import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Avatar, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Toggle } from "../../UI/Toggle";
import addressIcon from "../../../assets/location.svg";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import DefaultMaleProfilePic from "../../../assets/Icons/profile/Profile icon _ male.png";
import { ReactComponent as SearchIcon } from "../../../assets/Icons/Search Icon.svg";
import { useGetAllUsersQuery } from "../../../redux-toolkit/features/department/departmentApiSlice";
import { debounce } from "../../../utils";
import { useGetEmployeeQuery } from "../../../redux-toolkit/features/rewards/rewardsApiSlice";
import { height } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  maxHeight: "70vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: "40px 50px ",
};

export const CartModal = ({
  setCartOpen,
  isToggle,
  setToggle,
  cartOpen,
  balance,
  handleConfirmPurchase,
  gift,
}) => {
  const handleClose = () => setCartOpen(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedUser, setselectedUser] = React.useState();

  const { data: users, refetch } = useGetEmployeeQuery(
    searchQuery,

    {
      skip: searchQuery === "",
      refetchOnMountOrArgChange: true,
    }
  );
  const confirmHandler = async () => {
    handleClose();
    await handleConfirmPurchase(selectedUser);
    setselectedUser("");
  };

  // const setDebouncedSearchQuery = React.useCallback(
  //   debounce((value) => {
  //     setSearchQuery(value);
  //   }, 500),
  //   []
  // );
  const handleSearchChange = (item) => {
    setselectedUser(item);
    setSearchQuery("");
  };

  return (
    <div>
      <div>
        <Modal
          open={cartOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="checkout__modal">
              <div className="checkout__head">
                <h3 className="ftwt__b">Shipping Details</h3>
                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "var(--grey400)",
                    "&:hover": {
                      color: "var(--error)",
                    },
                  }}
                />
              </div>
              <div
                style={{ margin: "8px 0 20px" }}
                className="checkout__sperator"
              ></div>
              {gift && (
                <div style={{ position: "relative" }}>
                  <h4 style={{ color: "var(--pblue)" }}>Search Qgroup</h4>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      fullWidth
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon style={{ height: "15px" }} />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {searchQuery != "" && (
                    <div className="cartModal__searchLayout">
                      {users &&
                        users?.data?.map((item, index) => (
                          <div
                            onClick={() => handleSearchChange(item)}
                            key={index}
                            className="cartModal__searchD"
                          >
                            <Avatar
                              sx={{ width: 30, height: 30 }}
                              src={
                                selectedUser?.avatar
                                  ? selectedUser?.avatar
                                  : DefaultMaleProfilePic
                              }
                            />
                            <h6>
                              {item.fname} {item.lname}
                            </h6>

                            <div className="cartModal__searchDetails">
                              <p> {item.designation}</p>
                              <p>|</p>
                              <p> {item.location} </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {selectedUser && (
                    <div className="cartModal__searchDSelected">
                      <Avatar
                        sx={{ width: 30, height: 30 }}
                        src={
                          // data?.data?.avatar
                          //   ? data?.data?.avatar
                          // :
                          DefaultMaleProfilePic
                        }
                      />
                      <h6>
                        {selectedUser?.fname} {selectedUser?.lname}
                      </h6>

                      <div className="cartModal__searchDetails">
                        <p> {selectedUser?.designation}</p>
                        <p>|</p>
                        <p> {selectedUser?.location} </p>
                      </div>
                      <CloseIcon
                        onClick={() => setselectedUser("")}
                        sx={{
                          fontSize: "18px",
                          cursor: "pointer",
                          marginLeft: "auto",
                          color: "var(--grey400)",
                          "&:hover": {
                            color: "var(--error)",
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {/* {!gift && (
                <p className="ftwt__r checkout__address">
                  <img
                    height={20}
                    src={addressIcon}
                    alt=""
                  />
                  Choose the place for shipping
                </p>
              )} */}
              {!gift && <h5>Office</h5>}{" "}
              {!gift && (
                <div className="checkout__head">
                  <h6 className="ftwt__r">
                    Receive the rewards at office via super admin
                  </h6>
                </div>
              )}
              {!gift && <div className="checkout__sperator"></div>}{" "}
              {!gift && (
                <h6 style={{ margin: "25px 0" }} className="ftwt__r">
                  You have a balance <span>{balance} points</span> remaining
                  after this purchase. Are you sure you want to redeem these
                  products ?
                </h6>
              )}
              <Button
                onClick={confirmHandler}
                sx={{ marginTop: "10px" }}
                // color="success"
                variant="contained"
              >
                Confirm Purchase
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
