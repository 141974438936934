import { CloseOutlined, ZoomOutMapOutlined } from "@mui/icons-material";
import { Button, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useAlert } from "../../../../hooks/useAlert";
import { useUpdateTaskStatusMutation } from "../../../../redux-toolkit/features/books/bookApiSlice";
import "./TaskAccordian.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  maxHeight: "50vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: "40px 20px",
};

export const TaskAccordian = ({ taskData }) => {
  const [status, setStatus] = useState(false);
  const [openImageModel, setOpenImageModel] = useState(false);
  const [openModel, setOpenModel] = useState("");
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { displayAlert } = useAlert();

  const statusHandler = async (status) => {
    await updateTaskStatus({ status, taskCompletionId: taskData?._id });
    displayAlert({
      open: true,
      message:
        status === "approve"
          ? `The task has been approved`
          : "The task has been rejected",
      type: "success",
      timeout: "1500",
    });
  };

  // useEffect(() => {
  //   if (openImageModel) {
  //     const div = document.createElement("div");
  //     div.style =
  //       "position: fixed; inset: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: #00000088; z-index: 100000";
  //     div.id = "attachment_modal";
  //     const img = document.createElement("img");
  //     img.style = "object-fit: contain; height: 90%;";
  //     img.src = taskData?.attachments?.filename;
  //     img.alt = "attachment";
  //     div.appendChild(img);
  //     div.addEventListener("click", (e) => {
  //       setOpenImageModel(false);
  //     });
  //     img.addEventListener("click", (e) => {
  //       e.stopPropagation();
  //     });
  //     document.body.appendChild(div);
  //   } else {
  //     let modal = document.getElementById("attachment_modal");
  //     modal && modal.remove();
  //   }
  // }, [openImageModel]);

  console.log(taskData);
  return (
    <>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(0,0,0,0.6)",
        }}
        open={openImageModel}
        onClose={() => setOpenImageModel(false)}
      >
        <Box
          style={{
            height: "90%",
          }}
        >
          <IconButton
            onClick={() => {
              setOpenImageModel(false);
            }}
            style={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            size="large"
          >
            <CloseOutlined fontSize="large" style={{ color: "white" }} />
          </IconButton>
          <img
            alt="attachment"
            style={{
              objectFit: "contain",
              height: "90%",
            }}
            src={taskData?.attachments?.filename}
          />
        </Box>
      </Modal>

      <Modal
        open={!!openModel}
        onClose={() => {
          setOpenModel("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="deletemodal">
            <h3 className="ftwt__r">
              Are you sure you want to {openModel} the task ?
            </h3>
            <div className="deletemodal__action">
              <Button
                onClick={() => {
                  setOpenModel("");
                }}
                color="error"
                variant="outlined"
              >
                No
              </Button>
              <Button
                onClick={() => {
                  statusHandler(openModel);
                }}
                color="success"
                variant="outlined"
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <div className={status ? "TaskAccActive" : "TaskAcc"}>
        <div className="TaskAcc__head">
          <h4>
            {taskData?.bookDetails?.name} &#62; {taskData?.task?.name}:&nbsp;
            <span className="ftwt_r">{taskData?.task?.description}</span>
          </h4>
          {!status ? (
            <button
              onClick={() => setStatus(true)}
              className="contained__lgbtn"
            >
              View
            </button>
          ) : (
            <IconButton onClick={() => setStatus(false)}>
              <CloseOutlined fontSize="small" />
            </IconButton>
          )}
        </div>
        {status && (
          <div className="TaskAcc__detail">
            <div>
              <div
                className="TaskAcc__img"
                style={{ position: "relative", overflow: "hidden" }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    backgroundColor: "#00000055",
                  }}
                >
                  <button
                    onClick={() => {
                      setOpenImageModel(true);
                    }}
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      borderRadius: "100%",
                      padding: "2px",
                      width: "45px",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      outline: "none",
                      backgroundColor: "#00000065",
                      transition: "all easeCn-out 0.3s",
                      zIndex: "100",
                    }}
                  >
                    <ZoomOutMapOutlined style={{ color: "white" }} />
                  </button>
                </div>
              </div>

              {taskData?.attachments?.url ? (
                <div className="TaskAcc__url">
                  <h4>URL</h4>
                  <a
                    href={
                      taskData?.attachments?.url.startsWith("https://") ||
                      taskData?.attachments?.url.startsWith("http://")
                        ? taskData?.attachments?.url
                        : `https://${taskData?.attachments?.url}`
                    }
                    target="__blank"
                    className="ftwt__r"
                  >
                    {taskData?.attachments?.url}{" "}
                  </a>
                </div>
              ) : null}
            </div>

            {taskData?.comments && taskData?.comments !== "undefined" ? (
              <>
                <h4>Additional Note</h4>
                <span>{taskData?.comments}</span>
              </>
            ) : null}
            {taskData?.attachments?.filename && (
              <>
                <h4>Attachments:</h4>

                <img
                  src={taskData?.attachments?.filename}
                  height={100}
                  width={100}
                  alt=""
                />
              </>
            )}
            <div className="seprator"></div>
            <div className="TaskAcc__actions">
              <Button
                onClick={() => setOpenModel("reject")}
                sx={{ width: "100px" }}
                variant="outlined"
                color="error"
              >
                Reject
              </Button>
              <Button
                onClick={() => setOpenModel("approve")}
                sx={{ width: "100px" }}
                variant="outlined"
                color="success"
              >
                Accept
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
