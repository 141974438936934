import { Button, Checkbox } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../../redux-toolkit/features/auth/authApiSlice";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  selectCurrentToken,
  setCredentials,
} from "../../redux-toolkit/features/auth/authSlice";
import usePersist from "../../hooks/usePersist";
import { useAlert } from "../../hooks/useAlert";
import { isErrorRedirect } from "../../redux-toolkit/features/auth/authSlice";
import { ReactComponent as MicrosoftSvg } from "../../assets/microsoft_icon.svg";
import { ReactComponent as GoogleSvg } from "../../assets/google_icon.svg";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

export const LoginForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const { displayAlert } = useAlert();
  const user = useSelector((state) => state.auth);

  const query = new URLSearchParams(window.location.search);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberFlag: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = values;
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const userId = query.get("id");
  const token = query.get("token");
  function validateUser() {
    if (token && userId) {
      const baseUrl =
        process.env.REACT_APP_CLIENT_ENV === "development"
          ? process.env.REACT_APP_LOCAL_URL
          : process.env.REACT_APP_SERVER_URL;
      // Construct the URL for the validation endpoint
      const validateUrl = `${baseUrl}/api/auth/sso/validate/${userId}/${token}`;

      // Use fetch or any other HTTP library to make the API call
      fetch(validateUrl, { method: "POST" })
        .then(async (response) => {
          if (response.ok) {
            // Handle success
            const data = await response.json();
            const resp = await data.data;
            dispatch(setCredentials(resp));
            navigate("/quest");
          }
        })
        .catch((error) => {
          console.log(error);
          // Handle network error
        });
    }
  }

  useEffect(() => {
    validateUser();
  }, [userId, token]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const loginHandler = async () => {
    if (!formik.errors.email && !formik.errors.password) {
      navigate("/auth");
      dispatch(isErrorRedirect({ redirect: false }));
      await login({
        id: formik.values.email,
        password: formik.values.password,
        rememberFlag: formik.values.rememberFlag || false,
      })
        .unwrap()
        .then(() => {
          props.onLoginSuccess && props.onLoginSuccess();
        })
        .catch((err) => {
          displayAlert({
            open: true,
            message: err?.data?.message,
            type: "error",
          });
          dispatch(isErrorRedirect({ redirect: true }));
        });
    }
  };

  const SocialLoginButton = (social) => {
    // Redirect to the Google login URL with query parameters
    const baseUrl =
      process.env.REACT_APP_CLIENT_ENV === "development"
        ? process.env.REACT_APP_LOCAL_URL
        : process.env.REACT_APP_SERVER_URL;
    const loginUrl = `${baseUrl}/api/auth/${social}`;
    window.location.href = loginUrl;
  };

  return (
    <>
      <form className="login__form" onSubmit={formik.handleSubmit}>
        <input
          type="email"
          placeholder="Email ID"
          className={
            formik.touched.email && formik.errors.email
              ? "login__input login__invalid"
              : "login__input"
          }
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="login__error">
            <ErrorOutlineIcon fontSize="small" color="error" />
            <span>{formik.errors.email}</span>
          </div>
        )}
        <div className="password__input__wrapper">
          <input
            type={passwordType}
            placeholder="Password"
            name="password"
            className={
              formik.touched.password && formik.errors.password
                ? "login__input login__invalid"
                : "login__input"
            }
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <i className="password__visibility__icon" onClick={togglePassword}>
            {passwordType === "password" ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </i>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className="login__error">
            <ErrorOutlineIcon fontSize="small" color="error" />

            <span>{formik.errors.password}</span>
          </div>
        )}
        <div className="login__remforgot">
          <div className="login__checkbox">
            <input
              style={{ background: "var(--grey600)" }}
              type="checkbox"
              checked={formik.values.rememberFlag}
              onChange={formik.handleChange}
              id="rememberFlag"
              name="rememberFlag"
              value={formik.values.rememberFlag}
            />
            <label htmlFor="rememberFlag">Remember Me</label>
          </div>
          <Link style={{ textDecoration: "none" }} to={"/forgot-password"}>
            <p className="login__forgot">Forgot Password?</p>
          </Link>
        </div>
        <Button
          fullWidth
          type="submit"
          sx={{
            marginTop: "40px",
            backgroundColor: "var(--sblue)",
            padding: "15x 10px",
          }}
          onClick={loginHandler}
          variant="contained"
          // onClick={() => dispatch(login())}
        >
          Login
        </Button>

        <div className="login__or">
          <span className="small__seprator"></span>
          <p>Or login with</p>
          <span className="small__seprator"></span>
        </div>

        <div className="login__social">
          <span onClick={() => SocialLoginButton("google")}>
            {/* Login With Google */}
            <GoogleSvg />
          </span>
          <span onClick={() => SocialLoginButton("microsoft")}>
            <MicrosoftSvg />
          </span>
        </div>
      </form>
    </>
  );
};
