import React from "react";
import { ReportCard } from "./ReportCard";
import "./Reports.css";
import departmentActivity from "../../../assets/reports/admin/Department Book Activity.svg";
import departmentStatistic from "../../../assets/reports/admin/Department Book Statistics.svg";
import userActivity from "../../../assets/reports/admin/User Activity Report.svg";
import userStatistic from "../../../assets/reports/admin/User Statistics.svg";
const reportData = [
  {
    _id: 1,
    icon: userActivity,
    label: "User Activity",
    link: "user-activity",
    description: "Detailed information on the user’s performance.",
  },
  {
    _id: 2,
    icon: userStatistic,
    label: " Department User Statistics",
    link: "user-statistic",
    description: "Detailed information on the department's performance.",
  },

  {
    _id: 4,
    icon: departmentStatistic,
    label: "Department Books Statistics",
    link: "book-statistic",
    description:
      "Detailed information about each book and user activity in that book.",
  },
];

export const Reports = () => {
  return (
    <div className="container">
      <div className="reports">
        {reportData.map((elm) => (
          <ReportCard base={"/admin/reports"} data={elm} />
        ))}
      </div>
    </div>
  );
};
