import { CloseOutlined } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useAlert } from "../../../../../hooks/useAlert";
import { useUpdateTaskStatusMutation } from "../../../../../redux-toolkit/features/books/bookApiSlice";
import "./TaskAccordianHod.css";

export const TaskAccordianHod = ({ taskData }) => {
  const [status, setStatus] = useState(false);
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { displayAlert } = useAlert();

  const statusHandler = async (status) => {

    await updateTaskStatus({ status, taskCompletionId: taskData._id });
    displayAlert({
      open: true,
      message:
        status === "approve"
          ? `The task has been approved`
          : "The task has been rejected",
      type: "success",
      timeout: "1500",
    });
  };

  console.log("inhod view")
  return (
    <div className={status ? 'TaskAccActive' : 'TaskAcc'}>
      <div className="TaskAcc__head">
        <h4>
          Egyption Symbol &#62; {taskData.task.name}:&nbsp;
          <span className="ftwt_r">{taskData.task.description}</span>
        </h4>
        {!status ? (
          <button
            onClick={() => setStatus(true)}
            className="contained__lgbtn">
            View
          </button>
        ) : (
          <IconButton onClick={() => setStatus(false)}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        )}
      </div>
      {status && (
        <div className="TaskAcc__detail">
          <div>
            {taskData?.attachments.url === '' ? (
              <img
                className="TaskAcc__img"
                src={taskData?.attachments.filename}
                alt=""
              />
            ) : (
              <>
                <h4>URL</h4>
                <a
                  href={
                    taskData?.attachments?.url.startsWith('https://') ||
                    taskData?.attachments?.url.startsWith('http://')
                      ? taskData?.attachments?.url
                      : `https://${taskData?.attachments?.url}`
                  }
                  target="__blank"
                  className="ftwt__r">
                  {taskData?.attachments?.url}{' '}
                </a>
              </>
            )}
          </div>

          <h4>Additional Note</h4>
          <span>
            {taskData?.comments != 'undefined' ? taskData?.comments : 'None'}
          </span>
          <div className="seprator"></div>
          <div className="TaskAcc__actions">
            <Button
              onClick={() => statusHandler('reject')}
              sx={{ width: '100px' }}
              variant="outlined"
              color="error">
              Reject
            </Button>
            <Button
              onClick={() => statusHandler('approve')}
              sx={{ width: '100px' }}
              variant="outlined"
              color="success">
              Accept
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
