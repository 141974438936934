import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import "./ActivityTable.css";

export const ActivityTable = ({ users }) => {
  return (
    // <TableContainer sx={{ height: "800px", overflowX: "none !important" }}>
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow className="leaderboard__table_header">
            <TableCell sx={{ borderRadius: "10px 0 0 0" }} align="center">
              Name
            </TableCell>
            <TableCell align="center">Level</TableCell>

            <TableCell align="center" sx={{ borderRadius: "0 10px 0 0" }}>
              Task
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="leaderboard__table_body">
          {users?.map((user, i) => {
            return (
              <>
                <div className="row__spacing"></div>
                <TableRow key={i} className="leaderboard__table_row">
                  <TableCell
                    style={{
                      borderLeft: "0.1px solid #c3c3c3",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      width: "250px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItem: "center",
                        marginLeft: "20px",
                        gap: "15px",
                      }}
                    >
                      <Avatar
                        src={user?.user?.avatar}
                        style={{ width: "25px", height: "25px" }}
                      />
                      {`${
                        user?.user?.fname
                          ? `${user?.user?.fname} ${user?.user?.lname}`
                          : user.email
                      }`}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    Level {user?.level?.levelNumber} <br />
                    <div
                      className="truncate-1"
                      style={{ color: "var(--pmain)" }}
                    >
                      {user?.level?.name}{" "}
                    </div>
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      borderRight: "0.1px solid #c3c3c3",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    {user?.task?.name}
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
    // </TableContainer>
  );
};
