import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Avatar, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "../Delete Modal/DeleteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import "./ProfileModal.css";
import {
  useGetEmployeeByIdQuery,
  useGetLeaderboardEmployeeQuery,
} from "../../../redux-toolkit/features/employee/employeeApiSlice";
import { useEffect } from "react";
import DefaultMaleProfilePic from "../../../assets/Icons/profile/Profile icon _ male.png";
import { width } from "@mui/system";
import { useSendCongratulationsNotificationMutation } from "../../../redux-toolkit/features/notifications/notificationsApiSlice";
import { useAlert } from "../../../hooks/useAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  maxHeight: "70vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: "20px 20px",
};

export const UserModal = ({ profileOpen, setProfileOpen, rank }) => {
  const { displayAlert } = useAlert();
  const handleClose = () => setProfileOpen(false);
  const { data, isLoading, refetch, isError } = useGetLeaderboardEmployeeQuery(
    profileOpen,
    {
      refetchOnMountOrArgChange: true,
      skip: profileOpen === 0 || profileOpen === false,
    }
  );
  const [sendCongratulationsNotification] =
    useSendCongratulationsNotificationMutation();

  return (
    <div>
      <div>
        <Modal
          open={profileOpen != 0}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="profileHead">
              <CloseIcon
                onClick={handleClose}
                sx={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "var(--grey400)",
                  "&:hover": {
                    color: "var(--error)",
                  },
                }}
              />
            </div>
            <div className="profileTop">
              <div>
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    border: "5px solid var(--grey300)",
                  }}
                  src={
                    data?.data?.employeeDetails?.avatar
                      ? data?.data?.employeeDetails?.avatar
                      : DefaultMaleProfilePic
                  }
                />
              </div>
              <div>
                <h1 style={{ width: "242px" }} className="ftwt__b truncate-1">
                  {data?.data?.employeeDetails?.fname}{" "}
                  {data?.data?.employeeDetails?.lname}
                </h1>
                <h5 className="ftwt__r">
                  {data?.data?.employeeDetails?.designation}
                </h5>
                <h6 className="ftwt__r profileTop__role">
                  {data?.data?.employeeDetails?.permissionSets?.roleType ===
                    "CUSTOM_ROLE" && "Custom Role"}
                  {data?.data?.employeeDetails?.permissionSets?.name ===
                    "ADMIN" && "Admin"}
                  {data?.data?.employeeDetails?.permissionSets?.name ===
                    "SUPERADMIN" && "Super Admin"}
                  {data?.data?.employeeDetails?.permissionSets?.name ===
                    "EMPLOYEE" && "Employee"}
                </h6>
              </div>
              {rank && data?.data?.earnedBadge ? (
                <div className="profileTop__cong">
                  <Button
                    sx={{
                      borderRadius: " 5px",
                      color: "white",
                      fontWeight: "600 !important",
                      background: "linear-gradient(to right, #3c63d0, #8071da)",
                    }}
                    onClick={() => {
                      sendCongratulationsNotification(
                        data?.data?.employeeDetails?._id
                      )
                        .then((data) => {
                          displayAlert({
                            open: true,
                            message:
                              data?.data?.message || "Congratulated the user!",
                            type: "success",
                            timeout: "2000",
                          });
                        })
                        .catch(() => {
                          displayAlert({
                            open: true,
                            message:
                              "Something went wrong while congratulating the user!",
                            type: "error",
                            timeout: "2000",
                          });
                        });
                    }}
                  >
                    Congratulate
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="profileModal__seprator "></div>
            <div className="profileModal__achivements">
              <div className="profileTop__tab">Achivements</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  height: "100%",
                  margin: "0 auto",
                }}
              >
                {data?.data?.earnedBadge ? (
                  <div className="credential__parent_container">
                    <div className="credential__tier_left">
                      <small
                        className="credential__tier_title"
                        style={{ marginLeft: "-14px" }}
                      >
                        Tier {data?.data?.earnedBadge?.tier}
                      </small>
                      <div
                        className="credential__tier_left_border"
                        style={{ height: "120px" }}
                      ></div>
                    </div>
                    <div className="credential__tier_right">
                      <div className="credential__tier_step">
                        <div className="credential__tier_badge">
                          <div
                            className="credential__tier_badge_border"
                            style={{ zIndex: 1 }}
                          ></div>
                          <div
                            style={{
                              zIndex: 2,
                            }}
                          >
                            <img
                              alt="badge"
                              style={{
                                width: "50px",
                                zIndex: 1,
                              }}
                              src={data?.data?.earnedBadge?.badgeImage}
                            />
                          </div>
                          <small className="credential__tier_badge_text">
                            {data?.data?.earnedBadge?.badgeName}
                          </small>
                        </div>
                        <div
                          className="credential__tier_tasks_count"
                          style={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            width: "80%",
                            marginLeft: "14px",
                            backgroundColor: "#959595",
                            position: "relative",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              width: `${Math.ceil(
                                (+data?.data?.totalCompletedTasks /
                                  data?.data?.totalTasks) *
                                  100
                              )}%`,
                              height: "100%",
                              background: "#3361cf",
                              inset: 0,
                              position: "absolute",
                              zIndex: 1,
                              overflow: "hidden",
                            }}
                          >
                            <div className="bs__creds_tier_task_count_zebra">
                              {new Array(50).fill(0).map(() => (
                                <div></div>
                              ))}
                            </div>
                          </div>
                          <p style={{ color: "#fff", zIndex: 2 }}>
                            {data?.data?.totalCompletedTasks}/
                            {data?.data?.totalTasks} tasks
                          </p>
                        </div>
                        <p
                          style={{
                            marginLeft: "14px",
                            marginTop: "20px",
                            fontWeight: "600",
                          }}
                        >
                          Total points earned: {data?.data?.totalPoints}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p> - No badge has been earned - </p>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
