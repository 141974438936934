import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { roleToggler } from "../../redux-toolkit/features/access/accessSlice";
import {
  useGetBooksQuery,
  useResumeTaskQuery,
} from "../../redux-toolkit/features/books/bookApiSlice";
import ProgressBar from "../UI/ProgressBar";
import "./Home.css";
import Wheel from "./QuestWheel/Wheel";

import prevIconDark from "../../assets/dark buttons/next.svg";
import nextIconDark from "../../assets/dark buttons/previous.svg";
import nextIcon from "../../assets/next.svg";
import prevIcon from "../../assets/prev.svg";

import { useNavigate } from "react-router-dom";
import { useGetInitialAppliedThemeQuery } from "../../redux-toolkit/features/theme/themeApiSlice";
import { homeTabToggler } from "../../redux-toolkit/features/theme/themeSlice";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import lock from "../../assets/books/lock_book.gif";
const BookIsLockedAlert = ({ open, setOpen, handleDelete, lastBook }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img
          src={lock}
          alt="lock"
          style={{
            width: "70px",
            height: "70px",
          }}
        />
        <DialogContentText id="alert-dialog-description">
          This book is <strong style={{ color: "#3361cf" }}>locked</strong>!
          Complete{" "}
          <strong style={{ color: "#3361cf" }}>{lastBook?.name}</strong> or wait
          for the admin to approve all your tasks.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          padding: "20px 24px",
        }}
      >
        <Button variant="contained" autoFocus onClick={() => setOpen(false)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const Home = ({
  isPreview,
  user,
  wheelColors,
  wheelBgColor,
  prevdividerImage,
  prevWheelBg,
  themeCardImage,
  scrollToBottom,
}) => {
  const [activeTab, setActiveTab] = useState(true);
  const [openLockAlert, setOpenLockAlert] = useState(false);
  const [activeBook, setActiveBook] = useState(null);
  const [lastBook, setLastBook] = useState(null);
  const {
    data: orgBooks,
    refetch,
    isLoading: loadingBooks,
  } = useGetBooksQuery('ORG', {
    refetchOnMountOrArgChange: true,
  });
  const homeTab = useSelector((state) => state.theme.homeTab);
  // homeTabToggler
  const {
    data: deptBooks,
    refetch: refetchDept,
    isLoading: loadingDept,
  } = useGetBooksQuery('DEPT', {
    refetchOnMountOrArgChange: true,
  });
  const { data, isLoading, isError } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const usedId = useSelector((state) => state.auth?.user?._id);
  const {
    data: resumeTask,
    isLoading: resumeLoad,
    isError: errorLoad,
  } = useResumeTaskQuery(usedId, {
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();
  const wheelRef = useRef();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  React.useEffect(() => {}, [data, homeTab]);
  let orgData = orgBooks?.data.books.filter(
    (item) => item.isPublished === true
  );
  let depData = deptBooks?.data.books.filter(
    (item) => item.isPublished === true
  );

  React.useEffect(() => {
    orgData = orgBooks?.data?.books.filter((item) => item.isPublished === true);

    depData = deptBooks?.data?.books.filter(
      (item) => item.isPublished === true
    );
  }, []);

  React.useEffect(() => {
    refetch();
    if (orgData && depData) {
      setActiveBook(homeTab ? orgData[0] : depData[0] || null);
    }
  }, [homeTab]);

  // if (isLoading || loadingBooks) {
  //   return <ProgressBar />;
  // }

  React.useEffect(() => {
    dispatch(roleToggler('Employee'));
  }, []);

  const startHandler = (_id, isLocked) => {
    if (!isPreview) {
      if (_id && !isPreview && !isLocked) {
        navigate(`books/${_id}`);
      } else {
        const lastB = orgBooks?.data?.books?.find(
          (book) => book.order === (user?.currentBookOrder || 1)
        );
        setLastBook(lastB);
        setOpenLockAlert(true);
      }
    }
  };

  const resumeTaskHandler = (bookId, levelId) => {
    navigate(`/quest/books/${bookId}/levels/${levelId}`);
  };
  function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  const handleTabs = (tab) => {
    if (!isPreview) {
      dispatch(homeTabToggler(tab));
    }
  };

  return (
    <div>
      <BookIsLockedAlert
        lastBook={lastBook}
        open={openLockAlert}
        setOpen={setOpenLockAlert}
      />
      {data && orgData && depData && (
        <div className="bg__books">
          <div
            id="layout"
            style={{ overflow: isPreview && 'hidden' }}
            className="home__layout">
            <div className="home__right">
              <div className="home__rightTop">
                <div
                  className="home__rightTop"
                  style={{
                    width: '100%',
                    opacity: isExpanded ? 0 : 1,
                    transform: isExpanded
                      ? 'translateY(-150px)'
                      : 'translateY(0px)',
                    transition: '0.4s all ease-in-out',
                  }}>
                  <h1 className="ftwt__r">
                    Unlock the power of engagement on Quest!{' '}
                  </h1>
                  <h3 className="ftwt__r">
                    Through a variety of captivating activities, you'll deepen{' '}
                    <br /> your organizational understanding and create bonds
                    that foster growth.
                  </h3>
                  <img
                    style={{
                      width: '30%',
                    }}
                    alt="divider"
                    src={
                      isPreview && prevdividerImage
                        ? prevdividerImage
                        : data?.data?.dividerImage
                    }
                  />
                </div>
                <div className="step21">
                  <div
                    style={{
                      transform: isExpanded
                        ? 'translateY(-150px)'
                        : 'translateY(0px)',
                      transition: '0.4s all ease-in-out',
                    }}
                    className="home__btns home__rightswitch">
                    <h3
                      className={homeTab ? 'home__activetab' : 'home__tab'}
                      onClick={() => {
                        if (isPreview) return;
                        handleTabs(true);
                        wheelRef.current?.next();
                        setTimeout(() => {
                          wheelRef?.current?.prev();
                        }, 100);
                      }}>
                      Organization
                    </h3>
                    {user?.role != 'SUPERADMIN' && !user?.isHOD && (
                      <h3
                        className={!homeTab ? 'home__activetab' : 'home__tab'}
                        onClick={() => {
                          if (isPreview) return;
                          handleTabs(false);
                          wheelRef.current?.next();
                          setTimeout(() => {
                            wheelRef?.current?.prev();
                          }, 100);
                        }}>
                        Department
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {resumeTask?.data != null && homeTab
              ? resumeTask?.data?.orgBook != null && (
                  <div className="home__resume">
                    <div>
                      <FiberManualRecordIcon
                        color="success"
                        sx={{ fontSize: '13px' }}
                      />
                      <h5 className="ftwt__r">Ongoing</h5>
                    </div>
                    {resumeTask && (
                      <div className="home__resumeSecond">
                        <h6
                          onClick={() =>
                            resumeTaskHandler(
                              resumeTask?.data?.orgBook?.book?._id,
                              resumeTask?.data?.orgBook?.level?._id
                            )
                          }
                          className="ftwt__r moving-text">
                          {resumeTask?.data?.orgBook?.book?.name} &#62; &nbsp;{' '}
                          {resumeTask?.data?.orgBook?.level?.name}
                        </h6>
                      </div>
                    )}
                  </div>
                )
              : resumeTask?.data?.deptBook != null && (
                  <div className="home__resume">
                    <div>
                      <FiberManualRecordIcon
                        color="success"
                        sx={{ fontSize: '13px' }}
                      />
                      <h5 className="ftwt__r">Ongoing</h5>
                    </div>
                    {resumeTask && (
                      <div className="home__resumeSecond">
                        <h6
                          onClick={() =>
                            resumeTaskHandler(
                              resumeTask?.data?.deptBook?.book?._id,
                              resumeTask?.data?.deptBook?.level?._id
                            )
                          }
                          className="ftwt__r moving-text">
                          {resumeTask?.data?.deptBook?.book?.name} &#62; &nbsp;
                          {resumeTask?.data?.deptBook?.level?.name}
                        </h6>
                      </div>
                    )}
                  </div>
                )}
            {(homeTab ? orgBooks : deptBooks) ? (
              <>
                {homeTab ? (
                  <div className="home__quest">
                    <Wheel
                      wheelBgColor={wheelBgColor}
                      themeCardImage={themeCardImage}
                      activeTab={homeTab}
                      isExpanded={true}
                      ref={wheelRef}
                      wheelColors={wheelColors}
                      isPreview={isPreview}
                      prevWheelBg={prevWheelBg}
                      startHandler={startHandler}
                      books={orgData && orgData}
                      getCenterItem={(item) => {
                        setActiveBook(orgData[item]);
                      }}
                      activeBook={activeBook}
                      setActiveBook={(book) => {
                        if (book) {
                          setTimeout(() => {
                            setActiveBook(book);
                          }, 100);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div className="home__quest">
                    <Wheel
                      wheelBgColor={wheelBgColor}
                      themeCardImage={themeCardImage}
                      activeTab={homeTab}
                      isExpanded={true}
                      ref={wheelRef}
                      wheelColors={wheelColors}
                      isPreview={isPreview}
                      prevWheelBg={prevWheelBg}
                      startHandler={startHandler}
                      books={depData && depData}
                      getCenterItem={(item) => {
                        setActiveBook(depData[item] || null);
                      }}
                      activeBook={activeBook}
                      setActiveBook={(book) => {
                        if (book) {
                          setTimeout(() => {
                            setActiveBook(book);
                          }, 100);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              resumeTask?.data?.deptBook != null && (
                <div className="home__resume">
                  <div>
                    <FiberManualRecordIcon
                      color="success"
                      sx={{ fontSize: '13px' }}
                    />
                    <h5 className="ftwt__r">Ongoing</h5>
                  </div>
                  {resumeTask && (
                    <div className="home__resumeSecond">
                      <h6
                        onClick={() =>
                          resumeTaskHandler(
                            resumeTask?.data?.deptBook?.book?._id,
                            resumeTask?.data?.deptBook?.level?._id
                          )
                        }
                        className="ftwt__r moving-text">
                        {resumeTask?.data?.deptBook?.book?.name} &#62; &nbsp;
                        {resumeTask?.data?.deptBook?.level?.name}
                      </h6>
                    </div>
                  )}
                </div>
              )
            )}
            {(homeTab ? orgBooks : deptBooks) ? (
              <>
                <div className="quest__next">
                  <Button onClick={() => wheelRef.current.prev()}>
                    <img
                      src={data?.data.mode === 'dark' ? nextIconDark : nextIcon}
                      alt="expand"
                    />
                  </Button>
                </div>
                <div className="quest__prev">
                  <Button onClick={() => wheelRef.current.next()}>
                    <img
                      src={data?.data.mode === 'dark' ? prevIconDark : prevIcon}
                      alt="expand"
                    />
                  </Button>
                </div>
              </>
            ) : (
              <ProgressBar />
            )}
            {/* {isExpanded && ( */}
            <>
              <div className="quest__next">
                <Button onClick={() => wheelRef.current.prev()}>
                  <img
                    src={data?.data.mode === 'dark' ? nextIconDark : nextIcon}
                    alt="expand"
                  />
                </Button>
              </div>
              <div className="quest__prev">
                <Button onClick={() => wheelRef.current.next()}>
                  <img
                    src={data?.data.mode === 'dark' ? prevIconDark : prevIcon}
                    alt="expand"
                  />
                </Button>
              </div>
            </>
            {/* )} */}

            {/* <div className="quest__expand">
                <Button
                  onClick={handleExpand}
                  focusRipple={false}
                  disableRipple
                  disableElevation
                >
                  <img
                    src={
                      data?.data.mode === "dark" ? expandIconDark : expandIcon
                    }
                    alt="expand"
                    style={{
                      width: "80%",
                      transform: isExpanded
                        ? "rotate(0deg)"
                        : "rotate(-180deg)",
                      transition: "0.4s  ease-in-out",
                    }}
                  />
                </Button>
              </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
