import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Avatar, Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import DefaultMaleProfilePic from '../../assets/Icons/profile/Profile icon _ male.png';
import { useAlert } from '../../hooks/useAlert';
import {
  useChangePasswordMutation,
  useGetProfileQuery,
  useChangeProfileImageMutation,
  useGetCompletedAlphaScreensQuery,
} from '../../redux-toolkit/features/auth/authApiSlice';
import './Account.css';
import { AccountTabs } from './AccountTabs';
import ProgressBar from '../UI/ProgressBar';
import { Error404 } from '../UI/404/Error404';
import emailSvg from '../../assets/Employee Profile Icons/Mail.svg';
import activeSvg from '../../assets/Employee Profile Icons/Active hrs.svg';
import phoneSvg from '../../assets/Employee Profile Icons/Phone.svg';
import depSvg from '../../assets/Employee Profile Icons/Product Dept.svg';
import locationSvg from '../../assets/Employee Profile Icons/Location.svg';
import tenureSvg from '../../assets/Employee Profile Icons/Tenure.svg';
import { setAvatar } from '../../redux-toolkit/features/auth/authSlice';
import { useDispatch } from 'react-redux';

const validationSchema = yup.object({
  currentPassword: yup
    .string('Enter your password')
    .required('Password is required'),
  newPassword: yup
    .string('Enter your password')
    .required('Password is required'),
  confirmPassword: yup
    .string('Confirm your password')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const Account = () => {
  const { data, isLoading, refetch, isError } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const { displayAlert } = useAlert();

  const [changePassword] = useChangePasswordMutation();
  const [changeProfileImage] = useChangeProfileImageMutation();
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const finalPw = {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      };
      changePassword(finalPw)
        .unwrap()
        .then(() => {
          displayAlert({
            open: true,
            message: 'Successfully updated password',
            type: 'success',
            timeout: '2000',
          });
        })
        .catch((err) =>
          displayAlert({
            open: true,
            message: 'Entered Invalid Password',

            type: 'error',
            timeout: '2000',
          })
        );
      resetForm();
    },
  });

  const userData = [
    {
      _id: 2,
      label: data?.data.email,
      icons: emailSvg,
    },
    {
      _id: 3,
      label: data?.data.mobile,
      icons: phoneSvg,
    },
    {
      _id: 34,
      label: data?.data.location,
      icons: locationSvg,
    },
    {
      _id: 32,
      label: data?.data.lastLogin.slice(0, 20).replace('T', '  '),
      icons: activeSvg,
      prefix: 'Active: ',
    },
    {
      _id: 343,
      label: data?.data.department,
      icons: depSvg,
    },
    {
      _id: 33,
      label: data?.data.tenure,
      icons: tenureSvg,
      prefix: 'Tenure: ',
    },
  ];

  useEffect(() => {}, [data?.data]);

  if (isLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <ProgressBar />
      </div>
    );
  }

  if (isError) {
    return <Error404 />;
  }

  const profileHandler = async (event) => {
    let avatar = event.target.files[0];
    if (avatar.size >= 4099276) {
      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 4 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      const data = new FormData();
      data.append('avatar', avatar);
      await changeProfileImage(data).then((res) => {
        dispatch(setAvatar({ avatar: res?.data?.data?.avatar }));
        displayAlert({
          open: true,
          message: `Profile image updated successfully!`,
          type: 'success',
          timeout: '2000',
        });
      });
    }
  };

  return (
    <div className="container__accounts">
      <div className="account">
        <div className="account__top account__mb20">
          <h2>Account</h2>
        </div>

        <div className="account__lefttop account__mb20">
          <div className="account__avtar">
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={
                data?.data?.avatar ? data?.data?.avatar : DefaultMaleProfilePic
              }
            />

            <label for="file-input">
              <div className="account__edit">
                <EditOutlinedIcon
                  sx={{
                    fontSize: '17px',
                    color: 'white',
                    marginLeft: '4px',
                    marginTop: '4px',
                  }}
                />
              </div>
            </label>

            <input
              className="account__input"
              onChange={profileHandler}
              id="file-input"
              type="file"
              accept="image/*"
            />
          </div>
          <div className="account__profile">
            <h1>
              {data?.data.fname} {data?.data.lname}
            </h1>
            <h3 className="ftwt__r">{data?.data.designation}</h3>
            <h3 className="ftwt__r">Employee ID: {data?.data.employeeId}</h3>
          </div>
        </div>
        <div className="account__profilecontents account__mb20">
          {userData.map((item) => (
            <div
              key={item._id}
              className="account__profilecard">
              <img
                src={item.icons}
                alt=""
              />
              <h6>
                {item.prefix} {item.label}
              </h6>
            </div>
          ))}
        </div>
        <div className="account__bottom">
          <AccountTabs
            refetchProfile={refetch}
            setValue={setValue}
            value={value}
            formik={formik}
          />
        </div>
      </div>
    </div>
  );
};
