import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setRefresh } from '../features/auth/authSlice';

// Set base URL based on environment.
const baseUrl =
  process.env.REACT_APP_CLIENT_ENV === 'development'
    ? process.env.REACT_APP_LOCAL_URL
    : process.env.REACT_APP_SERVER_URL;

// setting base URL and token in header
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  keepUnusedDataFor: 30,
  refetchOnMountOrArgChange: true,
  credentials: 'same-origin',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    const orgId = getState().auth.user?.orgid;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('orgid', `${orgId}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 403) {

    // send refresh token to get new access token
    const refreshResult = await baseQuery(
      'api/auth/refresh',
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      // store the new token
      const { accessToken } = refreshResult?.data?.data;
      api.dispatch(setRefresh(accessToken));

      // retry original query with new access token

      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = 'Your login has expired. ';
      }
      return refreshResult;
    }
  }

  return result;
};
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Permission',
    'Search',
    'Books',
    'BookById',
    'BooksLevels',
    'BooksLevelsTask',
    'TaskCompletion',
    'Notifications',
    'Course',
    'CourseDetail',
    'Department',
  ],
  endpoints: (builder) => ({}),
});
