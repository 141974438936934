
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteInActiveSvg from '../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../assets/Icons/delete_red.svg';
import React from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const OrgCard = ({
  image,
  poc,
  name,
  id,
  handleNavigateDetailed }) => {


  return (
    <div
      onClick={() => { handleNavigateDetailed(id) }}
      key={id}
      className="owner__card">
      <div className='owner__cardimg'>
        <img
          src={image}
          alt="logo"
        />
      </div>
      <div className="owner__cardDetails">
        <div className="owner__cardDetailsfields">
          <h4 className="ftwt__b">Company - </h4>
          <h4 className="ftwt__r">{name}</h4>
        </div>
        <div className="owner__cardDetailsfields">
          <h4 className="ftwt__b">POC - </h4>
          <h4 className="ftwt__r">
            {poc}
          </h4>
        </div>
      </div>
    </div>
  )
}