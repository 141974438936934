import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ViewBook.css";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import {
  useGetBookByIdQuery,
  useGetBooksLevelsQuery,
} from "../../../../redux-toolkit/features/books/bookApiSlice";
import { BreadcrumbAdmin } from "../../../UI/BreadcrumbAdmin";
import { useAlert } from "../../../../hooks/useAlert";
import { Star } from "@mui/icons-material";
import { ViewLevel } from "./ViewLevel";


export const ViewBook = ({ isAdmin = false }) => {


  const { _id } = useParams();

  // RTKQuery hooks
  const { data: levels } = useGetBooksLevelsQuery(_id);
  const { data: bookData, refetch } = useGetBookByIdQuery(_id);

  let base = [];

  if(isAdmin){
    base=[{
      _id: 1,
      link: '/admin/my-workspace',
      label: 'My Workspace',
    },]
  }else{
    base = [
      {
        _id: 1,
        link: '/super-admin/manage-organization/organizational-quest',
        label: 'Organizational Quest',
      },
    ];
  }

  return (
    <div className="container">
      <BreadcrumbAdmin
        base={base}
        page={bookData?.data.name}
      />
      <div className="viewbook__container">
        <div className="viewbook">
          <div className="viewbooks__left">
            <div>
              <h4 className="ftwt__b"> Book Name </h4>
              <h6>{bookData?.data.name}</h6>
            </div>
            <div>
              <h4 className="ftwt__b">Description</h4>
              <h6>{bookData?.data.description}</h6>
            </div>
            <div className="viewbook__point_badge">
              <Star
                style={{
                  color: '#ee7233',
                  fontSize: '18px',
                }}
              />
              <h5> {bookData?.data.points} Points</h5>
            </div>
          </div>
          <div
            className="viewbook__right">
            <h6>Display Image</h6>
            {bookData?.data?.coverImage && (
              <img
                style={{
                  objectFit: "contain"
                }}
                src={
                  !bookData?.data?.coverImage.type
                    ? bookData?.data?.coverImage
                    : URL.createObjectURL(bookData?.data?.coverImage)
                }
                alt=""
              />
            )}
          </div>
        </div>
        <div className="seprator margin-separator"></div>
        <div className="viewbook__levels">
          {levels &&
            levels?.data.levels.map((level, index) => {
              return (
                <ViewLevel
                  key={index}
                  levelData={level}
                  index={index}
                  currentBookId={_id}
                />
              );
            })}
        </div>
      </div>
    </div>
  )
}