import * as React from "react";
import "./Workspace.css";
import { ReactComponent as EditSvg } from '../../../assets/Icons/edit (1).svg';
import { ReactComponent as DeleteSvg } from "../../../assets/Icons/Delete _ Default.svg";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function DepDrawer({
  deletebtnHandler,
  index,
  setDrawer,
  editbtnHandler,
  viewbtnHandler,
  isView
}) {
  const ref = React.useRef(null);

  const closePopup = () => {
    setDrawer && setDrawer(false);
  };

  useOutsideClick(ref, closePopup);

  return (
    <>
      <div
        ref={ref}
        className="wsDrawerActive">
          {isView&&<p onClick={viewbtnHandler}>
          <VisibilityOutlinedIcon style={{ width: '18px' }} />
          View
        </p>}
        {!isView&&<p onClick={editbtnHandler}>
          <EditSvg style={{ width: '15px' }} />
          Edit
        </p>}
        {!isView&&<p onClick={deletebtnHandler}>
          <DeleteSvg style={{ width: '15px' }} /> Delete
        </p>}
      </div>
    </>
  );
}
