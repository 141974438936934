import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./EditBooks.css";

import { Colorize, ColorLensOutlined, Star } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Modal, TextField } from "@mui/material";
import { useFormik } from "formik";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  useGetBookByIdQuery,
  useGetBooksLevelsQuery,
  useUpdateBookMutation,
  useDeleteBookMutation,
  usePublishBookMutation,
} from "../../../../redux-toolkit/features/books/bookApiSlice";
import { BreadcrumbAdmin } from "../../../UI/BreadcrumbAdmin";
import { AddLevels } from "./AddLevels";
import { useAlert } from "../../../../hooks/useAlert";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";
import { Box } from "@mui/system";
import { BadgeOne } from "./BadgeTemplates/BadgeOne";
import { BadgeTwo } from "./BadgeTemplates/BadgeTwo";
import { BadgeThree } from "./BadgeTemplates/BadgeThree";
import { BadgeFour } from "./BadgeTemplates/BadgeFour";
import { BadgeFive } from "./BadgeTemplates/BadgeFive";
import { BadgeSix } from "./BadgeTemplates/BadgeSix";
import { BadgeSeven } from "./BadgeTemplates/BadgeSeven";
import { BadgeEight } from "./BadgeTemplates/BadgeEight";
import { BadgeNine } from "./BadgeTemplates/BadgeNine";
import * as htmlToImage from "html-to-image";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import EditIcon from '@mui/icons-material/EditOutlined';
import { EditImages } from "./EditImages/EditImages";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  maxHeight: '50vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '40px 20px',
};

const ApplyBadgeModal = ({ label, confirmHandler, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="deletemodal">
          <h3 className="ftwt__r">
            Do you want to add a Badge to <strong>{label}</strong>?
          </h3>
          <div className="deletemodal__action">
            <Button onClick={confirmHandler} color="success" variant="outlined">
              Yes
            </Button>

            <Button onClick={handleClose} color="error" variant="outlined">
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const ColorPicker = ({ value, onChange, name }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "max-content",
          gap: "4px",
          border: "0.1px solid #575757",
          overflow: "hidden",
          paddingRight: "4px",
          borderRadius: "5px",
        }}
      >
        <label
          htmlFor={name}
          style={{
            backgroundColor: value,
            width: "35px",
            height: "35px",
            display: "block",
          }}
        ></label>
        <Colorize />
      </div>
      <input
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
          position: "absolute",
        }}
        type={"color"}
        onChange={(e) => {
          onChange(e);
        }}
        name={name}
        id={name}
        value={value}
      />
    </div>
  );
};

const EditableBadge = (props) => {
  switch (props.id) {
    case "1":
      return <BadgeOne {...props} />;
    case "2":
      return <BadgeTwo {...props} />;
    case "3":
      return <BadgeThree {...props} />;
    case "4":
      return <BadgeFour {...props} />;
    case "5":
      return <BadgeFive {...props} />;
    case "6":
      return <BadgeSix {...props} />;
    case "7":
      return <BadgeSeven {...props} />;
    case "8":
      return <BadgeEight {...props} />;
    default:
      return <BadgeNine {...props} />;
  }
};

export const EditBooks = ({ isAdmin = false, isView = false }) => {
  // States
  const [addLevel, setAddLevel] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [applyBadgeOpen, setApplyBadgeOpen] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [showBadgeGenerator, setShowBadgeGenerator] = useState(false);
  const [selectedBadgeData, setSelectedBadgeData] = useState({
    id: "1",
    name: "",
    firstLayer: "#2169CF",
    secondLayer: "#3DDEED",
    thirdLayer: "#7BF4FF",
  });
  // Custom hooks
  const { _id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { displayAlert } = useAlert();

  // RTKQuery hooks
  const { data: levels } = useGetBooksLevelsQuery(_id);
  const { data: bookData, refetch } = useGetBookByIdQuery(_id);
  const [updateBook] = useUpdateBookMutation();
  const [publishBook] = usePublishBookMutation();
  const [deleteBook] = useDeleteBookMutation();
  const [thumbnail, setThumbnail] = useState(bookData?.data?.coverImage);
  const [selectedTheme, setTheme] = useState(bookData?.data?.theme);
  const [label, setLabel] = useState("")
  const [keepDefaultOpen, setKeepDefaultOpen] = useState(false);

  React.useEffect(() => {
    setThumbnail(bookData?.data?.coverImage);
    setTheme(bookData?.data?.theme);
  }, [bookData]);

  // Handlers
  const refetchHandler = () => {
    refetch();
  };

  const handleNavigation = () => {
    if (isAdmin) {
      navigate('/admin/my-workspace');
    } else {
      navigate(`/super-admin/manage-organization/organizational-quest/`);
    }
  };

  const handleBookPublish = (file) => {
    publishBook({
      _id,
      publishBookBody: {
        badgeName: selectedBadgeData.name,
        image: file,
      },
    })
      .unwrap()
      .then((res) => {
        displayAlert({
          open: true,
          message: res?.message || 'Book has been published',
          type: 'success',
          timeout: '2000',
        });
        handleNavigation();
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: err?.data?.message || `Error while publishing the book`,
          type: 'error',
          timeout: '2000',
        });
      });
  };

  const convertToImageFile = () => {
    if (!selectedBadgeData.name) {
      displayAlert({
        open: true,
        message: 'Badge name is required',
        type: 'error',
        timeout: '2000',
      });
      return;
    }
    var node = document.getElementById('badge_temp');
    if (!node) return;

    // convert svg badge into jpeg
    htmlToImage
      .toJpeg(node)
      .then((dataUrl) => {
        // dataUrl is base64
        fetch(dataUrl)
          .then((res) => res.blob()) // convert it into File
          .then((blob) => {
            const file = new File([blob], 'badge.jpeg', { type: 'image/jpeg' });
            handleBookPublish(file);
          });
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  };

  const handleDeleteOpen = () => setDeleteOpen(true);

  const deleteBookHandler = () => {
    deleteBook(_id)
      .unwrap()
      .then(() => {
        refetch();
        displayAlert({
          open: true,
          message: 'Successfully Deleted Book',
          type: 'success',
          timeout: '2000',
        });
        handleNavigation();
      })
      .catch((err) => {
        console.log(err);
        displayAlert({
          open: true,
          message: 'Error in Deleting Book',
          type: 'error',
          timeout: '2000',
        });
      });
  };

  const handleEditImageOpen = (label) => {
    setLabel(label)
    setImageOpen(true)
  }

  // Bookmarks data generator
  let base = [];
  const type = searchParams.get('type');
  if (type === 'sa') {
    base = [
      {
        _id: 1,
        link: '/super-admin/manage-organization/organizational-quest',
        label: 'Organizational Quest',
      },
    ];
  } else {
    base = [
      {
        _id: 1,
        link: '/admin/my-workspace',
        label: 'My Workspace',
      },
    ];
  }

  const validationSchema = yup.object({
    name: yup.string('Enter Book name').required('Book name is required'),
    description: yup
      .string('Enter Book description')
      .required('Description is required'),
  });

  // Formik form handling and submit
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: bookData?.data.name,
      description: bookData?.data.description,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!thumbnail) {
        return displayAlert({
          open: true,
          message: 'To update Book Image is mandatary',
          type: 'error',
          timeout: '2000',
        });
      }

      const dataToUpdate = { name: values.name, description: values.description, thumbnail, ...selectedTheme }

      updateBook({ _id, data: dataToUpdate })
        // .unwrap()
        .then(() => {
          displayAlert({
            open: true,
            message: 'Successfully Updated Book',
            type: 'success',
            timeout: '2000',
          });
          refetchHandler();
          setDataChanged(false)
        })
        .catch((err) => {
          displayAlert({
            open: true,
            message: 'Error in Updating Book',
            type: 'error',
            timeout: '2000',
          });
        })
    },
  });

  return (
    <div className="container">
      {showBadgeGenerator ? (
        <>
          <BreadcrumbAdmin
            base={[
              ...base,
              {
                _id: 'book',
                link: `/admin/my-workspace/edit-books/${bookData?.data?._id}`,
                label: bookData?.data.name,
                reload: true,
              },
            ]}
            page={'Add New Badge'}
          />
          <div className="editbooks__container">
            <div
              style={{
                padding: '20px 0',
              }}>
              <h4 className="ftwt__b"> Badge name </h4>
              <TextField
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  marginTop: '5px',
                }}
                id="badge-name-field"
                name="badgeName"
                placeholder="Eg. Egyptian Symbol"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  setSelectedBadgeData({
                    ...selectedBadgeData,
                    name: e.target.value,
                  });
                }}
                value={selectedBadgeData.name}
              />
            </div>
            <hr
              className="seprator"
              style={{
                margin: '15px 0',
                height: '0.01px',
              }}
            />
            <div className="editbooks__badge_select_container">
              <div className="editbooks__badge_templates">
                <div
                  style={{
                    backgroundColor: '#3361cf11',
                    color: 'var(--pblue)',
                    padding: '15px 30px',
                  }}>
                  Badge templates
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '10px',
                    padding: '10px',
                  }}>
                  {new Array(9).fill(0).map((_, i) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '10px 0 0 0',
                          cursor: 'pointer',
                        }}>
                        <img
                          key={i}
                          alt="badge_temps"
                          src="https://quest-static-bucket.s3.ap-south-1.amazonaws.com/badge_templates/badge_template_1.png"
                          width={100}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="editbooks__badge_editor">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <ColorLensOutlined
                    fontSize="large"
                    style={{ fontWeight: '200', marginRight: '10px' }}
                  />

                  <ColorPicker
                    value={selectedBadgeData.firstLayer}
                    name="firstLayer"
                    onChange={(e) => {
                      setSelectedBadgeData({
                        ...selectedBadgeData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <ColorPicker
                    value={selectedBadgeData.secondLayer}
                    name="secondLayer"
                    onChange={(e) => {
                      setSelectedBadgeData({
                        ...selectedBadgeData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <ColorPicker
                    value={selectedBadgeData.thirdLayer}
                    name="thirdLayer"
                    onChange={(e) => {
                      setSelectedBadgeData({
                        ...selectedBadgeData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                    height: '100%',
                  }}>
                  <EditableBadge {...selectedBadgeData} />
                </div>
              </div>
            </div>
            <div
              style={{
                margin: '20px 0',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '15px',
              }}>
              <Button
                onClick={() => {
                  setApplyBadgeOpen(false);
                  setShowBadgeGenerator(false);
                }}
                variant="outlined">
                cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  convertToImageFile();
                }}>
                Save & Publish
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <DeleteModal
            label={bookData?.data.name}
            deleteHandler={deleteBookHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
          <EditImages
            label={label}
            setOpen={setImageOpen}
            open={imageOpen}
            setTheme={setTheme}
            selectedTheme={selectedTheme}
            setDataChanged={setDataChanged}
            setThumbnail={setThumbnail}
          />
          <BreadcrumbAdmin
            base={base}
            page={bookData?.data.name}
          />
          {/* <div className="editbooks__points">
            <h4>Points: {bookData?.data.points}</h4>
          </div> */}
          <div className="editbooks__container">
            <div className="editbooks">
              <div className="editbooks__left">
                <div>
                  <h4 className="ftwt__b"> Book Name </h4>
                  <TextField
                  disabled={isView}
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      marginTop: '5px',
                    }}
                    id="book-name-field"
                    name="name"
                    fullWidth
                    variant="outlined"
                    value={formik.values.name}
                    onChange={(e) => {
                      setDataChanged(true);
                      formik.handleChange(e);
                    }}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>

                <div>
                  <h4 className="ftwt__b">Description</h4>
                  <TextField
                  disabled={isView}
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      marginTop: '5px',
                    }}
                    required
                    id="book-description-field"
                    variant="outlined"
                    name="description"
                    fullWidth
                    rows={2}
                    multiline
                    value={formik.values.description}
                    onChange={(e) => {
                      setDataChanged(true);
                      formik.handleChange(e);
                    }}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </div>
                <div className="editbooks__point_badge">
                  <Star
                    style={{
                      color: '#ee7233',
                      fontSize: '18px',
                    }}
                  />
                  <h5> {bookData?.data.points} Points</h5>
                </div>
              </div>
              <div className="editbooks__right">
                <div className="editbooks__right__header">
                  <h6>
                    Display Image
                  </h6>
                  <div onClick={() => { handleEditImageOpen("Display Image") }}>
                    <EditIcon
                      sx={{ fontSize: '18px', color: 'var(--grey400)', ":hover": { cursor: "pointer" } }}
                    />
                  </div>
                </div>
                {thumbnail && (
                  <img
                    src={thumbnail}
                    alt=""
                  />
                )}
              </div>
              <div className="editbooks__theme">
                <div className="editbooks__right__header">
                  <h6>
                    Template
                  </h6>
                  <div onClick={() => { handleEditImageOpen("Template") }}>
                    <EditIcon
                      sx={{ fontSize: '18px', color: 'var(--grey400)', ":hover": { cursor: "pointer" } }}
                    />
                  </div>
                </div>
                {selectedTheme && (
                  <img
                    src={selectedTheme?.previewImage}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="seprator margin-separator"></div>

            <div className="editbooks__save">
              <Button
                variant="outlined"
                color="error"
                disabled={isView}
                onClick={handleDeleteOpen}>
                Delete book
              </Button>
              <Button
                disabled={!dataChanged}
                variant="contained"
                onClick={formik.handleSubmit}>
                Save changes
              </Button>
            </div>
            <AddLevels
              defaultOpen
              levelNo={levels?.data.levels.length + 1}
              levels={levels}
              bookData={bookData?.data}
              currentBookId={_id}
              refetch={refetchHandler}
              isView={isView}
            />
            <div className="editbooks__actions">
              {!bookData?.data?.isPublished && <Button
                variant="contained"
                onClick={() => {
                  handleBookPublish(null);
                }}>
                Save & Publish
              </Button>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
