import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetAdminBookStatisticQuery } from "../../../../../redux-toolkit/features/reports/ReportsApiSlice";
import { Error404 } from "../../../../UI/404/Error404";
import { NoRecord } from "../../../../UI/No record/NoRecord";
import ProgressBar from "../../../../UI/ProgressBar";
import { SortIcon } from "../../../../Super Admin/Manage Organization/Employee/EmployeeTable";

export const DeptBooksStatisticTable = ({
  check,
  searchQuery = "",
  getTableData,
  startDate,
  endDate,
}) => {
  const userDepartmentId = useSelector((state) => state.auth.user.departmentId);

  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('asc');

  const { data, isError, isLoading } = useGetAdminBookStatisticQuery(
    {
      userDepartmentId,
      sortBy: sortBy,
      type: sortType,
      query: searchQuery,
      endDate,
      startDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {}, [check]);

  React.useEffect(() => {
    if (!data) return;
    getTableData && getTableData(data);
  }, [data]);

  const handleSorting = (sortKey) => {
    setSortBy(sortKey);
    setSortType(!sortType || sortType === 'desc' ? 'asc' : 'desc');
  };

  if (isLoading) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }

  if (data.data.length === 0) {
    return <NoRecord />;
  }

  return (
    <div>
      <TableContainer
        sx={{
          height: '100%',
          maxHeight: 'calc( 100vh - 220px)',
          padding: '0 1px',
          width: '1270px',
        }}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead>
            <TableRow className="tableUi__head">
              {check.BookName && (
                <TableCell
                  sx={{ borderRadius: '20px 0px 0px 0px' }}
                  align="center"
                  onClick={() => handleSorting('name')}>
                  <div className="tableUi__head_sortable">
                    Book Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {check.task && <TableCell align="center">No of Tasks</TableCell>}
              {check.pointsLevels && (
                <TableCell align="center">No of Levels</TableCell>
              )}
              {check.pointsCompletions && (
                <TableCell align="center">Points Assigned</TableCell>
              )}
              {check.NoOfCompletions && (
                <TableCell align="center">No of Completions</TableCell>
              )}

              {check.Inprogress && (
                <TableCell
                  sx={{ borderRadius: '0px 20px 0px 0px' }}
                  align="center">
                  No of Inprogress{' '}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.data.map((row, index) => (
                <>
                  <div className="row__spacing"></div>
                  <TableRow
                    className="tableUi___body"
                    key={index}
                    // sx={{
                    //   // "&:last-child td, &:last-child th": {
                    //   // //     border: "1px solid grey",
                    //   // },
                    //   borderRadius: "20px",
                    // }}
                    sx={{ borderRadius: '10px' }}>
                    {check.BookName && (
                      <TableCell
                        sx={{
                          borderRadius: '10px 0px 0px 10px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        <div className="tableUi__rankscir">
                          <div className="tableUi__rank"> {row?.name}</div>
                        </div>
                      </TableCell>
                    )}

                    {check.task && (
                      <TableCell align="center">{row?.tasksCount}</TableCell>
                    )}
                    {check.pointsLevels && (
                      <TableCell align="center">{row?.levelCount}</TableCell>
                    )}
                    {check.pointsCompletions && (
                      <TableCell align="center">
                        {row?.pointsCompletion}
                      </TableCell>
                    )}
                    {check.NoOfCompletions && (
                      <TableCell align="center">
                        {row?.completionCount}
                      </TableCell>
                    )}
                    {check.Inprogress && (
                      <TableCell
                        sx={{
                          borderRadius: '0px 10px 10px 0px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        {row?.inProgressCount}
                      </TableCell>
                    )}
                  </TableRow>
                  <div className="row__spacing"></div>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
