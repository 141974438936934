import { useAlert } from "../../hooks/useAlert";
import './ViewOrganization.css'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useParams, useNavigate } from "react-router-dom";
import { useGetOwnersByIdQuery, useUpdateOwnersByIdMutation } from "../../redux-toolkit/features/owner/ownerApiSlice";
import { Button } from "@mui/material";

export const ViewOrganization = () => {

    const { orgId } = useParams()
    const navigate = useNavigate();
    const { displayAlert } = useAlert();
    const [updateOwnersById] = useUpdateOwnersByIdMutation();
    const handleEditNavigation = (orgId) => {
        navigate(`/quest/owner/update-organization/${orgId}`);
    }

    const handleGoBack = () => {
        navigate('/quest/owner')
    }

    const { data } = useGetOwnersByIdQuery(orgId, {
        skip: orgId === undefined,
        refetchOnMountOrArgChange: true
    });

    const updateOrg = (activeStatus) => {
        const apiData = { formData: { isActive: activeStatus }, orgId: orgId };
        updateOwnersById(apiData)
            .then((res) => {
                displayAlert({
                    open: true,
                    message:
                        res?.message ||
                        "Successfully updated organization",
                    type: "success",
                    timeout: "2000",
                });
                navigate('/quest/owner');
            })
            .catch((err) => {
                displayAlert({
                    open: true,
                    message: err?.data?.message || `Something went wrong`,
                    type: "error",
                    timeout: "2000",
                });
            })
    }

    return (
        <div className='container__accounts'>
            <div className='vieworg'>
                <div className='vieworg__breadcrumbs' onClick={() => { handleGoBack() }}>
                    <h4 className='ftwt__b'>Go Back</h4>
                </div>
                <br />
                <div className='vieworg__container'>
                    {data?.data?.isActive && <div className='vieworg__edit' onClick={() => {
                        handleEditNavigation(orgId)
                    }}>
                        <EditOutlinedIcon
                            sx={{
                                fontSize: '17px',
                                color: "var(--pblue)"
                            }}
                        />
                        <h4>Edit</h4>
                    </div>}
                    <div className='vieworg__details'>
                        <div className='vieworg__company'>
                            <h4 style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>Company Details</h4>
                            <div className='vieworg__companydetails'>
                                <div className='vieworg__companyleft'>
                                    <p className='ftwt__b'>Company Logo</p>
                                    <img src={data?.data?.logo} alt="" />
                                </div>
                                <div className='vieworg__companyright'>
                                    <div className='vieworg__datapoint'>
                                        <p className='ftwt__b'>Company Name</p>
                                        <p className='ftwt__b'>Owner</p>
                                        <p className='ftwt__b'>Domain email</p>
                                    </div>
                                    <div className='vieworg__datapoint'>
                                        <p className='ftwt__r'>{data?.data?.companyName}</p>
                                        <p className='ftwt__r'>{data?.data?.contactPersonFname + " " + data?.data?.contactPersonLname}</p>
                                        <p className='ftwt__r'>{data?.data?.companyEmailDomain}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='vieworg__contact'>
                            <h4>Contact Details</h4>
                            <div className='vieworg__contactdetails'>
                                <div className='vieworg__contactside'>
                                    <div className='vieworg__datapoint'>
                                        <p className='ftwt__b'>Contact Person</p>
                                        <p className='ftwt__b'>Designation</p>
                                        <p className='ftwt__b'>Role</p>
                                    </div>
                                    <div className='vieworg__datapoint'>
                                        <p className='ftwt__r'>{data?.data?.contactPersonFname + " " + data?.data?.contactPersonLname}</p>
                                        <p className='ftwt__r'>{data?.data?.contactPersonDesignation ?? "Org Admin"}</p>
                                        <p className='ftwt__r'>Super Admin</p>
                                    </div>
                                </div>
                                <div className='vieworg__contactside'>
                                    <div className='vieworg__datapoint'>
                                        <p className='ftwt__b'>Email Address</p>
                                        <p className='ftwt__b'>Phone Number</p>
                                    </div>
                                    <div className='vieworg__datapoint'>
                                        <p className='ftwt__r'>{data?.data?.contactPersonEmail}</p>
                                        <p className='ftwt__r'>{data?.data?.contactPersonPhone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    {data?.data?.isActive
                        ? <Button
                            onClick={() => { updateOrg(false) }}
                            style={{ display: 'flex', alignSelf: "flex-end" }}
                            sx={{ width: "100px" }}
                            variant="contained">Deactivate</Button>
                        : <Button
                            onClick={() => { updateOrg(true) }}
                            style={{ display: 'flex', alignSelf: "flex-end" }}
                            sx={{ width: "100px" }}
                            variant="contained">Activate</Button>}
                </div>
            </div>
        </div>
    )
}