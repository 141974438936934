import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./CreateCourse.css";

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCreateCourseMutation } from '../../../redux-toolkit/features/courses/coursesApiSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import DeleteInActiveSvg from '../../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../../assets/Icons/delete_red.svg';
import { useCreateBookMutation, useGetBookThemesQuery } from '../../../redux-toolkit/features/books/bookApiSlice';
import { useAlert } from '../../../hooks/useAlert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '50%',
  maxHeight: '85vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: '25px 40px',
};

const config = {
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
  ],
};

const validationSchema = yup.object({
  name: yup.string('Enter Book name').required('Book name is required'),
  description: yup
    .string('Enter your description')
    .min(10, 'Description should be of minimum 8 characters length')
    .required('Description is required'),
});

export const CreateBooks = ({ type, bookOpen, setBookOpen }) => {
  const [createBook, { isLoading }] = useCreateBookMutation();
  const [thumbnail, setThumbnail] = React.useState();
  const [selectedTheme, setTheme] = React.useState();
  const [isLarge, setIsLarge] = React.useState(false);

  const { data, isBookThemeLoading } = useGetBookThemesQuery({
    refetchOnMountOrArgChange: true,
  })

  const { displayAlert } = useAlert();

  const handleClose = () => {
    formik.handleReset();
    setThumbnail();
    setTheme("")
    setBookOpen(false);
    setIsLarge(false);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!thumbnail) {
        return displayAlert({
          open: true,
          message: `Book Image is manadatry field`,
          type: 'error',
          timeout: '2000',
        });
      }
      // const formData = new FormData();
      // formData.append('name', values.name);
      // formData.append('description', values.description);
      // formData.append('coverImage', thumbnail);
      // formData.append('type', type);
      // formData.append('theme', selectedTheme);
      createBook({ name: values.name, description: values.description, coverImage: thumbnail, type, theme: selectedTheme })
        // .unwrap()
        .then(() => {
          displayAlert({
            open: true,
            message: `Successfully created new book`,
            type: 'success',
            timeout: '2000',
          });
          setThumbnail();
          resetForm();
        })
        .catch((err) => {
          displayAlert({
            open: true,
            message: `${err?.message}`,
            type: 'error',
            timeout: '2000',
          })
        }
        );
      handleClose();
    },
  });
  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);

      setThumbnail(file);
    }
  };

  return (
    <div>
      <div>
        <Modal
          open={bookOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={style}>
              <div className="create__form">
                <div className="create__formhead">
                  <h3>Add Book</h3>
                  <CloseIcon
                    onClick={handleClose}
                    sx={{
                      fontSize: '18px',
                      cursor: 'pointer',
                      color: 'var(--grey400)',
                      '&:hover': {
                        color: 'var(--error)',
                      },
                    }}
                  />
                </div>
                <div className="seprator"></div>
                <br />

                <h5 className="ftwt__r">
                  Book Name <span>*</span>
                </h5>

                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  required
                  id="create-book-name-field"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <h5 className="ftwt__r">
                  Description <span>*</span>
                </h5>
                <TextField
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    marginTop: '5px',
                  }}
                  required
                  id="create-book-description-field"
                  variant="outlined"
                  name="description"
                  fullWidth
                  rows={2}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                <h5 className="ftwt__r">
                  Select Template <span>*</span>
                </h5>
                <div style={{ display: "flex", gap: "20px", overflowX: "auto", marginTop: "10px", paddingBottom: "10px" }}>
                  {data?.data?.themes?.map(theme => (
                    <div>
                      <img src={theme.previewImage} alt="" style={{ objectFit: "contain", height: "150px", borderRadius: "10px", border: theme._id == selectedTheme ? "2px solid var(--pblue)" : "1px solid transparent" }} onClick={() => { setTheme(theme._id) }} />
                      <h4>{theme.name}</h4>
                    </div>
                  ))}
                </div>
                <h5 className="ftwt__r">
                  Select Book Logo <span>*</span>
                </h5>
                <div style={{ display: "flex", gap: "20px", overflowX: "scroll", marginTop: "10px", paddingBottom: "10px" }}>
                  {data?.data?.logos?.map(logo => (
                    <div>
                      <img src={logo.url} alt="" width="100px" height="100px" style={{ padding: "10px 30px",objectFit: "contain",border: logo.url == thumbnail ? "2px solid var(--pblue)" : "1px solid #C3C3C3", borderRadius: "8px" }} onClick={() => setThumbnail(logo.url)} />
                      <h4>{logo.name}</h4>
                    </div>
                  ))}
                </div>
                {/* <div
                  style={{ borderColor: isLarge ? 'var(--error)' : '' }}
                  className="dropzone-wrapper">
                  {thumbnail ? (
                    <div className="dropzone-image">
                      <img
                        height={'100%'}
                        src={URL.createObjectURL(thumbnail)}
                        alt="Thumbnail Preview"
                      />
                      <CloseIcon
                        onClick={() => setThumbnail()}
                        sx={{
                          fontSize: '18px',
                          cursor: 'pointer',

                          color: 'var(--grey400)',
                          '&:hover': {
                            color: 'var(--error)',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className="dropzone-desc">
                      <UploadFileOutlinedIcon
                        sx={{
                          fontSize: '70px',
                        }}
                      />
                      <p
                        style={{
                          fontSize: '14px',
                          // color: isLarge ? 'var(--error)' : '',
                        }}>
                        Choose an image file{' '}
                        <span style={{ fontSize: '11px' }}>
                          or drag it here.
                        </span>
                        <br />
                        <span style={{ fontSize: '11px' }}>
                          Maximum file size 1MB
                        </span>
                      </p>
                    </div>
                  )}
                  {!thumbnail && (
                    <input
                      name="thumbnail"
                      onChange={thumbnailHandler}
                      type="file"
                      accept="image/*"
                      // name="img_logo"
                      className="dropzone"
                    />
                  )}
                </div>
                {isLarge && (
                  <p
                    style={{
                      color: 'var(--error)',
                      marginTop: '5px',
                      fontSize: '12px',
                    }}>
                    Choose Image smaller than 1MB
                  </p>
                )} */}
              </div>
              <br />

              <div className="createcourse__action">
                {/* <button onClick={formik.handleReset} className="outlined__btn">
                  Discard
                </button> */}
                <div
                  onClick={formik.handleReset}
                  className="figure">
                  <img
                    src={DeleteInActiveSvg}
                    className="Sirv image-main"
                    height="22px"
                    alt=""
                  />
                  <img
                    src={DeleteActiveSvg}
                    className="Sirv image-hover"
                    height="22px"
                    alt=""
                  />
                </div>

                <Button
                  type="submit"
                  variant="contained">
                  Create
                </Button>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
    </div>
  );
};

