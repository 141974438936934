import { ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Button, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCourseMutation,
  useGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../../../redux-toolkit/features/courses/coursesApiSlice";
import { BreadcrumbAdmin } from "../../../UI/BreadcrumbAdmin";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";
import ProgressBar from "../../../UI/ProgressBar";
import { AddModuleV2 } from "./AddModulev2";
import { CertificateModal } from "./Certificate Modal/CertificateModal";
import "./EditCourses.css";
import { useAlert } from '../../../../hooks/useAlert';
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { SubModule } from "./Submodules/SubModules";
import { SubModuleDrawer } from "./Submodules/SubModuleDrawer";
import { AddTopic } from "./Submodules/AddTopic";
import { Module } from "./Submodules/Module";
import { Quiz } from "./Submodules/Quiz"

const config = {
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
  ],
};

const base = [
  {
    _id: 1,
    link: '/admin/my-workspace',
    label: 'My Workspace',
  },
];
export const EditCourses = ({ isView = false }) => {
  const { _id } = useParams();
  const { displayAlert } = useAlert();

  const { data, isLoading, refetch, isError } = useGetCourseByIdQuery(_id, {
    refetchOnMountOrArgChange: true,
  });
  const [course, setCourse] = useState({
    name: '',
    // points: "",
    description: '',
  });

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteCourse] = useDeleteCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [showAddModule, setShowAddModule] = useState(false);
  const [selectedModule, setSelectedModule] = React.useState({})
  const [selectedSubModule, setSelectedSubModule] = React.useState({})
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(data?.data?.coverImage);
  const [dataChanged, setDataChanged] = useState(false);
  const [isLarge, setIsLarge] = React.useState(false);
  const ref = useRef(null);
  const [subModDrawer, setSubModDrawer] = useState(false)
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showEditModule, setShowEditModule] = useState("")
  const [question, setShowQuestion] = useState({})
  const [totalModules, setTotalModules] = useState(-1)

  useOutsideClick(ref, () => {
    setSubModDrawer(false)
  });

  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);
      setThumbnail(file);
      setDataChanged(true);
    }
  };

  React.useEffect(() => {
    setThumbnail(data?.data?.coverImage);
    const index = data?.data.modules.length > totalModules ? data?.data.modules.length - 1 : selectedModule?.index
    setSelectedModule({ ...data?.data.modules[totalModules == -1 ? 0 : index], index:totalModules == -1 ? 0 : index })
    if (data?.data.modules.length > totalModules) {
      setShowQuestion({})
      setShowAddTopic(false)
      setShowQuiz(false)
    }
    setTotalModules(data?.data?.modules?.length || -1)
  }, [data]);

  React.useEffect(() => {
    if (showQuiz) setSelectedSubModule(selectedModule?.submodules[selectedModule?.submodules.length - 1])
  }, [selectedModule])

  const navigate = useNavigate();

  useEffect(() => {
    setCourse({
      name: data?.data.name,
      // points: data?.data.points,
      description: data?.data.description,
    });
  }, [data]);

  if (isLoading) {
    return <ProgressBar />;
  }
  const handleChange = (event) => {
    setDataChanged(true);
    setCourse({ ...course, [event.target.name]: event.target.value });
  };

  const refetchHander = async () => {
    setSelectedSubModule({})
    refetch();
  };

  const handleOpen = () => setDeleteOpen(true);
  const deleteCourseHandler = async () => {
    await deleteCourse(_id);
    navigate('/admin/my-workspace');
  };
  const updateCourseHandler = () => {
    //  createCourse(data);
    const formData = new FormData();
    formData.append('name', course.name);
    formData.append('description', course.description);
    formData.append('coverImage', thumbnail);

    updateCourse({ formData, _id }).then(() =>
      displayAlert({
        open: true,
        message: `Successfully updated the course details`,
        type: 'success',
        timeout: '2000',
      })
    );
    setDataChanged(false);
  };

  return (
    <>
      {data && !isError ? (
        <div className="container">
          <BreadcrumbAdmin
            base={base}
            page={data?.data?.name}
          />
          <CertificateModal
            setCertificateOpen={setCertificateOpen}
            certificateOpen={certificateOpen}
            courseId={_id}
            hasCertificate={data?.data.hasCertificate}
          />

          <DeleteModal
            label={data?.data.name}
            deleteHandler={deleteCourseHandler}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />

          <div className="editcourses__container">
            <div className="editcourses">
              <div className="editcourses__left">
                <div>
                  <h4 className="ftwt__b"> Course Name</h4>
                  <TextField
                    disabled={isView}
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      marginTop: '5px',
                    }}
                    id="outlined-basic"
                    onChange={handleChange}
                    value={course.name}
                    name="name"
                    fullWidth
                    variant="outlined"
                  />
                </div>

                <div>
                  <h4 className="ftwt__b">Description</h4>
                  <TextField
                    disabled={isView}
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      marginTop: '5px',
                    }}
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="description"
                    fullWidth
                    rows={2}
                    multiline
                    value={course.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                style={{ borderColor: isLarge ? 'var(--error)' : '' }}
                className="editcourses__right">
                {!thumbnail && (
                  <input
                    name="thumbnail"
                    onChange={thumbnailHandler}
                    type="file"
                    accept="image/*"
                    className="dropzone"
                  />
                )}
                <h6>
                  Display Image <span>*</span>
                </h6>
                {thumbnail && (
                  <img
                    src={
                      !thumbnail.type
                        ? data?.data?.coverImage
                        : URL.createObjectURL(thumbnail)
                    }
                    alt=""
                  />
                )}

                {thumbnail ? (
                  <div
                    onClick={() => setThumbnail()}
                    className="editbooks__cross">
                    {!isView && <CloseIcon
                      sx={{ fontSize: '14px', color: 'var(--grey400)' }}
                    />}
                  </div>
                ) : (
                  <>
                    <div className="dropzone-desc-edit">
                      <UploadFileOutlinedIcon
                        sx={{ fontSize: '50px', color: 'var(--grey400) ' }}
                      />
                      <p
                        style={{
                          fontSize: '14px',
                          // color: isLarge ? 'var(--error)' : '',
                        }}>
                        Choose an image file{' '}
                        <span style={{ fontSize: '11px' }}>
                          or drag it here.
                        </span>
                        <br />
                        <span style={{ fontSize: '11px' }}>
                          Maximum file size 1MB
                        </span>
                      </p>
                    </div>
                    {isLarge && (
                      <p
                        style={{
                          color: 'var(--error)',
                          fontSize: '11px',
                        }}>
                        Choose Image smaller than 1MB
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <br />

            <div className="seprator"></div>
            <br />
            <div className="editcourses__save">
              <Button
                disabled={isView}
                onClick={handleOpen}
                // sx={{ width: "80px" }}
                color="error"
                variant="outlined">
                Delete Course
              </Button>
              <Button
                onClick={updateCourseHandler}
                disabled={!dataChanged || isView}
                // sx={{ width: "80px" }}
                variant="contained">
                Save Changes
              </Button>
            </div>
            {/* This below code is for new view of add modules */}
            <div className="editcourses__new">
              <div className="editcourses__newLeft" style={{ justifyContent: data?.data?.modules.length > 0 || showAddModule ? "flex-start" : "center" }}>
                {data?.data?.modules && data?.data.modules.map((mod, index) => (
                  <Module
                    selectedModule={selectedModule}
                    index={index}
                    setSelectedModule={setSelectedModule}
                    setSelectedSubModule={setSelectedSubModule}
                    setShowAddTopic={setShowAddTopic}
                    setShowQuiz={setShowQuiz}
                    refetch={refetchHander}
                    mod={mod}
                    setShowEditModule={setShowEditModule}
                    showEditModule={showEditModule}
                    setShowQuestion={setShowQuestion}
                    setShowAddModule={setShowAddModule}
                    isView={isView}
                  />
                ))}
                {data?.data?.modules.length > 0 && (<div className="seprator"></div>)}

                {!showAddModule ? (
                  <Button
                    disabled={isView}
                    onClick={() => {
                      setShowAddModule(true);
                    }}
                    variant="contained">
                    + Add Module
                  </Button>
                ) : (<AddModuleV2
                  setShowModule={setShowAddModule}
                  course={data?.data}
                  refetch={refetchHander}
                />)}
              </div>
              <div className="editcourses_rightcontainer" style={{ justifyContent: selectedModule?.submodules?.length > 0 || showAddTopic || showQuiz ? "flex-start" : "center" }}>
                {selectedModule?.submodules?.length > 0 &&
                  <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px",paddingLeft: "5%" }}>
                    <h6 style={{color: "var(--pblue)", cursor: "pointer", maxWidth:Object.keys(question).length > 0?"60%":"100%" }} onClick={() => {
                      setShowAddTopic(false)
                      setShowQuiz(false)
                      setSelectedSubModule({})
                      setShowQuestion({})}}>{selectedModule.name}</h6>
                    {(showQuiz || showAddTopic) && <ChevronRight fontSize="small" style={{ padding: "0px 5px", color: "var(--grey500)" }} />}
                    {(showQuiz || showAddTopic) && <h6 style={{ color: Object.keys(question).length == 0 ? "var(--grey400)" : "var(--pblue)", cursor: "pointer" }} onClick={() => {
                      if (selectedSubModule?.type == 'Quiz') {
                        setShowQuestion({})
                      }
                    }}>{showQuiz ? "Quiz" : "Topic " + (Object.keys(selectedSubModule).length > 0 ? (selectedSubModule.index + 1) : selectedModule.submodules.length + 1)}
                    </h6>}
                    {Object.keys(question).length > 0 && <ChevronRight fontSize="small" style={{ padding: "0px 5px", color: "var(--grey500)" }} />}
                    {Object.keys(question).length > 0 && <h6>Question {(question?.index + 1 || 1)}</h6>}
                  </div>}
                <div className="editcourses__newRight" style={{ flexDirection: selectedModule?.submodules?.length > 0 || showAddTopic || showQuiz ? "column" : "row", justifyContent: selectedModule?.submodules?.length > 0 || showAddTopic || showQuiz ? "flex-start" : "center" }}>
                  {selectedModule?.submodules?.length > 0 ?
                    showQuiz ?
                      <Quiz
                        id={selectedSubModule?._id}
                        setShowQuestion={setShowQuestion}
                        question={question}
                        mod={selectedModule}
                        refetchHandler={refetchHander}
                        setShowQuiz={setShowQuiz}
                        isView={isView}
                      />
                      : showAddTopic ?
                        <AddTopic
                          topic={selectedSubModule}
                          module={selectedModule}
                          course={data?.data}
                          setShowTopic={setShowAddTopic}
                          refetch={refetchHander}
                          isView={isView}
                        />
                        : selectedModule?.submodules.map((sub, index) => (
                          <SubModule
                            submodule={sub}
                            index={index}
                            setShowAddTopic={setShowAddTopic}
                            setSelectedSubModule={setSelectedSubModule}
                            setShowQuiz={setShowQuiz}
                          />
                        ))
                    : showAddTopic ?
                      <AddTopic
                        module={selectedModule}
                        topic={{}}
                        course={data?.data}
                        setShowTopic={setShowAddTopic}
                        refetch={refetchHander}
                      />
                      : showQuiz ? <Quiz
                        id={selectedSubModule?._id}
                        setShowQuestion={setShowQuestion}
                        question={question}
                        mod={selectedModule}
                        refetchHandler={refetchHander}
                        setShowQuiz={setShowQuiz}
                      />
                        : (<div style={{ gap: "20px", display: "flex" }}>
                          <Button
                            disabled={Object.keys(selectedModule).length > 1 && !isView ? false : true}
                            onClick={() => {
                              setShowAddTopic(true)
                            }}
                            variant="outlined">
                            Add Topic
                          </Button>
                          <Button
                            disabled={Object.keys(selectedModule).length > 1 && !isView ? false : true}
                            onClick={() => { setShowQuiz(true) }}
                            variant="outlined">
                            Add Quiz
                          </Button>
                        </div>)}
                  {selectedModule?.submodules?.length > 0 && <div className="editcourses_addSubmodule">
                    {subModDrawer && <div ref={ref}>
                      <SubModuleDrawer
                        setShowAddTopic={setShowAddTopic}
                        setSubModDrawer={setSubModDrawer}
                        setShowQuiz={setShowQuiz}
                      />
                    </div>}
                    {!showAddTopic && !showQuiz && <Button
                      disabled={isView}
                      onClick={() => { setSubModDrawer(true) }}
                      variant="contained"
                      sx={{ minWidth: '12px', height: "40px" }}
                    >
                      <span style={{ fontSize: "25px", color: "white" }}>+</span>
                    </Button>}
                  </div>}
                </div>
              </div>
            </div>
            {
              !data?.data?.isPublish && <div className="editcourses__next">
                <Button
                  onClick={() => setCertificateOpen(true)}
                  // sx={{ width: '80px' }}
                  variant="contained"
                  disabled={data?.data.modules.length === 0 || isView}>
                  Save & Publish
                </Button>
              </div>
            }
          </div>
        </div>
      ) : (
        <div className="container">
          <h3>Something went wrong...</h3>
        </div>
      )}
    </>
  );
};
