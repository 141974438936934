import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar } from '@mui/material';
import { Paper } from '@mui/material';
import Switch from '@mui/material/Switch';
import ProgressBar from '../../../../UI/ProgressBar';
import { useGetdepartmentBookSummaryQuery } from '../../../../../redux-toolkit/features/reports/ReportsApiSlice';
import { Error404 } from '../../../../UI/404/Error404';
import { SortIcon } from '../../../Manage Organization/Employee/EmployeeTable';

export const BookSummaryTable = ({
  check,
  searchQuery = '',
  getTableData,
  startDate,
  endDate,
}) => {
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('asc');

  const { data, isError, isLoading } = useGetdepartmentBookSummaryQuery(
    {
      sortBy: sortBy,
      type: sortType,
      query: searchQuery,
      startDate,
      endDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {
    if (!data) return;
    getTableData && getTableData(data);
  }, [data]);

  const handleSorting = (sortKey) => {
    setSortBy(sortKey);
    setSortType(!sortType || sortType === 'desc' ? 'asc' : 'desc');
  };

  React.useEffect(() => {}, [check]);

  if (isLoading) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }
  return (
    <div>
      <TableContainer
        sx={{
          height: '100%',
          maxHeight: 'calc( 100vh - 220px)',
          padding: '0 1px',
          width: '1450px',
        }}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                borderRadius: '20px 20px 0px 0px',
              }}
              className="tableUi__head">
              {check.depName && (
                <TableCell
                  sx={{ borderRadius: '20px 0px 0px 0px' }}
                  align="center"
                  onClick={() => handleSorting('department_name')}>
                  <div className="tableUi__head_sortable">
                    Department Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {check.hod && (
                <TableCell align="center">Department HOD</TableCell>
              )}
              {check.bookName && (
                <TableCell
                  align="center"
                  onClick={() => handleSorting('name')}>
                  <div className="tableUi__head_sortable">
                    Book Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {/* {check.bookId && <TableCell align="center">Book Id</TableCell>} */}
              {check.bookCreator && (
                <TableCell align="center">Book Creator</TableCell>
              )}
              {check.totalLevels && (
                <TableCell align="center"> Total Levels</TableCell>
              )}
              {check.totalTasks && (
                <TableCell align="center">Total Tasks</TableCell>
              )}

              {check.pointsAssigned && (
                <TableCell
                  sx={{ borderRadius: '0px 20px 0px 0px' }}
                  align="center">
                  Points Assigned
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.data?.map((row, index) => (
                <>
                  <div className="row__spacing"></div>
                  <TableRow
                    className="tableUi___body"
                    key={index}
                    // sx={{
                    //   // "&:last-child td, &:last-child th": {
                    //   //   border: "1px solid grey",
                    //   // },
                    //   borderRadius: "20px",
                    // }}
                    sx={{ borderRadius: '15px' }}>
                    {check.depName && (
                      <TableCell
                        sx={{
                          borderRadius: '15px 0px 0px 15px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        <div className="tableUi__rankscir">
                          <div className="tableUi__rank">
                            {row?.department?.name}
                          </div>
                        </div>
                      </TableCell>
                    )}

                    {check.hod && (
                      <TableCell align="center">
                        {' '}
                        {row?.department?.hod.email}
                      </TableCell>
                    )}
                    {check.bookName && (
                      <TableCell align="center">{row?.name}</TableCell>
                    )}

                    {check.bookCreator && (
                      <TableCell align="center">
                        {row?.createdBy?.fname + ' ' + row?.createdBy?.lname}
                      </TableCell>
                    )}
                    {check.totalLevels && (
                      <TableCell align="center">{row?.levelCount}</TableCell>
                    )}
                    {check.totalTasks && (
                      <TableCell align="center"> {row?.tasksCount}</TableCell>
                    )}

                    {check.pointsAssigned && (
                      <TableCell
                        sx={{
                          borderRadius: '0px 15px 15px 0px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        {row?.points}
                      </TableCell>
                    )}
                  </TableRow>
                  <div className="row__spacing"></div>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
