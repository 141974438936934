import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar } from "@mui/material";
import { Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import ProgressBar from "../../../../UI/ProgressBar";
import { useGetDepartmentUserSummaryQuery } from "../../../../../redux-toolkit/features/reports/ReportsApiSlice";
import { Error404 } from "../../../../UI/404/Error404";
import { SortIcon } from "../../../Manage Organization/Employee/EmployeeTable";

export const UserSummaryTable = ({
  check,
  searchQuery = "",
  getTableData,
  startDate,
  endDate,
}) => {
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("asc");
  const { data, isError, isLoading, isFetching } = useGetDepartmentUserSummaryQuery(
    {
      sortBy: sortBy,
      type: sortType,
      query: searchQuery,
      startDate,
      endDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(() => {
    if (!data) return;
    getTableData && getTableData(data);
  }, [data]);

  const handleSorting = (sortKey) => {
    setSortBy(sortKey);
    setSortType(!sortType || sortType === "desc" ? "asc" : "desc");
  };

  React.useEffect(() => {}, [check]);
  if (isLoading||isFetching) {
    return <ProgressBar />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div>
      <TableContainer
        sx={{
          height: '100%',
          maxHeight: 'calc( 100vh - 220px)',
          padding: '0 1px',
          width: '2000px',
        }}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead>
            <TableRow className="tableUi__head">
              {check.depName && (
                <TableCell
                  sx={{ borderRadius: '20px 0px 0px 0px' }}
                  align="center"
                  onClick={() => handleSorting('department_name')}>
                  <div className="tableUi__head_sortable">
                    Department Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {check.hod && (
                <TableCell align="center">Department HOD</TableCell>
              )}
              {check.empName && (
                <TableCell
                  align="center"
                  onClick={() => handleSorting('fname')}>
                  <div className="tableUi__head_sortable">
                    Employee Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {check.email && (
                <TableCell align="center">Employee Email</TableCell>
              )}
              {check.empId && <TableCell align="center">Employee ID</TableCell>}
              {check.lLogin && (
                <TableCell
                  align="center"
                  onClick={() => handleSorting('lastLogin')}>
                  <div className="tableUi__head_sortable">
                    Last login <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>
              )}
              {check.booksComp && (
                <TableCell align="center">Books Completed</TableCell>
              )}
              {check.bookInProg && (
                <TableCell align="center">Book In Progress</TableCell>
              )}
              {check.totalPointsEarned && (
                <TableCell
                  sx={{ borderRadius: '0px 20px 0px 0px' }}
                  align="center">
                  Total Points Earned{' '}
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data?.data.map((row, index) => (
                <>
                  <div className="row__spacing"></div>
                  <TableRow
                    className="tableUi___body"
                    key={index}
                    // sx={{
                    //   // "&:last-child td, &:last-child th": {
                    //   //   border: "1px solid grey",
                    //   // },
                    //   borderRadius: "20px",
                    // }}
                    sx={{ borderRadius: '10px' }}>
                    {check.depName && (
                      <TableCell
                        sx={{
                          borderRadius: '10px 0px 0px 10px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        <div className="tableUi__rankscir">
                          <div className="tableUi__rank">
                            {row?.department && row.department.name}
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {check.hod && (
                      <TableCell align="center">
                        {row?.department && row.department.hod.email}
                      </TableCell>
                    )}
                    {check.empName && (
                      <TableCell align="center">
                        {row?.fname + ' ' + row.lname}
                      </TableCell>
                    )}
                    {check.email && (
                      <TableCell align="center">{row?.email}</TableCell>
                    )}
                    {check.empId && (
                      <TableCell align="center">{row?.employeeId}</TableCell>
                    )}
                    {check.lLogin && (
                      <TableCell align="center">
                        {row?.lastLogin && row.lastLogin.slice(0, 10)}
                      </TableCell>
                    )}
                    {check.booksComp && (
                      <TableCell align="center">
                        {row?.booksCompleted}
                      </TableCell>
                    )}
                    {check.bookInProg && (
                      <TableCell align="center">
                        {row?.booksInProgress}
                      </TableCell>
                    )}

                    {check.totalPointsEarned && (
                      <TableCell
                        sx={{
                          borderRadius: '0px 10px 10px 0px',
                          //     border: "1px solid grey",
                        }}
                        align="center">
                        {row?.totalPointsEarned}
                      </TableCell>
                    )}
                  </TableRow>
                  <div className="row__spacing"></div>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
