import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import Generic from "../../../../assets/Side Bar/Super Admin/Light Background/generic_default.svg";
import GenericH from "../../../../assets/Side Bar/Super Admin/Light Background/generic_active.svg";
import Theme from "../../../../assets/Side Bar/Super Admin/Light Background/themes default.svg";
import ThemeH from "../../../../assets/Side Bar/Super Admin/Light Background/themes_active.svg";
import Employee from '../../../../assets/Side Bar/Super Admin/Light Background/employee_default.svg';
import EmployeeH from '../../../../assets/Side Bar/Super Admin/Light Background/employee_active.svg';
import Department from '../../../../assets/Side Bar/Super Admin/Light Background/department_default.svg';
import DepartmentH from '../../../../assets/Side Bar/Super Admin/Light Background/department_active.svg';
import Credentials from '../../../../assets/Side Bar/Super Admin/Light Background/badges_default.svg';
import CredentialsH from '../../../../assets/Side Bar/Super Admin/Light Background/badges_active.svg';
import Access from '../../../../assets/Side Bar/Super Admin/Light Background/access_default.svg';
import AccessH from '../../../../assets/Side Bar/Super Admin/Light Background/access_active.svg';
import TaskVerification from '../../../../assets/Side Bar/Light Background/Task Verification _ default.svg';
import TaskVerificationH from '../../../../assets/Side Bar/Light Background/Task Verification _ active.svg';
import TaskVerificationD from '../../../../assets/Side Bar/Dark Background/task verification.svg';
import TaskVerificationDH from '../../../../assets/Side Bar/Super Admin/Dark Background/task_verification_filled.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmployeeDH from '../../../../assets/Side Bar/Super Admin/Dark Background/employee_active.svg';
import ThemesHD from '../../../../assets/Side Bar/Super Admin/Dark Background/themes_active.svg';
import ThemesD from '../../../../assets/Side Bar/Super Admin/Dark Background/themes default.svg';

import CredentialsDH from '../../../../assets/Side Bar/Super Admin/Dark Background/access_active.svg';
import CredentialsD from '../../../../assets/Side Bar/Super Admin/Dark Background/access_default.svg';
import BadgesD from '../../../../assets/Side Bar/Super Admin/Dark Background/badges_default.svg';
import BadgesDH from '../../../../assets/Side Bar/Super Admin/Dark Background/badges_active.svg';
import GenericDH from '../../../../assets/Side Bar/Super Admin/Dark Background/generic_active.svg';
import EmployeeD from '../../../../assets/Side Bar/Super Admin/Dark Background/employee_default.svg';
import GenericD from '../../../../assets/Side Bar/Super Admin/Dark Background/generic_default.svg';
import DepartmentD from '../../../../assets/Side Bar/Super Admin/Dark Background/department_default.svg';
import DepartmentDH from '../../../../assets/Side Bar/Super Admin/Dark Background/department_active.svg';

import RedeemH from '../../../../assets/Side Bar/Super Admin/Light Background/redeem-status-fill-light.svg'
import Redeem from '../../../../assets/Side Bar/Super Admin/Light Background/redeem-status-stroke-light.svg'
import RewardsH from '../../../../assets/Side Bar/Super Admin/Light Background/manage-rewards-fill-light.svg'
import Rewards from '../../../../assets/Side Bar/Super Admin/Light Background/manage-rewards-stroke-light.svg'

import RedeemD from '../../../../assets/Side Bar/Super Admin/Dark Background/redeem-status-stroke-dark.svg'
import RewardsD from '../../../../assets/Side Bar/Super Admin/Dark Background/manage-rewards-stroke-dark.svg'
import RedeemDH from '../../../../assets/Side Bar/Super Admin/Dark Background/redeem-status-fill-dark.svg'
import RewardsDH from '../../../../assets/Side Bar/Super Admin/Dark Background/manage-rewards-fill-dark.svg'

import { Transition } from 'react-transition-group';
import ForumListItem from './ForumListItem';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
const forumItemList = [
  {
    id: 1,
    name: 'Employee',
    icon: Employee,
    iconOnHover: EmployeeH,
    iconD: EmployeeD,
    iconOnHoverD: EmployeeDH,
    altText: 'Employee',
    link: '/super-admin/manage-organization/employee',
    access: ['SUPERADMIN_EMPLOYEES'],
  },
  {
    id: 2,
    name: 'Department',
    icon: Department,
    iconOnHover: DepartmentH,
    iconD: DepartmentD,
    iconOnHoverD: DepartmentDH,
    altText: 'Design',
    link: '/super-admin/manage-organization/departments',
    access: ['SUPERADMIN_DEPARTMENT'],
  },
  {
    id: 3,
    name: 'Access',
    icon: Credentials,
    iconOnHover: CredentialsH,
    iconD: CredentialsD,
    iconOnHoverD: CredentialsDH,
    altText: 'Technical',
    link: '/super-admin/manage-organization/access',
    access: [],
  },
  {
    id: 4,
    name: 'Badge',
    icon: Access,
    iconOnHover: AccessH,
    iconD: BadgesD,
    iconOnHoverD: BadgesDH,
    altText: 'Badge',
    link: '/super-admin/manage-organization/credentials',
    access: [],
  },
];
const questItemList = [
  {
    id: 5,
    name: 'Organizational Quest',
    icon: Generic,
    iconOnHover: GenericH,
    iconD: GenericD,
    iconOnHoverD: GenericDH,
    altText: 'Generic',
    link: '/super-admin/manage-organization/organizational-quest',
    access: ['SUPERADMIN_BOOKS'],
  },
  {
    id: 6,
    name: 'Themes',
    icon: Theme,
    iconOnHover: ThemeH,
    iconD: ThemesD,
    iconOnHoverD: ThemesHD,
    altText: 'Themes',
    link: '/super-admin/manage-organization/themes',
    access: ['SUPERADMIN_THEMES'],
  },
  {
    id: 7,
    name: 'Task Verification',
    icon: TaskVerification,
    iconOnHover: TaskVerificationH,
    iconD: TaskVerificationD,
    iconOnHoverD: TaskVerificationDH,
    altText: 'TaskVerification',
    link: '/super-admin/manage-organization/task-verification',
    access: ['SUPERADMIN_TASK'],
  },
];
const rewardItemList = [
  {
    id: 8,
    name: 'Rewards',
    icon: Rewards,
    iconOnHover: RewardsH,
    iconD: RewardsD,
    iconOnHoverD: RewardsDH,
    altText: 'rewards',
    link: '/super-admin/rewards',
    access: ['SUPERADMIN_REWARDS'],
  },
  {
    id: 9,
    name: 'Redeem Status',
    icon: Redeem,
    iconOnHover: RedeemH,
    iconD: RedeemD,
    iconOnHoverD: RedeemDH,
    altText: 'redeemStatus',
    link: '/super-admin/rewards/redeem-status',
    access: ['SUPERADMIN_REWARDS'],
  },
];


const duration = 500;
const sidebarStyle = {
  transition: `width ${duration}ms`,
};
const sidebarTransitionStyles = {
  entering: { width: '52px' },
  entered: { width: '17vw' },
  exiting: { width: '17vw' },
  exited: { width: '52px' },
};

const opacitySwitchTransitionStyles = {
  entering: { opacity: 0, width: 0, height: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, width: 0, height: 0 },
};
const opacitySwitchButtonTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 0, width: 0, height: 0 },
  exiting: { opacity: 0, width: 0, height: 0 },
  exited: { opacity: 1 },
};

const sidebarLinksWidthTransitionStyles = {
  entering: { width: '100%' },
  entered: { width: '100%' },
  exiting: { width: '100%' },
  exited: { width: '100%' },
};

const opacityArrowTransitionStyles = {
  entering: { transform: 'rotate(0)' },
  entered: { transform: 'rotate(180deg)' },
  exiting: { transform: 'rotate(180deg)' },
  exited: { transform: 'rotate(0)' },
};

const linkLabelStyle = {
  transition: `opacity ${duration}ms`,
};

const linkLabelTransitionStyles = {
  entering: { opacity: 0, width: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, width: 0 },
};

const preTransitionStyles = {
  entering: { display: 'none' },
  entered: { display: '' },
  exiting: { display: '' },
  exited: { display: 'none' },
};

const linkIconTransitionStyles = {
  entering: { flexGrow: 1 },
  entered: { flexGrow: 1 },
  exiting: { flexGrow: 1 },
  exited: { flexGrow: 1 },
};

const SidebarListItem = ({
  id,
  active,
  onClick,
  activeOrg,
  setActiveOrg,
  activeQuest,
  setActiveQuest,
  activeRewards,
  setActiveRewards,
  activeDept,
  setActiveDept,
  theme,
  name,
  icon,
  iconOnHover,
  iconD,
  iconOnHoverD,
  altText,
  link,
  open,
  access,
  handleSidebar,
  handleForumDropDown,
}) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const mode = useSelector((state) => state.theme.mode);

  const { pathname } = useLocation();
  const [iconSrc, setIconSrc] = useState(icon);
  const [isHovering, setIsHovering] = useState(false);

  const handleHoverEnter = () => {
    setIsHovering(true);
  };
  useEffect(() => { }, [activeQuest, activeOrg]);

  const handleHoverLeave = () => {
    setIsHovering(false);
  };
  const handleMouseEnter = (iconPath) => {
    return () => {
      setIconSrc(iconPath);
    };
  };


  const departmentItemList = [
    {
      id: 10,
      name: 'Task Verification',
      icon: TaskVerification,
      iconOnHover: TaskVerificationH,
      iconD: TaskVerificationD,
      iconOnHoverD: TaskVerificationDH,
      altText: 'Task Verification',
      link: '/admin/manage-department/task-verification',
      access: ['ADMIN_TASK_VER'],
    },
    {
      id: 11,
      name: 'Employee',
      icon: Employee,
      iconOnHover: EmployeeH,
      iconD: EmployeeD,
      iconOnHoverD: EmployeeDH,
      altText: 'Employee',
      link: `/admin/manage-department/employee/${user?.departmentId}`,
      access: [],
    }
  ]

  const handleRedirect = () => {
    setIconSrc(iconOnHover);
    if (name === 'Manage Quest') {
      if(!open)handleSidebar()
      if (
        pathname != '/super-admin/manage-organization/organizational-quest' &&
        pathname != '/super-admin/manage-organization/themes' &&
        pathname != '/super-admin/manage-organization/task-verification'
      ) {
        setActiveQuest((prev) => !prev);
        setActiveOrg(false);
        setActiveRewards(false)
        setActiveDept(false)
      }
    }
    if (name === 'Manage Organization') {
      if(!open)handleSidebar()
      if (
        pathname != '/super-admin/manage-organization/employee' &&
        pathname != '/super-admin/manage-organization/credentials' &&
        pathname != '/super-admin/manage-organization/access' &&
        pathname != '/super-admin/manage-organization/departments'
      ) {
        setActiveOrg((prev) => !prev);
        setActiveQuest(false);
        setActiveRewards(false)
        setActiveDept(false)
      }
    }
    if (name === "Manage Department") {
      if(!open)handleSidebar()
      setActiveDept((prev) => !prev)
      setActiveOrg(false);
      setActiveQuest(false);
      setActiveRewards(false)
    }
    if (name === "Manage Rewards") {
      if(!open)handleSidebar()
      setActiveRewards((prev) => !prev)
      setActiveQuest(false);
      setActiveOrg(false);
      setActiveDept(false)
    }
    if (name != 'Manage Quest' && name != 'Manage Organization' && name != 'Manage Rewards' && name != "Manage Department") {
      onSelfClick();
    }

    navigate(link);
  };

  function onSelfClick() {
    setActiveQuest(false);
    setActiveOrg(false);
    setActiveDept(false)
    setActiveRewards(false)
    onClick(id);
  }

  function onForumClick(id) {
    // setActiveOrg(true);
    // setActiveQuest(false);
    onClick(0);
  }

  function onOrgClick(id) {
    // setActiveQuest(true);
    // setActiveOrg(false);
    onClick(0);
  }
  function initialHandler() {
    // setActiveForumItem(questItemList[0].id);
    // setActiveOrgItem(forumItemList[0].id);
  }

  let hasAccess = true;
  if (access.length > 0) {
    hasAccess =
      user?.allowedAccess.filter((value) => access.includes(value)).length > 0;
  }

  return (
    <>
      {hasAccess && (
        <Transition
          in={open}
          timeout={duration}>
          {(state) => (
            <li
              id={`step${id}`}
              // onMouseEnter={handleMouseEnter(iconOnHover)}
              // onMouseLeave={handleMouseEnter(icon)}
              onMouseEnter={handleHoverEnter}
              onMouseLeave={handleHoverLeave}
              onClick={handleRedirect}
              className={`sidebar__link filter__class ${active || isHovering ? 'sidebar__active' : 'inactive'
                }`}
              style={{ ...linkIconTransitionStyles[state] }}>
              <div className="sidebar__linkInfo ">
                <div className="sidebar__icon ">
                  {theme === 'light' ? (
                    <img
                      id="my-icon"
                      src={active || isHovering ? iconOnHoverD : iconD}
                      className={
                        active || isHovering
                          ? 'sidebar__icongrey  '
                          : ' sidebar__iconwhite' //sidebar__iconwhite
                      }
                      alt={altText}
                    />
                  ) : (
                    <img
                      src={active || isHovering ? iconOnHover : icon}
                      className={
                        !active ? 'sidebar__icongrey' : 'sidebar__iconwhite'
                      }
                      alt={altText}
                    />
                  )}
                </div>

                <div
                  style={{
                    ...linkLabelStyle,
                    ...linkLabelTransitionStyles[state],
                  }}>
                  <div style={{ ...preTransitionStyles[state] }}>
                    <pre
                      onClick={initialHandler}
                      style={{ font: 'inherit' }}
                      >
                      {name}
                    </pre>
                  </div>
                </div>
                {name === 'Manage Quest' && (
                  <KeyboardArrowDownIcon
                    sx={{
                      marginLeft: '10px',
                      transform: activeQuest && 'rotate(180deg)',

                      fontSize: '20px',
                    }}
                  />
                )}
                {name === 'Manage Organization' && (
                  <KeyboardArrowDownIcon
                    sx={{
                      marginLeft: '10px',
                      transform: activeOrg ? 'rotate(180deg)' : '',
                      fontSize: '20px',
                    }}
                  />
                )}
                {name === 'Manage Department' && (
                  <KeyboardArrowDownIcon
                    sx={{
                      marginLeft: '10px',
                      transform: activeOrg ? 'rotate(180deg)' : '',
                      fontSize: '20px',
                    }}
                  />
                )}
                {name === 'Manage Rewards' && (
                  <KeyboardArrowDownIcon
                    sx={{
                      marginLeft: '10px',
                      transform: activeRewards ? 'rotate(180deg)' : '',
                      fontSize: '20px',
                    }}
                  />
                )}
              </div>

              {/* <div
                className="sidebar__actionButton"
                style={{ ...linkLabelTransitionStyles[state] }}>
                {name === 'Forum' && (
                  <div
                    onClick={handleForumDropDown}
                    className="sidebar__action">
                    <KeyboardArrowDownIcon
                      className={`forum__dropdown ${active ? 'forumdd__active' : 'forumdd_inactive'
                        }`}
                    />
                  </div>
                )}
              </div> */}
            </li>
          )}
        </Transition>
      )}
      <Transition
        in={open}
        timeout={duration}>
        {(state) => (
          <div
            style={{
              marginBottom: '10px',

              ...sidebarStyle,
              ...sidebarTransitionStyles[state],
            }}>
            <ul
              className="sidebar__forums"
              style={{ ...opacitySwitchTransitionStyles[state] }}>
              {activeOrg ||
                pathname === '/super-admin/manage-organization/employee' ||
                pathname === '/super-admin/manage-organization/credentials' ||
                pathname === '/super-admin/manage-organization/access' ||
                pathname === '/super-admin/manage-organization/departments' ? (
                forumItemList.map((forumItem) => {
                  return (
                    name === 'Manage Organization' && (
                      <ForumListItem
                        isHovering={isHovering}
                        theme={theme}
                        setActiveQuest={setActiveQuest}
                        key={forumItem.id}
                        active={pathname === forumItem.link}
                        pathname={pathname}
                        onClick={onOrgClick}
                        open={open}
                        access={forumItem.access}
                        {...forumItem}
                      />
                    )
                  );
                })
              ) : (
                <></>
              )}
              {activeQuest ||
                pathname ===
                '/super-admin/manage-organization/organizational-quest' ||
                pathname === '/super-admin/manage-organization/themes' ||
                pathname ===
                '/super-admin/manage-organization/task-verification' ? (
                questItemList.map((forumItem) => {
                  return (
                    name === 'Manage Quest' && (
                      <ForumListItem
                        theme={theme}
                        key={forumItem.id}
                        active={pathname === forumItem.link}
                        onClick={onForumClick}
                        open={open}
                        access={forumItem.access}
                        {...forumItem}
                      />
                    )
                  );
                })
              ) : (
                <></>
              )}
              {activeDept ?
                departmentItemList.map((forumItem) => {
                  return (
                    name === 'Manage Department' && (
                      <ForumListItem
                        theme={theme}
                        key={forumItem.id}
                        active={pathname === forumItem.link}
                        onClick={onForumClick}
                        open={open}
                        access={forumItem.access}
                        {...forumItem}
                      />
                    )
                  );
                }) : <></>
              }
              {activeRewards ?
                rewardItemList.map((forumItem) => {
                  return (
                    name === 'Manage Rewards' && (
                      <ForumListItem
                        theme={theme}
                        key={forumItem.id}
                        active={pathname === forumItem.link}
                        onClick={onForumClick}
                        open={open}
                        access={forumItem.access}
                        {...forumItem}
                      />
                    )
                  );
                }) : <></>
              }
            </ul>
          </div>
        )}
      </Transition>
    </>
  );
};

export default SidebarListItem;
