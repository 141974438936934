import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import mail from "../../assets/Login Page/Desktop/Email Icon.png";
import * as yup from "yup";
import { useFormik } from "formik";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useForgotPasswordMutation } from "../../redux-toolkit/features/auth/authApiSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export const Fpassword = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [timer, setTimer] = useState(Date.now() + 15000);

  React.useEffect(() => {}, [timer]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = values;
    },
  });

  const forgotpwHandler = (e, isResend) => {
    e.preventDefault();
    if (!formik.errors.email && formik.values.email !== "") {
      forgotPassword({
        email: formik.values.email,
      }).unwrap();

      navigate("/send-mail");
      setTimer(Date.now() + 15000);
    }
  };

  const resendPwHandler = () => {
    if (timer === 0 && !formik.errors.email && formik.values.email !== "") {
      forgotPassword({
        email: formik.values.email,
      }).unwrap();
    }
  };

  return (
    <>
      <div className="fpassword">
        {location.pathname === "/forgot-password" && (
          <>
            {" "}
            <h4>Reset Password</h4>
            <h5>
              Enter your email ID and we'll send a link on your email to reset
              your password
            </h5>
            <form className="login__form" onSubmit={formik.handleSubmit}>
              <input
                type="email"
                placeholder="E-mail ID"
                className={
                  formik.touched.email && formik.errors.email
                    ? "login__input login__invalid"
                    : "login__input"
                }
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="login__error">
                  <ErrorOutlineIcon fontSize="small" color="error" />
                  <span>{formik.errors.email}</span>
                </div>
              )}

              <Button
                onClick={forgotpwHandler}
                fullWidth
                type="submit"
                sx={{
                  marginTop: "20px",
                  backgroundColor: "var(--sblue)",
                  padding: "15x 10px",
                }}
                variant="contained"
              >
                Send
              </Button>
            </form>
            <Link style={{ textDecoration: "none" }} to={"/login"}>
              <p className="login__forgot">Go Back</p>
            </Link>
          </>
        )}{" "}
        {location.pathname === "/send-mail" && (
          <div className="login__send">
            <img width="20%" src={mail} alt="email icons" />
            <h5>
              We have sent a password reset link to your official email ID
            </h5>
            <h5>
              Click on the ‘Reset Password’ link sent to your email ID and
              create a new password
            </h5>
            <div className="fpassword__footer">
              <div>
                <Countdown
                  date={timer}
                  key={Date.now()}
                  renderer={({ completed, seconds }) =>
                    !completed ? (
                      <p onClick={forgotpwHandler} className="login__forgot">00:{seconds}</p>
                    ) : (
                      <p onClick={forgotpwHandler} className="login__forgot">
                        Resend email?
                      </p>
                    )
                  }
                />
              </div>
              <Link style={{ textDecoration: "none" }} to={"/login"}>
                <p className="login__forgot">Back To Login</p>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
