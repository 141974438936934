import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./AddTask.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import DeleteActiveSvg from '../../../../assets/Icons/delete_red.svg';
import { AddTaskCourse } from "./AddTaskCourse";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAlert } from "../../../../hooks/useAlert";
import {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useGetTaskCourseQuery,
} from "../../../../redux-toolkit/features/books/bookApiSlice";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";
import { ReactComponent as DeleteOutlineIcon } from "../../../../assets/delete_icon.svg"

export const AddTask = ({
  isAdd,
  taskData,
  level,
  isView,
  isCourse,
  setAddTask
}) => {
  // States
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [enableSave, setEnableSave] = useState(false);

  const { data } = useGetTaskCourseQuery(null,{refetchOnMountOrArgChange:true});
  const [selectedCourse, setSelectedCourse] = useState("");

  // Custom hooks
  const { displayAlert } = useAlert();

  // RTKQuery hooks
  const [createTask] = useCreateTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();

  // Handler
  const handleDeleteOpen = () => setDeleteOpen(true);
  const deleteTaskHandler = async () => {
    const taskId = taskData?._id;
    deleteTask(taskId)
      .unwrap()
      .then(() => {
        displayAlert({
          open: true,
          message: 'Successfully Deleted Task',
          type: 'success',
          timeout: '2000',
        });
        setAddTask('')
      })
      .catch((err) => {
        console.log(err);
        displayAlert({
          open: true,
          message: 'Error in Deleting Task',
          type: 'error',
          timeout: '2000',
        });
      });
  };

  // Formik validation
  const validationSchema = yup.object({
    description: yup
      .string("Enter Level description")
      .required("Description is required"),
    points: yup
      .number("Must be a number!")
      .required("Points are required")
      .positive("Must be a positive value!")
      .min(1, "Must be greater than or equal to 1!"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: taskData?.description??"",
      points: taskData?.points ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (Object.keys(taskData).length==0) {
        if (isCourse && !selectedCourse) {
          return displayAlert({
            open: true,
            message: 'Select Course To create task',
            type: 'error',
            timeout: '2000',
          });
        }
        const task = {
          name: `Task ${(taskData?.tasks?.length||0) + 1}`,
          description: values.description,
          course: selectedCourse?.value,
          points: values.points,
        };

        createTask({
          bookId: level.book,
          levelId: level._id,
          task,
        })
          .unwrap()
          .then(async () => {
            displayAlert({
              open: true,
              message: 'Successfully Created Task',
              type: 'success',
              timeout: '2000',
            });
            // await refetch();
            setAddTask('')
            formik.handleReset();
            setTimeout(() => {
              const textArea = document.getElementById(
                'task-description-add-field'
              );

              textArea?.focus();

              textArea?.scrollIntoView({
                behavior: 'smooth',
              });
            }, 1000);
          })
          .catch((err) => {
            formik.handleReset();
            displayAlert({
              open: true,
              message: 'Error in Creating Task',
              type: 'error',
              timeout: '2000',
            });
            formik.handleReset();
          });
      } else {
        if (isCourse && !selectedCourse) {
          return displayAlert({
            open: true,
            message: 'Select Course To create task',
            type: 'error',
            timeout: '2000',
          });
        }
        let updateData;
        if (isCourse) {
          updateData = { ...values, course: selectedCourse.value };
        }

        if (!isCourse) {
          updateData = { ...values, course: null };
        }
        updateTask({
          taskId: taskData._id,
          updateTaskBody: updateData,
        })
          .unwrap()
          .then(() => {
            displayAlert({
              open: true,
              message: 'Successfully Updated Task',
              type: 'success',
              timeout: '2000',
            });
            formik.handleReset();
            setAddTask('')
          })
          .catch((err) => {
            displayAlert({
              open: true,
              message: 'Error in Updating Task',
              type: 'error',
              timeout: '2000',
            });
          });
      }
    },
  });

  React.useEffect(() => {
    if (!isAdd && taskData.course != null && data) {
      const preCourse = data?.data.filter(
        (item) => item.value === taskData.course
      )[0];
      setSelectedCourse(preCourse);
    }
  }, [data]);

  // React.useEffect(() => {
  //   if (isAdd) {
  //   }
  // }, [taskData]);

  const courseHandler = (e) => {
    setSelectedCourse(e.target.value);
  };

  return (
        <div
          className="addTasks"
          key={level.index + 1}>
          <h6>
            <span onClick={()=>{setAddTask('')}}>Level {level.index + 1}</span> &gt; Task {(taskData?.index??level?.tasks?.length) + 1}
          </h6>
          <h4 className="ftwt__r ">Question</h4>
          <TextField
          disabled={isView}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              marginTop: '5px',
            }}
            required
            id="task-description-add-field"
            variant="outlined"
            name="description"
            fullWidth
            rows={2}
            multiline
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={
              formik.touched.description && formik.errors.description
            }
          />
          {isCourse && (
            <h4 className="ftwt__r task__points">Select Course</h4>
          )}

          {isCourse && (
            <FormControl sx={{ width: '30%', marginTop: '5px',}}>
              <Select
              disabled={isView}
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                defaultValue={data?.data?.filter((course)=>course.value==taskData?.course)[0]}
                onChange={courseHandler}>
                {data?.data?.map((item, index) => (
                  <MenuItem
                    sx={{ fontSize: '14px', alignSelf:"flex-start" }}
                    key={index}
                    value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <h4 className="ftwt__r task__points">Points</h4>
          <TextField
          disabled={isView}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              marginTop: '5px',
            }}
            type={'number'}
            id="task-points-add-field"
            name="points"
            value={formik.values.points}
            onChange={formik.handleChange}
            fullWidth
            variant="outlined"
            error={formik.touched.points && Boolean(formik.errors.points)}
            helperText={formik.touched.points && formik.errors.points}
          />

          {!isView&&<div className="task__actions">
            {Object.keys(taskData).length==0?<Button
                  variant="outlined"
                  onClick={() => { setAddTask('') }}
                >
                  Cancel
                </Button>:<DeleteOutlineIcon 
                onClick={deleteTaskHandler}
                style={{
              cursor: 'pointer',
              color: 'var(--grey400)',
              '&:hover': {
                color: 'var(--error)',
              }
            }} />}
            <Button
              onClick={formik.handleSubmit}
              variant="contained">
              Save
            </Button>
          </div>}
        </div>
  );
};
