import React, { useState } from "react";
import "./PointBadgeTimeline.css";
import { useSelector } from "react-redux";
import { CheckBoxRounded, CheckCircleOutline } from "@mui/icons-material";
import { useGetUserPointBasedBadgesQuery } from "../../../redux-toolkit/features/my-activity/myActivityApiSlice";

const PointBadgeItem = ({ url, isCompleted, points, title }) => {
  return (
    <div className="pb__item">
      <p>
        <b>{title}</b>
      </p>
      <img
        style={{
          filter: !isCompleted ? 'brightness(50%)' : '',
        }}
        alt="badge"
        src={url}
      />
    </div>
  );
};

const PointBadgeCheckpoint = ({ isCompleted, title, points }) => {
  return (
    <div
      style={{
        border: isCompleted ? '3px solid #ffbe18' : '3px solid #c3c3c3',
      }}>
      <span className={`tooltip ${isCompleted ? '' : 'tooltip-inactive'}`}>
        <b> {points} Points</b>
      </span>
      {isCompleted ? (
        <CheckCircleOutline color="success" />
      ) : (
        <strong style={{ color: 'rgb(92,92,92)' }}>·</strong>
      )}
    </div>
  );
};

const PointBadgeTimeline = () => {
  const user = useSelector((state) => state.auth.user);
  let completedBadgeCount = 0;

  const { data, isLoading } = useGetUserPointBasedBadgesQuery(user?._id);

  if (isLoading) return null;

  return (
    <div className="pb__container">
      <div className="pb__badgescont">
        {data?.data?.map((badge) => {
          if (badge.hasEarned) {
            completedBadgeCount++;
          }
          return (
            <PointBadgeItem
              key={badge?._id}
              title={badge?.badgeName}
              isCompleted={badge?.hasEarned}
              url={badge?.badgeImage}
              points={badge?.valueThreshold}
            />
          );
        })}
      </div>
      <div className="pb__timeline">
        <div
          className="pb__timelinecomplete"
          style={{
            width: `${[12, 30, 50, 70, 100][completedBadgeCount - 1]}%`,
          }}></div>
        <div className="pb__timelineprofilecircle">
          <img
            src={user?.avatar}
            alt="avatar"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '100%',
            }}
          />
        </div>
        <div className="pb__badgepointscont">
          {data?.data?.map((badge) => {
            return (
              <PointBadgeCheckpoint
                key={badge?._id}
                points={badge?.valueThreshold}
                title={badge?.badgeName}
                isCompleted={badge?.hasEarned}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PointBadgeTimeline;
