import { Button, TextField } from "@mui/material";
import React, { useState, useRef } from "react";
import {
    useCreateModuleMutation,
    useDeleteModuleMutation,
    useUpdateModuleMutation,
} from "../../../../redux-toolkit/features/courses/coursesApiSlice";
import { useAlert } from "../../../../hooks/useAlert";


export const AddModuleV2 = ({
    setShowModule,
    course,
    refetch
}) => {

    const [module, setModule] = useState("");
    const [createModule] = useCreateModuleMutation();
    const [deleteModule] = useDeleteModuleMutation();
    const { displayAlert } = useAlert();
    const ref = useRef(null);

    React.useEffect(()=>{
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', });
    },[])


    const saveModuleHandler = async () => {
        const data = { name: module, submodules: [] };
        const finalModule = { data, courseId: course?._id };
        await createModule(finalModule);
        setModule("");
        // await setSelectedModule({index:nextModuleIndex})
        setShowModule(false);
        await refetch();
        displayAlert({
            open: true,
            message: "Added New Module Successfully",
            type: "success",
            timeout: "1500",
        });
    };
    return (
        <div className="editcourses__accordiansForm">
            <div>
                <h4 className="ftwt__r">Module Name</h4>
                <TextField
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        marginTop: '5px',
                    }}
                    id="outlined-basic"
                    value={module}
                    onChange={(e) => setModule(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
                <div className="editcourses__moduleAction">
                    {/* <Button
                        onClick={deleteModuleHandler}
                        style={{
                            color: "var(--grey400)",
                            borderColor: "var(--grey200)",
                        }}
                        variant="outlined">
                        Delete
                    </Button> */}
                    <Button
                    ref={ref}
                        onClick={saveModuleHandler}
                        variant="contained">
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}