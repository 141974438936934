import React from "react";
import arrow from "../../assets/Icons/arrow.svg";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import "./Books.css";
import { BooksCover } from "./BooksCover";
import { BooksContent } from "./BooksContent";
import { useParams, useLocation, Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useGetBookByIdQuery } from "../../redux-toolkit/features/books/bookApiSlice";
import ProgressBar from "../UI/ProgressBar";
import { bookModeToggler } from "../../redux-toolkit/features/theme/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Error404 } from '../UI/404/Error404';

export const Books = () => {
  const { bookId } = useParams();
  const dispatch = useDispatch();
  const bookMode = useSelector((state) => state.theme.bookMode);
  const mode = useSelector((state) => state.theme.mode);

  const {
    data: bookData,
    isLoading,
    isError,
    refetch,
  } = useGetBookByIdQuery(bookId);

  React.useEffect(() => {
    // if (bookData?.data.type === "ORG") {
    dispatch(bookModeToggler(bookData?.data?.theme?.mode));
    // } else {
    //   dispatch(bookModeToggler(mode));
    // }
  }, [bookData]);

  if (isLoading) {
    return (
      <div style={{ height: '100vh', backgroundColor: 'white' }}>
        <ProgressBar />
      </div>
    );
  }
  if (isError) {
    return (
      <div style={{ height: '100vh' }}>
        <Error404 />
      </div>
    );
  }

  return (
    <>
      {bookData && (
        <div className={bookMode === 'dark' ? 'darkBook' : ''}>
          <div className="bg__books">
            <div className="books__layout ">
              <div className="container__books">
                <div className="books__breadcrumbs">
                  <Link to="/quest" style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"10px", textDecoration:"none"}}>
                    <div className="books__breadcrumbshome">
                      <HomeRoundedIcon
                        sx={{
                          padding: '5px',
                          color: bookMode === 'dark' ? 'var(--pblue)' : 'white',
                          '&:hover': {
                            color: bookMode === 'dark' ? 'white' : '#444444',
                          },
                        }}
                      />
                    </div>
                    <h4
                        className="ftwt__r"
                        style={{
                          color: bookMode === 'dark' ? 'var(--pblue)' : 'white',
                        }}>
                        Home
                      </h4>
                  </Link>
                </div>
                <div className="books">
                  <div className="books__lyt">
                    <BooksCover coverImage={bookData?.data?.coverImage} />
                  </div>
                  <div className="books__ryt">
                    <BooksContent
                      bookData={bookData?.data}
                      _id={bookId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
