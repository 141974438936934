import { Divider, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import "./Profile.css";
import ProfileListItem from "./ProfileListItem";

import feedbackSvg from "../../assets/Icons/profile/new feedback_default.svg";
import feedbackSvgH from "../../assets/Icons/profile/new feedback_filled.svg";

import accountSvgH from "../../assets/Icons/profile/Account _ hover state.svg";
import accountSvg from "../../assets/Icons/profile/Account _ normal state.svg";
import logout from "../../assets/Icons/profile/Logout _ normal state.svg";
import notificationProfileSvgH from "../../assets/Icons/profile/Notification _ Profile _ hover state.svg";
import notificationProfileSvg from "../../assets/Icons/profile/Notification _ Profile _ normal state.svg";
import userManualSvgH from "../../assets/Icons/profile/User manual _ hover state.svg";
import userManualSvg from "../../assets/Icons/profile/User manual _ normal state.svg";
import UserGuide from "../../assets/Icons/profile/User Guide.svg";
import UserGuideH from "../../assets/Icons/profile/User Guide_hover.svg";
// import logoutH from "../../assets/Icons/profile/Logout _ hover state.svg"
import DefaultMaleProfilePic from "../../assets/Icons/profile/Profile icon _ male.png";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { roleToggler } from "../../redux-toolkit/features/access/accessSlice";
import { useSendLogoutMutation } from "../../redux-toolkit/features/auth/authApiSlice";
import { logoutAuth } from "../../redux-toolkit/features/auth/authSlice";
import {
  logoutTheme,
  setStep,
  setTourActive,
} from "../../redux-toolkit/features/theme/themeSlice";

const profileListItems = [
  {
    name: "Account",
    guide: "step15",
    icon: accountSvg,
    iconOnHover: accountSvgH,
    link: "/quest/account",
  },
  {
    name: "My Activity",
    icon: notificationProfileSvg,
    iconOnHover: notificationProfileSvgH,
    link: "/quest/my-activity",
  },
  {
    name: "Quest Walkthrough",
    icon: UserGuide,
    iconOnHover: UserGuideH,
    link: "",
  },
  {
    name: "User Manual",
    icon: userManualSvg,
    guide: "step17",

    iconOnHover: userManualSvgH,
    link: "https://quest-static-bucket.s3.ap-south-1.amazonaws.com/user_manual.pdf",
    newTab: true,
  },
  {
    name: "Feedback",
    icon: feedbackSvg,
    guide: "step18",

    iconOnHover: feedbackSvgH,
    // link: '/quest/my-activity',
  },
  {
    name: "Owner Panel",
    icon: accountSvg,
    iconOnHover: accountSvgH,
    link: "/quest/owner",
    hidden: (user) => {
      return !user?.isOwner;
    },
  },
];

const rolesList = [
  {
    label: "Employee",
    link: "/quest",
    access: [
      "ADMIN_COURSES",
      "ADMIN_BOOKS",
      "ADMIN_TASK_VER",
      "ADMIN_REPORTS",
      "SUPERADMIN_BOOKS",
      "SUPERADMIN_EMPLOYEES",
      "SUPERADMIN_DEPARTMENT",
      "SUPERADMIN_THEMES",
      "SUPERADMIN_REPORTS",
      "SUPERADMIN_REWARDS"
    ],
  },
  {
    label: "Admin",
    link: "/admin",
    links: {
      'ADMIN_COURSES': "/admin/my-workspace",
      'ADMIN_BOOKS': "/admin/my-workspace",
      'ADMIN_TASK_VER': "/admin/manage-department/task-verification",
      'ADMIN_REPORTS': "/admin",
    },
    access: ["ADMIN_COURSES", "ADMIN_BOOKS", "ADMIN_TASK_VER", "ADMIN_REPORTS"],
  },
  {
    label: "Super Admin",
    link: "/super-admin",
    links: {
      'SUPERADMIN_BOOKS': "/super-admin/manage-organization/organizational-quest",
      'SUPERADMIN_EMPLOYEES': "/super-admin/manage-organization/employee",
      'SUPERADMIN_DEPARTMENT': "/super-admin/manage-organization/departments",
      'SUPERADMIN_THEMES': "/super-admin/manage-organization/themes",
      'SUPERADMIN_REPORTS': "/super-admin",
      'SUPERADMIN_TASK': "/super-admin/manage-organization/task-verification",
      'SUPERADMIN_REWARDS': "/super-admin/rewards",
    },
    access: [
      "SUPERADMIN_BOOKS",
      "SUPERADMIN_EMPLOYEES",
      "SUPERADMIN_DEPARTMENT",
      "SUPERADMIN_THEMES",
      "SUPERADMIN_REPORTS",
      "SUPERADMIN_REWARDS"
    ],
  },
];

const profileDown = [
  {
    target: "#step0",
    title: "kickstart",
    content:
      "Specific users have permissions to switch the access. This access can allow you to monitor the users and create an engaging platform. ",
  },
  {
    target: "#step16",
    title: "Switch View",
    content:
      "Specific users have permissions to switch the access. This access can allow you to monitor the users and create an engaging platform. ",
  },
  {
    target: "#step15",
    title: "Account",
    content:
      "Here you can do basic settings on your account. You can also check your activity and overall performance. ",
  },

  {
    target: "#step17",
    title: "User Manual",
    content:
      "Retrieve the walk-through of the platform and the functionality of each feature in the manual.",
  },
  {
    target: "#step18",
    title: "Feedback",
    content: "Submit your feedback at your convenience.",
  },
];

export const Profile = ({ theme, toggleDrawer }) => {
  const selectedRole = useSelector((state) => state.access.selectedRole);
  const dispatch = useDispatch();
  const { displayAlert } = useAlert();
  const [steps, setSteps] = useState();

  const [role, setRole] = React.useState(
    rolesList.filter((item) => item.label === selectedRole)[0]
  );

  const [allRole, setAllRole] = React.useState([]);

  const [sendLogout] = useSendLogoutMutation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const logoutHandler = async () => {
    await sendLogout()
      .unwrap()
      .then(() => {
        dispatch(logoutTheme());
        dispatch(logoutAuth());

        navigate("/login");
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: "Error in Updating Book",
          type: "error",
          timeout: "2000",
        });
      });
  };

  React.useEffect(() => {
    if (selectedRole) {
      setRole(rolesList.filter((item) => item.label === selectedRole)[0]);
    }
  }, [selectedRole]);

  const roleHandler = async (event) => {
    const { label, link,links } = event.target.value;
    await setRole(label);
    dispatch(roleToggler(label));
    if(!user?.allowedAccess.includes("ADMIN_REPORTS") && label=="Admin"){
      navigate(links[user?.allowedAccess[0]])
    } else if (!user?.allowedAccess.includes("SUPERADMIN_REPORTS") && label=="Super Admin"){
      navigate(links[user?.allowedAccess[0]])
    }
    else navigate(link);
  };

  React.useEffect(() => {
    const filteredRolesList = rolesList.filter((role) => {
      return role.access.some((access) => user?.allowedAccess.includes(access));
    });
    setAllRole(filteredRolesList);
  }, [user]);

  const showScreen = (screen) => {
    let existingScreens =
      JSON.parse(localStorage.getItem("alpha_screens")) || [];
    if (!existingScreens.includes(screen)) return true;
    return false;
  };

  React.useEffect(() => {
    dispatch(setStep(profileDown));

    setTimeout(() => {
      if (showScreen("PROFILE_DD")) {
        dispatch(setTourActive(true));
      }
    }, 500);
    return clearTimeout();
  }, []);


  return (
    <div className="profile">
      <div className="profile__head">
        <Avatar
          alt="Remy Sharp"
          sx={{ width: "65px", height: "65px" }}
          src={user?.avatar ? user?.avatar : DefaultMaleProfilePic}
        />
        <div className="profile__headcontent">
          <div className="profile__headcontent__item">
            <h6>
              {user?.fname} {user?.lname}
            </h6>
          </div>
          <div className="profile__headcontent__item">
            <p style={{ fontWeight: "600" }}>ID: {user?.employeeId}</p>
          </div>

          {(user?.role === "CUSTOM_ROLE" ||
            user?.role === "ADMIN" ||
            user?.role === "SUPERADMIN") && (
              // <div id="step6">
              <FormControl id="step16" sx={{ width: "90%", marginTop: "5px" }}>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth "
                  value={role}
                  onChange={roleHandler}
                >
                  {allRole?.map((item, index) => {
                    return (
                      <MenuItem
                        onClick={toggleDrawer("top", false)}
                        sx={{ fontSize: "14px" }}
                        key={index}
                        value={item}
                      >
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              // </div>
            )}
        </div>
      </div>
      <Divider variant="fullWidth" sx={{ my: "10px" }} />{" "}
      <div>
        <ul>
          {profileListItems.map((item, index) => {
            if (item.hidden && item.hidden(user)) return null;
            return (
              <div
                id={
                  (item.name === "Account" && "step4") ||
                  (item.name === "User Manual" && "step5")
                }
                onClick={toggleDrawer("top", false)}
                key={index}
              >
                <ProfileListItem
                  index={index}
                  name={item.name}
                  icon={item.icon}
                  iconOnHover={item.iconOnHover}
                  link={item.link}
                  guide={item.guide}
                  theme={theme}
                  role={role}
                  newTab={item.newTab}
                />
              </div>
            );
          })}
        </ul>
      </div>
      <Divider variant="fullWidth" sx={{ my: "10px" }} />{" "}
      <div onClick={logoutHandler} className="profile__logout">
        <ul>
          <ProfileListItem
            name={"Log Out"}
            icon={logout}
            iconOnHover={logout}
            link={"logout"}
            theme={theme}
          />
        </ul>
      </div>
    </div>
  );
};
