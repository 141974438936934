import { AccessTime } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../assets/Icons/Delete _ Default.svg";
import { ReactComponent as EditIcon } from "../../../assets/Icons/EditIcon.svg";
import { useAlert } from "../../../hooks/useAlert";
import {
  useDeleteBookMutation,
  usePublishBookMutation,
  useUnPublishBookMutation,
} from "../../../redux-toolkit/features/books/bookApiSlice";
import { useDeleteCourseMutation } from "../../../redux-toolkit/features/courses/coursesApiSlice";
import { getFormattedTimeInHoursAndMinuteByMinutes } from "../../../utils";
import { ConfirmModal } from "../../UI/Confirm Modal/ConfirmModal";
import { DeleteModal } from "../../UI/Delete Modal/DeleteModal";
import { Toggle } from "../../UI/Toggle";
import "./AdminCard.css";
import WorkspaceDrawer from "./WorkspaceDrawer";

export const AdminCard = ({
  isCourse,
  isBook,
  isAdmin,
  setDrawer,
  drawer,
  isGeneric,
  handleBookOpen,
  handleOpen,
  refetch,
  data
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [drawer, setDrawer] = React.useState(0);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [deleteCourse] = useDeleteCourseMutation();
  const [deleteBook] = useDeleteBookMutation();
  const [unpublishBook] = useUnPublishBookMutation();
  const [publishBook] = usePublishBookMutation();

  const { displayAlert } = useAlert();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editBookHandler = (_id) => {
    if (isGeneric) {
      navigate(
        `/super-admin/manage-organization/organizational-quest/edit-books/${_id}?type=sa`
      );
    } else {
      navigate(`/admin/my-workspace/edit-books/${_id}`);
    }
  };

  const viewBookHandler = (_id) => {
    if(isGeneric){
      navigate(
        `/super-admin/manage-organization/organizational-quest/view-books/${_id}`
      );
    }
    else navigate(`/admin/my-workspace/view-books/${_id}`);
  };

  const deleteBookHandler = (_id) => {
    deleteBook(_id).then(() =>
      displayAlert({
        open: true,
        message: `Successfully moved to trash`,
        type: "success",
        timeout: "2000",
      })
    );
  };
  const editCourseHandler = async (_id) => {
    await navigate(`/admin/my-workspace/edit-courses/${_id}`);
    setDrawer(0);
  };

  const viewCourseHandler = async (_id) => {
    await navigate(`/admin/my-workspace/view-courses/${_id}`);
    setDrawer(0);
  };

  const deleteCourseHandler = async (_id) => {
    await deleteCourse(_id).then(() =>
      displayAlert({
        open: true,
        message: `Successfully moved to trash`,
        type: "success",
        timeout: "2000",
      })
    );

    refetch();
  };

  const handleBookPublish = (file) => {
    publishBook({
      _id: data?._id,
    })
      .unwrap()
      .then((res) => {
        displayAlert({
          open: true,
          message: res?.message || "Book has been published",
          type: "success",
          timeout: "2000",
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: err?.data?.message || `Error while publishing the book`,
          type: "error",
          timeout: "2000",
        });
      })
      .finally(() => {
        refetch();
      });
  };

  const handleConfirmModal = (isPublished) => {
    setConfirmOpen(true);
  };

  const handlePublishModal = (isPublished) => {
    if (isPublished) {
      handleBookUnPublish();
    } else {
      handleBookPublish();
    }
  };

  const handleBookUnPublish = () => {
    unpublishBook({
      _id: data?._id,
    })
      .unwrap()
      .then((res) => {
        displayAlert({
          open: true,
          message: res?.message || "Book has been unpublished",
          type: "error",
          timeout: "2000",
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: err?.data?.message || `Error while unpublishing the book`,
          type: "error",
          timeout: "2000",
        });
      })
      .finally(() => {
        refetch();
      });
  };

  return (
    <div
      className="admincard"
      style={{
        position: "relative",
      }}>
      {/* //TODO same as department dropdown */}
      {/* //alert */}
      {isBook && (
        <div className="departments__more">
          {drawer !== data._id && (
            <MoreVertIcon
              onClick={() => setDrawer(data._id)}
              sx={{
                color: "white",
                fontSize: "22px",
                margin: "5px",
                padding: "2px",
                background: "rgba(68, 68, 68, 0.4)",
                borderRadius: "5px",
              }}
            />
          )}
          {drawer === data._id && (
            <WorkspaceDrawer
              editbtnHandler={() => editBookHandler(data?._id)}
              viewbtnHandler={()=>viewBookHandler(data?._id)}
              deletebtnHandler={() => setDeleteOpen(true)}
              setDrawer={setDrawer}
              isView={!data?.canModify}
            />
          )}
          <DeleteModal
            label={data?.name}
            deleteHandler={() => deleteBookHandler(data?._id)}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
          <ConfirmModal
            isPublished={data?.isPublished}
            label={data?.name}
            handlePublishModal={handlePublishModal}
            setConfirmOpen={setConfirmOpen}
            confirmOpen={confirmOpen}
          />
        </div>
      )}
      {isCourse && (
        <div className="departments__more">
          {drawer != data._id && (
            <MoreVertIcon
              onClick={() => setDrawer(data._id)}
              sx={{
                color: "white",
                fontSize: "22px",
                margin: "5px",
                padding: "2px",
                background: "rgba(68, 68, 68, 0.4)",
                borderRadius: "5px",
              }}
            />
          )}
          {drawer === data._id && (
            <WorkspaceDrawer
              editbtnHandler={() => editCourseHandler(data?._id)}
              viewbtnHandler={() => viewCourseHandler(data?._id)}
              deletebtnHandler={() => setDeleteOpen(true)}
              setDrawer={setDrawer}
              isView={!data?.canModify}
            />
          )}
          <DeleteModal
            label={data?.name}
            deleteHandler={() => deleteCourseHandler(data?._id)}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
          />
        </div>
      )}
      <div className="admincard__imgcontainer">
        <img
          src={
            isCourse || isAdmin
              ? data.coverImage ||
                "https://images.unsplash.com/photo-1562679299-266edbefd6d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80"
              : data.wheelImage ||
                "https://images.unsplash.com/photo-1562679299-266edbefd6d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80"
          }
          alt=""
          onClick={() => setDrawer(0)}
          // style={{
          //   objectFit:"cover",
          //   // width: "70%",
          //   // maxWidth: "70%",
          //   // heighth: "100px",
          //   maxHeight: "150px",
          //   overflow: 'hidden'
          // }}
          className="admincard__img"
        />
      </div>
      {/* <div className="admincard__edit" onClick={handleClick}>
        <KebabMenu />
      </div> */}
      {/* <div className="admincard__editAdmin">
          {isBook && (
            <h5 onClick={() => editBookHandler(data._id)} className="ftwt__r">
              Edit Book
            </h5>
          )}
          {isCourse && (
            <h5 onClick={() => editCourseHandler(data._id)} className="ftwt__r">
              Edit Course
            </h5>
          )}
        </div> */}
      <Menu
        id="admincard-option-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={() => editBookHandler(data._id)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            {isBook && (
              <h5 onClick={() => editBookHandler(data._id)} className="ftwt__r">
                Edit Book
              </h5>
            )}
            {isCourse && (
              <h5
                onClick={() => editCourseHandler(data._id)}
                className="ftwt__r">
                Edit Course
              </h5>
            )}
          </ListItemText>
        </MenuItem>
        <MenuItem sx={{ mt: 1 }} onClick={() => editCourseHandler(data._id)}>
          <ListItemIcon>
            <DeleteIcon style={{ width: 18, height: 18 }} />
          </ListItemIcon>
          <ListItemText>
            <h5 className="ftwt__r">Delete</h5>
          </ListItemText>
        </MenuItem>
      </Menu>
      {/* <div className="admincard__edit">
        {isBook && (
          <h5 onClick={() => editBookHandler(data._id)} className="ftwt__r">
            Edit Book
          </h5>
        )}
        {isCourse && (
          <h5 onClick={() => editCourseHandler(data._id)} className="ftwt__r">
            Edit Course
          </h5>
        )}
      </div> */}
      <div className="admincard__content">
        <div>
          <div className="admincard__contenthead">
            <h4 className="frwt__b">{data?.name}</h4>
          </div>
          <div className="admincard__contentseprator"></div>
        </div>
        {isBook ? (
          <div className="admincard__contentpoints">
            {true ? (
              <StarBorderOutlinedIcon
                sx={{ color: "var(--pmain)", fontSize: "14px" }}
              />
            ) : (
              <StarOutlinedIcon
                sx={{ color: "var(--pmain)", fontSize: "20px" }}
              />
            )}
            <p
              style={{
                fontSize: "12px",
              }}>
              {data?.points} Points
            </p>
          </div>
        ) : null}
        <p
          className="frwt__r truncate-2"
          style={{
            fontSize: "12px",
          }}>
          {data?.description}
        </p>
        {isCourse ? (
          <div className="admincard__contentpoints">
            <AccessTime sx={{ color: "var(--pmain)", fontSize: "14px" }} />
            <p
              style={{
                fontSize: "12px",
              }}>
              {getFormattedTimeInHoursAndMinuteByMinutes(data?.timeToComplete)}
            </p>
          </div>
        ) : null}
        {isBook && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              position: "absolute",
              bottom: "10px",
              right: "10px",
              gap: "2px",
              fontSize: "12px",
            }}>
            <label>{data?.isPublished ? "Unpublish" : "Publish"}</label>
            <Toggle
              isOn={data?.isPublished}
              id={data?._id}
              handleToggle={() => handleConfirmModal(data?.isPublished)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
