import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, FormHelperText, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './EditBooks.css';
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {
  useCreateLevelMutation,
  useDeleteLevelMutation,
  useGetLevelTasksQuery,
  useUpdateLevelMutation,
} from '../../../../redux-toolkit/features/books/bookApiSlice';
import { AddTask } from './AddTask';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAlert } from '../../../../hooks/useAlert';
import { DeleteModal } from '../../../UI/Delete Modal/DeleteModal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteInActiveSvg from '../../../../assets/delete_icon.svg';
import { ReactComponent as EditSvg } from '../../../../assets/Icons/edit (1).svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const ITEM_HEIGHT = 48;

export default function LongMenu({ onEditClick, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        marginLeft: 'auto',
      }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      // onMouseLeave={() => handleClose()}
      >
        <MoreVertIcon
          fontSize="small"
          sx={{
            color: 'var(--pblue)',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        />
      </IconButton>
      <Menu
        anchorPosition={'left'}
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: '10ch',
          },
        }}>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'var(--slightpurple)',
            },
            paddingTop: '5px', // Remove all padding
          }}
          onClick={(e) => {
            onEditClick && onEditClick();
            handleClose(e);
          }}>
          <EditSvg
            className="edit_svg_level"
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              fontWeight: 100,
              marginRight: '10px',
              strokeWidth: '0.5px',
            }}
          />
          Edit
        </MenuItem>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'var(--slightpurple)',
            },
            paddingBottom: '5px', // Remove all padding
          }}
          onClick={(e) => {
            onDeleteClick && onDeleteClick();
            handleClose(e);
          }}>
          <img
            src={DeleteInActiveSvg}
            className="Sirv image-main"
            height="25px"
            style={{
              marginRight: '10px',
            }}
            alt=""
          />{' '}
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export const AddLevels = ({
  levels,
  index: levelIndex,
  levelNo,
  bookData,
  isView
}) => {
  // States
  const ref = React.useRef(null);
  const [drawer, setDrawer] = React.useState(0);
  const [taskDrawer, setTaskDrawer] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addTask, setAddTask] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState({})
  const [selectedTask, setSelectedTask] = useState({})
  const [isAdd, setIsAdd] = useState(false)
  // Custom Hooks
  const { displayAlert } = useAlert();

  useEffect(() => {
    setSelectedLevel(levels?.data?.levels?.length > 0 ? { ...levels?.data?.levels[0], index: 0 } : {})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedLevel])
  // RTKQuery Hooks
  const { data: tasks, refetch } = useGetLevelTasksQuery(selectedLevel?._id, {
    skip: isAdd||levels?.data?.levels.length==0,
  });
  const [createLevel] = useCreateLevelMutation();
  const [updateLevel] = useUpdateLevelMutation();
  const [deleteLevel] = useDeleteLevelMutation();
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailError, setThumbnailError] = useState('');

  const thumbnailHandler = (event) => {
    const file = event.target.files[0];
    if (file.size > 1024 * 1000) {
      return displayAlert({
        open: true,
        message: 'Image must be less than 4MB  in size',
        type: 'error',
        timeout: '2000',
      });
    }
    setThumbnail(file);
    setThumbnailError('');
  };

  // Handlers
  const deleteLevelHandler = (id) => {
    const levelId = id;
    deleteLevel(levelId)
      .unwrap()
      .then(() => {
        setSelectedLevel(levels?.data?.levels?.length > 1 ? { ...levels?.data?.levels[0], index: 0 } : {})
        displayAlert({
          open: true,
          message: 'Successfully Deleted Level',
          type: 'success',
          timeout: '2000',
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: 'Error in Deleting Level',
          type: 'error',
          timeout: '2000',
        });
      });
  };

  // Formik validation and formik hook
  const validationSchema = yup.object({
    name: yup.string('Enter Level name').required('Level name is required'),
    description: yup
      .string('Enter Level description')
      .required('Description is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (Object.values(selectedLevel).length == 0) {
        const level = new FormData();
        level.append('name', values.name);
        level.append('description', values.description);
        level.append('coverImage', thumbnail);
        const currentIndex = levels?.data?.levels.length
        createLevel({
          bookId: bookData._id,
          level,
        })
          .unwrap()
          .then((res) => {
            setIsAdd(false)
            setSelectedLevel({...res.data.level,index:currentIndex})
            // formik.handleReset();
          })
          .catch((err) => {
            console.log(err);
          })
          .catch((err) => { });
      } else {
        const updateLevelBody = new FormData();
        updateLevelBody.append('name', values.name);
        updateLevelBody.append('description', values.description);
        updateLevelBody.append('coverImage', thumbnail);

        updateLevel({
          levelId: selectedLevel._id,
          updateLevelBody,
        })
          .unwrap()
          .then(() => {
            displayAlert({
              open: true,
              message: 'Successfully Updated Level',
              type: 'success',
              timeout: '2000',
            });
            setIsAdd(false)
          })
          .catch((err) => {
            displayAlert({
              open: true,
              message: 'Error in Updating Level',
              type: 'error',
              timeout: '2000',
            });
          });
      }
    },
  });


  // React.useEffect(() => {
  //   if (!isAdd) {
  //     setThumbnail(selectedLevel?.coverImage);
  //   }
  // }, []);

  useOutsideClick(ref, () => {
    setDrawer(0);
    setTaskDrawer(false)
  });


  return (
    <>
      {isAdd ? (
        <div
          className='editbooks__accordiansActive'
          key={levelNo}>
          <h5>{selectedLevel ? selectedLevel.name : "Add level " + levels?.data?.levels.length + 1}</h5>

          <div className="editbooks__accordiansForm">
            <h4 className="ftwt__r">Name</h4>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                // marginTop: '5px',
              }}
              id="level-name-field"
              name="name"
              fullWidth
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <h4 className="ftwt__r">Display Image</h4>
            <div
              className="dropzone-wrapper"
              style={{
                border: thumbnailError ? '1px dashed #d32f2f' : '',
                margin: '0px'
              }}>
              {thumbnail ? (
                <div className="dropzone-image">
                  <img
                    height={'100%'}
                    src={Object.keys(selectedLevel).length==0?URL.createObjectURL(thumbnail):thumbnail}
                    alt="Thumbnail Preview"
                  />
                  <CloseIcon
                    onClick={() => setThumbnail()}
                    sx={{
                      fontSize: '18px',
                      cursor: 'pointer',
                      color: 'var(--grey400)',
                      '&:hover': {
                        color: 'var(--error)',
                      },
                    }}
                  />
                </div>
              ) : (
                <div className="dropzone-desc">
                  <UploadFileOutlinedIcon sx={{ fontSize: '70px' }} />
                  <p
                    style={{
                      fontSize: '14px',
                      // color: isLarge ? 'var(--error)' : '',
                    }}>
                    Choose an image file{' '}
                    <span style={{ fontSize: '11px' }}>or drag it here.</span>
                    <br />
                    <span style={{ fontSize: '11px' }}>
                      Maximum file size 1MB
                    </span>
                  </p>
                </div>
              )}

              {!thumbnail && (
                <input
                  name="thumbnail"
                  onChange={thumbnailHandler}
                  type="file"
                  accept="image/*"
                  className="dropzone"
                />
              )}
            </div>
            {thumbnailError ? (
              <FormHelperText
                style={{
                  marginTop: '-5px',
                  color: '#d32f2f',
                }}
                error>
                {thumbnailError}
              </FormHelperText>
            ) : null}
            <h4 className="ftwt__r">Description</h4>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                marginTop: '5px',
              }}
              required
              id="level-description-field"
              variant="outlined"
              name="description"
              fullWidth
              rows={2}
              multiline
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description &&
                Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <div>
              <div className="editbooks__levelAction">
                {/* <img src={DeleteInActiveSvg} height="30px" alt="" /> */}
                <Button
                  variant="outlined"
                  onClick={() => { setIsAdd(false) }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (!thumbnail) {
                      setThumbnailError('Thumbnail is required');
                      return;
                    }
                    formik.handleSubmit();
                  }}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="editbooks__content">
          <div className="editbooks__contentLeft" style={{ justifyContent: levels?.data?.levels.length > 0 ? "flex-start" : "center" }}>
            {levels &&
              levels?.data?.levels.map((level, index) => {
                return (
                  <div className='editbooks__levelContainer' onClick={() => {
                    setAddTask('')
                    setSelectedLevel({ ...level, index })
                  }}>
                    <div className='editbooks__level' style={{ backgroundColor: selectedLevel._id === level._id ? "var(--slightpurple)" : "transparent" }}>
                      <h4 className="ftwt__r" style={{ color: "var(--pblue)", paddingLeft: "10px" }}>{level.name}</h4>
                      {!isView &&<MoreVertIcon
                        onClick={() => setDrawer(level._id)}
                        sx={{
                          color: "var(--pblue)",
                          fontSize: "20px",
                          padding: "2px",
                          borderRadius: "5px",
                        }}
                      />}
                    </div>
                    {drawer === level._id && (
                      <div ref={ref} className="courseDrawer">
                        <p onClick={() => {
                          setDrawer(0)
                          setThumbnail(selectedLevel?.coverImage);
                          formik.setValues({ name: selectedLevel?.name, description: selectedLevel?.description })
                          setIsAdd(true)
                        }}>Edit</p>
                        <div className="seprator"></div>
                        <p onClick={()=>{
                          deleteLevelHandler(level._id)
                          setDrawer(0)
                          }}> Delete</p>
                      </div>
                    )}
                  </div>
                );
              })}
            {levels?.data?.levels?.length > 0 && <div className="seprator" />}
            <Button
            disabled={isView}
              onClick={() => {
                setSelectedLevel({})
                setThumbnail("")
                formik.resetForm()
                setAddTask("")
                setIsAdd(true)
              }}
              variant="contained">
              + Add Level
            </Button>
          </div>
          <div className="editbooks__contentRight" style={{ flexDirection: addTask == '' && (tasks?.data?.tasks?.length == 0||tasks==undefined) ? "row" : "column", justifyContent: addTask==''&&(tasks?.data?.tasks?.length == 0||tasks==undefined) ? "center" : "flex-start", alignItems: addTask==''&&(tasks?.data?.tasks?.length == 0||tasks==undefined) ? "center" : "flex-start" }}>
            {tasks?.data?.tasks?.length > 0 && addTask == "" && <h5>Level {(selectedLevel.index + 1) + " - " + selectedLevel.name}</h5>}
            {
              addTask != ''
                ? (<AddTask
                  isAdd={true}
                  taskData={selectedTask}
                  level={selectedLevel}
                  isCourse={addTask == 'course'||selectedTask?.course}
                  setAddTask={setAddTask}
                  isView={isView}
                />) : tasks?.data?.tasks?.length > 0 ?
                  tasks?.data?.tasks.map((task, index) => (
                    <div className="editbooks__task">
                      <div className="submodules__type">
                        <h3 className="ftwt__r" style={{ color: "var(--pblue)", paddingLeft: "10px" }}>{index + 1}.   Task</h3>
                        <EditOutlinedIcon
                          sx={{
                            fontSize: '17px',
                            color: "var(--pblue)",
                            ":hover": { cursor: "pointer" }
                          }}
                          onClick={() => {
                            setAddTask('task')
                            setSelectedTask({ ...task, index })
                            // if (submodule?.type == "Quiz") setShowQuiz(true)
                            // else setShowAddTopic(true)
                            // // submodule.index = index
                            // setSelectedSubModule({ ...submodule, index })
                          }}
                        />
                      </div>
                      <div className="seprator"></div>
                      <div className="submodules__title">
                        <h6 className="ftwt__r">{task.description}</h6>
                      </div>
                    </div>
                  )) : <div style={{ gap: "20px", display: "flex" }}>
                    <Button
                    disabled={levels?.data?.levels?.length==0}
                      onClick={() => {
                        setSelectedTask({})
                        setAddTask('task')
                      }}
                      variant="outlined">
                      + Add Task
                    </Button>
                    <Button
                    disabled={levels?.data?.levels?.length==0}
                      onClick={() => {
                        setSelectedTask({})
                        setAddTask('course')
                      }}
                      variant="outlined">
                      + Add Course
                    </Button>
                  </div>}
            <div className='editbooks__taskDrawer'>
              {taskDrawer && (
                <div className="task__drawer"ref={ref}>
                  <p onClick={() => {
                    setTaskDrawer(false)
                    setSelectedTask({})
                    setAddTask('task')
                  }}>Add Topic</p>
                  <div className="seprator"></div>
                  <p onClick={() => {
                    setTaskDrawer(false)
                    setSelectedTask({})
                    setAddTask('course')
                  }}> Add Course</p>
                </div>
              )}
              {addTask == '' && tasks?.data?.tasks.length > 0 && !isView && <Button
                onClick={() => { setTaskDrawer(true) }}
                variant="contained"
                sx={{ minWidth: '12px', height: "40px", alignSelf: "flex-end" }}
              >
                <span style={{ fontSize: "25px", color: "white" }}>+</span>
              </Button>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
