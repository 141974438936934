import { apiSlice } from "../../api/apiSlice";

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserNotifications: builder.query({
      query: (type = "all") => ({
        url: `/api/notifications?type=${type}`,
        method: "GET",
      }),
    }),
    getUserNotificationsCount: builder.query({
      query: () => ({
        url: `/api/notifications/unseen-count`,
        method: "GET",
      }),
    }),
    markAllNotificationRead: builder.mutation({
      query: () => ({
        url: `/api/notifications/mark-seen`,
        method: "PATCH",
      }),
    }),
    markSingleNotificationRead: builder.mutation({
      query: (notificationId) => ({
        url: `/api/notifications/mark-one-seen/${notificationId}`,
        method: "PATCH",
      }),
    }),
    getUserNotificationPreferences: builder.query({
      query: () => ({
        url: `/api/user/notification/preferences`,
        method: "GET",
      }),
    }),
    updateUserNotificationPreferences: builder.mutation({
      query: (preferenceId) => ({
        url: `/api/user/notification/preferences`,
        method: "PATCH",
        body: {
          preferenceId,
        },
      }),
    }),
    sendCongratulationsNotification: builder.mutation({
      query: (userId) => ({
        url: `/api/notifications/congratulate/${userId}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUserNotificationsQuery,
  useGetUserNotificationsCountQuery,
  useMarkAllNotificationReadMutation,
  useMarkSingleNotificationReadMutation,
  useGetUserNotificationPreferencesQuery,
  useUpdateUserNotificationPreferencesMutation,
  useSendCongratulationsNotificationMutation,
} = notificationsApiSlice;
