import { AddCircleOutline, ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import rightArrow from "../../../assets/Icons/arrow.svg";
import {
  useGetCategoriesQuery,
  useGetProductsQuery,
} from "../../../redux-toolkit/features/rewards/rewardsApiSlice";
import { Search } from "../../UI/Search/Search";
import "./Rewards.css";
import AddCategory from "./components/AddCategory";
import AddProduct from "./components/AddProduct";
import RewardsCard from "./components/RewardsCard";
import RewardsCategoryBtn from "./components/RewardsCategoryBtn";
import { debounce } from "../../../utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Rewards = () => {
  const [openManageSelect, setOpenManageSelect] = useState(false);
  const [openCatForm, setOpenCatForm] = useState(false);
  const [openProductForm, setOpenProductForm] = useState(false);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [productEditData, setProductEditData] = useState({
    _id: null,
    name: "",
    category: "",
    points: null,
  });
  const [query, setQuery] = useState("");

  const {
    data: products,
    refetch,
    isLoading,
  } = useGetProductsQuery(
    { catId: selectedCategory, query },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: categories, refetch: refetchCats } = useGetCategoriesQuery(
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const setDebouncedSearchQuery = useCallback(
    debounce((value) => {
      setQuery(value.trim());
    }, 500),
    []
  );

  return (
    <div className="container">
      <AddCategory
        open={openCatForm}
        handleClose={() => {
          setOpenCatForm(false);
          refetchCats();
        }}
      />
      <AddProduct
        category={selectedCategory}
        open={openProductForm}
        editData={productEditData}
        handleClose={() => {
          setOpenProductForm(false);
          refetch();
          setProductEditData({
            _id: null,
            name: "",
            category: "",
            points: null,
          });
        }}
      />
      <div className="reportsDetailed__head" style={{ marginTop: "50px" }}>
        <Search
          placehold={"Search..."}
          setSearchVal={(val) => setDebouncedSearchQuery(val)}
        />
        {/* <div className="reportsDetailed__headright">
          <div
            className={
              (openManageSelect ? "open_rm" : "") +
              " rewards__manage_rewards_dd_item"
            }
            style={{
              position: "relative",
            }}
          >
            <div
              onClick={() => {
                setOpenManageSelect(!openManageSelect);
              }}
              className="employee__manage_employee_dd_label"
            >
              <p value="">Manage Rewards</p>
              <div>
                <img src={rightArrow} style={{ marginBottom: "2px" }} alt="" />
              </div>
            </div>
            {openManageSelect ? (
              <div className="employee__select_menu" style={{ left: "-0.5px" }}>
                <h1 className="select_divider"></h1>
                <p
                  onClick={() => {
                    setOpenManageSelect(false);
                    setOpenCatForm(true);
                  }}
                >
                  Add category
                </p>

                <p
                  onClick={() => {
                    setOpenManageSelect(false);
                    setOpenProductForm(true);
                  }}
                >
                  Add product
                </p>
                <p
                  onClick={() => {
                    setOpenManageSelect(false);
                    navigate("/super-admin/rewards/redeem-status");
                  }}
                >
                  Redeem status
                </p>
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
      <div className={"reports__select"}>
        <div className="reports__selectcontent"></div>
      </div>

      {query === "" && selectedCategory === "all" ? (
        <div className="rewards_cat_container">
          <p>All Categories</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className="rewards_cat_header">
              <p>Category name</p>
              <p
                style={{
                  paddingRight: "85px",
                }}
              >
                Action
              </p>
            </div>
            {categories?.data?.categories?.map((cat) => {
              return (
                <RewardsCategoryBtn
                  onClick={() => {
                    setSelectedCategory(cat._id);
                  }}
                  onDelete={(id) => {
                    refetchCats();
                  }}
                  onEdit={() => {
                    refetchCats();
                  }}
                  isActive={selectedCategory === cat?._id}
                  category={cat}
                >
                  {cat?.name}
                </RewardsCategoryBtn>
              );
            })}
            <Button
              variant="contained"
              style={{
                marginLeft: "auto",
                marginTop: "20px",
              }}
              onClick={() => {
                setOpenCatForm(true);
              }}
              startIcon={
                <AddCircleOutline
                  sx={{ strokeWidth: 1 }}
                  style={{ color: "white" }}
                />
              }
            >
              Add category
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <p
            style={{
              margin: "2rem 0",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <span
              style={{ cursor: "pointer", fontWeight: "500", color: "#3361cf" }}
              onClick={() => setSelectedCategory("all")}
            >
              All Categories
            </span>
            <ChevronRight fontSize="small" />
            <span>
              {
                categories?.data?.categories?.find(
                  (cat) => cat._id === selectedCategory
                )?.name
              }
            </span>
          </p>
          <div className="rewards_cards_container">
            {isLoading
              ? null
              : products?.data?.products
                  ?.filter((p) =>
                    selectedCategory !== "all"
                      ? p?.category === selectedCategory
                      : true
                  )
                  ?.map((product) => (
                    <RewardsCard
                      onDelete={() => {
                        refetch();
                      }}
                      onEdit={(prod) => {
                        setProductEditData(prod);
                        setOpenProductForm(true);
                      }}
                      product={product}
                    />
                  ))}
            <RewardsCard
              isAdd
              onClick={() => {
                setOpenProductForm(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Rewards;
