import React, { useRef, useEffect } from 'react';
import { Books } from '../../Books/Books';
import { Navbar } from '../../Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { useGetInitialAppliedThemeQuery } from '../../../redux-toolkit/features/theme/themeApiSlice';
import { useGetCompletedAlphaScreensQuery } from '../../../redux-toolkit/features/auth/authApiSlice';
import ProgressBar from '../../UI/ProgressBar';
import { useDispatch } from 'react-redux';
import { roleToggler } from '../../../redux-toolkit/features/access/accessSlice';

const EmployeeLayout = () => {
  const { data, isLoading, refetch, isError } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: alphaScreens, refetch: alphaRefetch } =
  useGetCompletedAlphaScreensQuery({
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    refetch();
    alphaRefetch()
    dispatch(roleToggler('Employee'));
  }, []);

  if (isLoading) {
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <ProgressBar />;
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundImage: `url(${data?.data?.bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
        {data?.data?.leftIcon&&<div className='bookLayout__left'><img src={data?.data?.leftIcon} alt="" /></div>}
        {data?.data?.rightIcon&&<div className='bookLayout__right'><img src={data?.data?.rightIcon} alt="" /></div>}
      <Navbar />
      <Outlet />
    </div>
  );
};

export default EmployeeLayout;
