import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useState } from 'react';
import './ViewBook.css'
import {
    useGetLevelTasksQuery,
} from '../../../../redux-toolkit/features/books/bookApiSlice';
import { Star } from "@mui/icons-material";

export const ViewLevel = ({ levelData, index: levelIndex }) => {

    const [open, setOpen] = useState(false);
    const { data: tasks } = useGetLevelTasksQuery(levelData?._id);


    return (
        <div className='viewbook__level'>
            <div
                onClick={() => setOpen(!open)}
                style={{
                    borderBottomRightRadius: open ? '0px' : '10px',
                    borderBottomLeftRadius: open ? '0px' : '10px',
                }}
                className="viewbook__levelHead">
                <h5>
                    Level {levelIndex + 1} {'>'} {levelData.name}
                </h5>
                {!open ? (
                    <ArrowForwardIosIcon
                        fontSize="small"
                        onClick={() => setOpen(true)}
                        sx={{
                            transform: 'rotate(90deg)',
                            color: 'var(--pblue)',
                            fontSize: '18px',
                            cursor: 'pointer',
                            transition: 'transform 0.6s',
                        }}
                    />
                ) : (
                    <ArrowForwardIosIcon
                        fontSize="small"
                        onClick={() => setOpen(false)}
                        sx={{
                            transform: 'rotate(-90deg)',
                            color: 'var(--pblue)',
                            fontSize: '18px',
                            cursor: 'pointer',
                            transition: 'transform 0.6s',
                        }}
                    />
                )}

            </div>
            {open && (
                <div className="viewbook__levelExpanded">
                    <div className='viewbook__topLevel'>
                        <div className="viewbook__right">
                            <h6>
                                Display Image
                            </h6>
                            {levelData?.coverImage && (
                                <img
                                    src={
                                        !levelData.coverImage?.type
                                            ? levelData.coverImage
                                            : URL.createObjectURL(levelData.coverImage)
                                    }
                                    alt=""
                                />
                            )}
                        </div>
                        <div className="viewbooks__left">
                            <div>
                                <h4 className="ftwt__b"> Level Name </h4>
                                <h6>{levelData.name}</h6>
                            </div>
                            <div>
                                <h4 className="ftwt__b">Description</h4>
                                <h6>{levelData.description}</h6>
                            </div>
                        </div>
                    </div>

                    <div className="seprator"></div>
                    <div
                        className="viewbook__tasks">
                        {tasks &&
                            tasks.data.tasks.map((task, index) => {
                                return (
                                    <div className='viewbook__task'>
                                        <h4 className="ftwt__b" style={{color: 'var(--pblue)', marginBottom:"15px"}}>Task {index + 1}</h4>
                                        <h4 className='ftwt__b'>Question</h4>
                                        <h5 style={{color:"#747474"}}>{task.description}</h5>
                                        <div className="viewbook__point_badge" style={{backgroundColor:"var(--slightpurple)"}}>
                                            <Star
                                                style={{
                                                    color: 'var(--pblue)',
                                                    fontSize: '18px',
                                                }}
                                            />
                                            <h5 style={{color: 'var(--pblue)'}}> {task.points} Points</h5>
                                        </div>
                                        <br />
                                        <div className="seprator"></div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    )
}