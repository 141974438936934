import "./Quiz.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useRef, useState } from "react";
import { ReactComponent as DeleteOutlineIcon } from "../../../../../assets/delete_icon.svg"
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { MenuItem } from "@mui/material";
import { DeleteModal } from "../../../../UI/Delete Modal/DeleteModal";
import { Button, TextField } from '@mui/material';
import { useAlert } from "../../../../../hooks/useAlert";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StarOutline } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    useUpdateTopicMutation,
    useCreateTopicMutation,
    useGetSubmoduleByIdQuery,
    useDeleteTopicMutation
} from '../../../../../redux-toolkit/features/courses/coursesApiSlice';

export const Quiz = ({ id, setShowQuestion, question, mod, refetchHandler, setShowQuiz, isView }) => {

    const [questionTitle, setQuestionTitle] = useState("");
    const [options, setOptions] = useState([]);
    const { displayAlert } = useAlert();
    const [updateTopic] = useUpdateTopicMutation();
    const [createQuiz] = useCreateTopicMutation();
    const [deleteQuiz] = useDeleteTopicMutation();
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const { data, isLoading, refetch, isError } = useGetSubmoduleByIdQuery(id, {
        refetchOnMountOrArgChange: true,
    });

    var submod = data?.data?.submodule


    React.useEffect(() => {
        if (!submod) {
            if (!isLoading) setShowQuestion({ type: "Single Choice", correctAnswer: [], options: [] })
            else setShowQuestion({})
        }
    }, [isLoading])

    React.useEffect(() => {
        setQuestionTitle(question.question ?? questionTitle)
        // if (Object.keys(question).length) {
        //     var updatedOptions = question?.options.slice()
        //     if (!updatedOptions.includes("")) updatedOptions.push("")
        //     setOptions(updatedOptions)
        // }
    }, [question])

    const updateQuizHandler = async (isDelete = false) => {
        const topicId = submod?._id;
        const index = question.index
        var quiz = submod?.quiz.slice()
        var errMessage = ""
        var isError = false
        let finalData;
        const updatedOptions = options.slice()
        if (updatedOptions.includes("")) updatedOptions.splice(updatedOptions.indexOf(""), 1)
        if (isDelete) {
            quiz.splice(index, 1)
            finalData = { topic: { ...submod, quiz }, topicId };
        }
        else {
            if (updatedOptions.length < 2) { isError = true; errMessage = "Please add atleast 2 options" }
            else if (question.correctAnswer.length == 0) { isError = true; errMessage = "Please select correct answers" }
            else if (!questionTitle) { isError = true; errMessage = "Please enter the question" }
            else if (!question.points) { isError = true; errMessage = "Please enter the points" }
            else { isError = false }
            if (isError) {
                return displayAlert({
                    open: true,
                    message: errMessage,
                    type: "error",
                    timeout: "2000",
                })
            } else {
                delete question.index
                quiz[index] = { ...question, options: updatedOptions, question: questionTitle }
                finalData = { topic: { ...submod, quiz }, topicId };
            }
        }
        await updateTopic(finalData).then(async (res) => {
            if (res?.error?.status == 400) {
                throw res.error
            }
            await refetch()
            setShowQuestion({})
            //Refresh submod data
            displayAlert({
                open: true,
                message: isDelete ? "Successfully Deleted Question" : 'Successfully Updated Question',
                type: 'success',
                timeout: '2000',
            });
        }).catch(err => {
            displayAlert({
                open: true,
                message: err?.data?.message ?? 'Unable to update question, please try again',
                type: 'error',
                timeout: '2000',
            });
        })
    }

    const addQuizHandler = async () => {
        const actualOptions = options.slice()
        if (actualOptions.includes("")) actualOptions.splice(actualOptions.indexOf(""), 1)
        if (!questionTitle || !actualOptions || !question?.points) {
            return displayAlert({
                open: true,
                message: 'Please add all the required fields',
                type: 'error',
                timeout: '2000',
            })
        }
        if(question.correctAnswer?.length == 0 ){
            return displayAlert({
                open: true,
                message: 'Please select the correct answers',
                type: 'error',
                timeout: '2000',
            })
        }
        var newSubMod = { name: "It's Quiz Time", type: 'Quiz', quiz: [{ ...question, question: questionTitle, options: actualOptions }] }
        await createQuiz({ courseId: mod.course, moduleId: mod._id, topic: newSubMod }).then(async (res) => {
            if (res?.error?.status == 400) {
                throw res.error
            }
            id = res?.data?.data._id
            //Refresh submod data
            displayAlert({
                open: true,
                message: 'Successfully Created Question',
                type: 'success',
                timeout: '2000',
            });
        }).catch(err => {
            displayAlert({
                open: true,
                message: err?.data?.message ?? 'Unable to create question, please try again',
                type: 'error',
                timeout: '2000',
            });
        })
        setShowQuestion({})
        refetchHandler()
    }

    const deleteQuizHandler = async () => {
        const quizId = submod?._id;
        await deleteQuiz(quizId);
        setShowQuestion({})
        await refetchHandler()
        setShowQuiz(false)
        displayAlert({
            open: true,
            message: 'Successfully Deleted Quiz',
            type: 'error',
            timeout: '2000',
        });
    };

    const handleOptionSelection = (option) => {
        if (isView) return;
        if (question?.type == 'Single Choice' && option) {
            if (option != "") setShowQuestion({ ...question, correctAnswer: [option] })
        } else if (question?.type == 'Multiple Choice' && option) {
            const correctAnswer = question.correctAnswer.slice()
            if (correctAnswer?.includes(option)) correctAnswer.splice(correctAnswer.indexOf(option), 1)
            else if (correctAnswer) correctAnswer.push(option)
            setShowQuestion({ ...question, correctAnswer })
        }
    }

    return (
        <div className="quiz">
            <DeleteModal
                label={data?.name}
                deleteHandler={() => deleteQuizHandler()}
                setDeleteOpen={setDeleteOpen}
                deleteOpen={deleteOpen}
            />
            {Object.keys(question).length > 0 && <h4>Question <span style={{ color: "red" }}>*</span></h4>}
            {Object.keys(question).length > 0 ? <div className="quiz__qcontainer">
                <div className="quiz__qEdit">
                    <div className="quiz_qEditTitle">
                        <TextField
                            disabled={isView}
                            autoFocus={question?.question ? false : true}
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                marginTop: '5px',
                                color: "red",
                            }}
                            placeholder="Write Question here..."
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    fontSize: "15px",
                                }
                            }}
                            name="q"
                            value={questionTitle}
                            onChange={(e) => { setQuestionTitle(e.target.value) }}
                            fullWidth
                        />
                        <FormControl sx={{ width: '35%', marginTop: '5px' }}>
                            <Select
                                disabled={isView}
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                defaultValue={question.type}
                            >
                                <MenuItem
                                    sx={{ fontSize: '14px' }}
                                    value="Single Choice"
                                    onClick={() => {
                                        if (question.type != 'Single Choice')
                                            setShowQuestion({ ...question, type: "Single Choice", correctAnswer: [] })
                                    }}
                                >
                                    Single Choice
                                </MenuItem>
                                <MenuItem
                                    sx={{ fontSize: '14px' }}
                                    value="Multiple Choice"
                                    onClick={() => { if (question.type != 'Multiple Choice') setShowQuestion({ ...question, type: "Multiple Choice", correctAnswer: [] }) }}
                                >
                                    Multiple Choice
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="seprator" />
                    <div className="quiz_options">
                        {
                            (options?.length > 0 ? options : ['']).map((option, index) => {
                                return (
                                    <div className="quiz__choice">
                                        {question?.type == 'Single Choice'
                                            ? question?.correctAnswer.includes(option)
                                                ? <CheckCircleIcon style={{ color: 'var(--pblue)', width: "18px" }} />
                                                : <RadioButtonUncheckedIcon
                                                    style={{ color: option != "" ? "#5C5C5C" : "#c3c3c3", width: "18px" }}
                                                    onClick={() => { handleOptionSelection(option) }}
                                                />
                                            : question?.correctAnswer.includes(option)
                                                ? <CheckBoxIcon
                                                    style={{ color: 'var(--pblue)', width: "18px" }}
                                                    onClick={() => { handleOptionSelection(option) }}
                                                />
                                                : <CheckBoxOutlineBlankIcon
                                                    style={{ color: option != "" ? "#5C5C5C" : "#c3c3c3", width: "18px" }}
                                                    onClick={() => { handleOptionSelection(option) }}
                                                />}
                                        <TextField
                                            disabled={isView}
                                            id={index}
                                            autoFocus={(question?.question || questionTitle) ? true : false}
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                sx: {
                                                    fontSize: "13px",
                                                }
                                            }}
                                            placeholder="Option"
                                            name="q"
                                            value={option}
                                            onChange={(e) => {
                                                var updatedOptions = options.slice()
                                                updatedOptions[index] = e.target.value
                                                setOptions(updatedOptions)
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.code == 'Enter') {
                                                    var updatedOptions = options.slice()
                                                    if (!updatedOptions.includes("")) updatedOptions.push("")
                                                    setOptions(updatedOptions)
                                                    const textField = document.getElementById(index)
                                                    textField.blur()
                                                }
                                            }}
                                            fullWidth
                                        />
                                        {option != "" && (<div className="quiz__removeoption" onClick={() => {
                                            var updatedOptions = options.slice()
                                            updatedOptions.splice(index, 1)
                                            // updatedOptions.push("")
                                            setOptions(updatedOptions)
                                            //Remove the answer from correct answers if present
                                            if (question?.correctAnswer.includes(option)) {
                                                const correctAnswer = question.correctAnswer.slice()
                                                correctAnswer.splice(correctAnswer.indexOf(option), 1)
                                                setShowQuestion({ ...question, correctAnswer })
                                            }
                                        }}><CloseIcon style={{ width: "18px" }} /></div>)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="seprator" />
                    <div className="quiz_actions">
                        <div className="quiz_actionsLeft">
                            <StarOutline
                                style={{
                                    color: '#ee7233',
                                    fontSize: '18px',
                                }}
                            />
                            <h5>Points</h5>
                            <TextField
                                disabled={isView}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    marginTop: '5px',
                                    marginLeft: "10px",
                                    color: "red",
                                    width: "120px"
                                }}
                                variant="outlined"
                                InputProps={{
                                    sx: {
                                        fontSize: "15px",
                                    }
                                }}
                                name="q"
                                value={question.points}
                                onChange={(e) => { setShowQuestion({ ...question, points: e.target.value }) }}
                            />
                        </div>
                        <div className="quiz_actionsRight">
                            {!isView && <DeleteOutlineIcon
                                onClick={() => {
                                    if (submod?.quiz.length == 1) setDeleteOpen(true)
                                    else updateQuizHandler(true)
                                }}
                                style={{
                                    cursor: "pointer",
                                    color: 'var(--grey400)',
                                    '&:hover': {
                                        color: 'var(--error)',
                                    }, fontSize: '25px',
                                }} />}
                            <div className="vertical__seprator"></div>
                            {submod ? <Button
                                disabled={isView}
                                variant="contained"
                                onClick={() => updateQuizHandler()}
                            >
                                Update Question
                            </Button> : <Button
                                disabled={isView}
                                variant="contained"
                                onClick={() => addQuizHandler()}
                            >
                                Add Question
                            </Button>}
                        </div>
                    </div>
                </div>
            </div> :
                submod?.quiz?.map((q, index) => (
                    <div className="quiz__container">
                        <div className="quiz__qcontainer" style={{ width: "90%" }}>
                            <div className="quiz__qTopView">
                                <div className="quiz__qTitle">
                                    <h4 style={{ color: "var(--pblue)" }}>Question - {index + 1}</h4>
                                    <EditOutlinedIcon
                                        sx={{
                                            fontSize: '17px',
                                            color: "var(--pblue)",
                                            ":hover": { cursor: "pointer" }
                                        }}
                                        onClick={() => {
                                            setShowQuestion({ ...q, index })
                                            var optionsToSet = q.options.slice()
                                            optionsToSet.push("")
                                            setOptions(optionsToSet)
                                        }}
                                    />
                                </div>
                                <div className="quiz__q">
                                    <h4>{q.question}</h4>
                                </div>
                            </div>
                        </div>
                        {Object.keys(question).length == 0 && index == submod?.quiz.length - 1 && !isView && <div className="quiz_qoptions" onClick={() => {
                            setShowQuestion({ type: "Single Choice", correctAnswer: [], options: [], index: submod?.quiz.length })
                            setOptions([])
                            setQuestionTitle("")
                        }}>
                            <p style={{ fontSize: "24px", color: "var(--pblue)" }}>+</p>
                        </div>}
                    </div>
                ))
            }
        </div>
    )
}