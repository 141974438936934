import { Button } from "@mui/material";
import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { BreadcrumbAdmin } from "../../UI/BreadcrumbAdmin";
import "./Modulecontent.css";
import { Box } from "@mui/material";
import ProgressBar from "../../UI/ProgressBar";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {
  useMarkCompleteMutation,
  useGetSubmoduleByIdQuery,
  useAttemptQuizMutation
} from "../../../redux-toolkit/features/courses/coursesApiSlice";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { Error404 } from "../../UI/404/Error404";
import { useAlert } from "../../../hooks/useAlert";
import CoursePdf from './CoursePdf';
import starMedal from "../../../assets/star-medal.png";

import { Quiz } from "../../../Components/Mycourses/Course/Quiz";


export const Modulecontent = ({
  content,
  refetch,
  selectedTopic,
  selectedModule,
  handlePrevious,
  handleNext,
  moduleIndex,
  topicIndex,
  topicId,
  isLast,
  toggleCompleted,
}) => {
  const { courseId, levelId, taskId, bookName, bookId, levelName } =
    useParams();
  const [markComplete] = useMarkCompleteMutation();
  const { displayAlert } = useAlert();

  const [quizEnd, setQuizEnd] = useState(false);
  const [resultPreview, setResultPreview] = useState(false);
  const [quizValue, setQuizValue] = React.useState({})
  const [correctAnswers, setCorrectAnswers] = React.useState({})
  const [userResponses, setUserResponses] = React.useState({})
  const [attemptQuiz] = useAttemptQuizMutation();
  var score = 0;


  const { data, isLoading, isFetching, isError } = useGetSubmoduleByIdQuery(
    topicId,
    {
      skip: toggleCompleted === true,
      refetchOnMountOrArgChange: true,
    }
  );

  React.useEffect(()=>{
    if (data?.data?.submoduleCompletion == 'COMPLETE' && data?.data?.submodule?.type == 'Quiz') {
      Object.keys(data?.data?.submodule.quizcompletion[0]?.responses).map(res=>{
        userResponses[res] = data?.data?.submodule.quizcompletion[0]?.responses[res]['response']
      })
      setUserResponses(userResponses)
      data?.data?.submodule.quiz.map(question => {
        correctAnswers[question._id] = question.correctAnswer
      })
      setCorrectAnswers(correctAnswers)
      setResultPreview(true)
    }else{
      setResultPreview(false)
    }
  },[topicId, data])

  if (isLoading || isFetching) {
    return (
      <div
        className=" modcon"
        style={{ height: '97vh' }}>
        <ProgressBar />
      </div>
    );
  }
  if (isError) {
    return (
      <div style={{ height: '97vh', paddingTop: '80px' }}>
        <Error404 />
      </div>
    );
  }
  const submodule = data?.data?.submodule

  const base = [
    {
      _id: 1,
      link: `/quest/books/${bookId}`,
      label: bookName,
    },
    {
      _id: 2,
      link: `/quest/books/${bookId}/levels/${levelId}`,
      label: levelName,
    },
  ];

  const handleQuizSubmission = async () => {
    if(selectedTopic.lockStatus == 'LOCKED'|| selectedTopic.lockStatus == 'COMPLETE'){
      displayAlert({
        open: true,
        message: 'Please complete all the topics before attempting the quiz',
        type: 'error',
        timeout: '2000',
      });
    }
    else if (Object.keys(quizValue).length != submodule.quiz.length) {
      displayAlert({
        open: true,
        message: 'Please attempt all the questions of the quiz',
        type: 'error',
        timeout: '2000',
      });
    }
    else {
      attemptQuiz({ userResponses: quizValue, quizId: submodule._id }).then(async (res) => {
        if (res?.data?.data) {
          let bookDetails;
          bookDetails = {
            bookId: bookId,
            levelId: levelId,
            taskId: taskId,
          };
          await markComplete({
            courseId,
            moduleId: selectedModule._id,
            topicId: selectedTopic._id,
            bookDetails,
          })
          await refetch();
          setQuizEnd(true)
          score = Object.values(data?.data.submodule.quizcompletion[0].responses).reduce((prev,curr)=>{
            return curr.points>0?prev+1:(prev||0)
          },0)
          submodule.quiz.map(question => {
            correctAnswers[question._id] = question.correctAnswer
          })
          setCorrectAnswers(correctAnswers)
          setUserResponses(quizValue)
        }
      })
    }
  }

  const handlePreview = () => {
    setQuizEnd(false)
    setResultPreview(true)
  }

  const markDoneHandler = () => {
    if(selectedTopic.lockStatus == 'INCOMPLETE'){
      let bookDetails;
      // if (isLast) {
      bookDetails = {
        bookId: bookId,
        levelId: levelId,
        taskId: taskId,
      };
      // }
      markComplete({
        courseId,
        moduleId: selectedModule._id,
        topicId: selectedTopic._id,
        bookDetails,
      })
        .unwrap()
        .then(async () => {
          await displayAlert({
            open: true,
            message: 'Successfully Marked as completed ',
            type: 'success',
            timeout: '2000',
          });
          await refetch();
          await handleNext();
        });
    }else {
      displayAlert({
        open: true,
        message: 'Please complete all the topics in the provided order',
        type: 'error',
        timeout: '2000',
      });
    }
  };

  if(quizEnd){
    score = Object.values(data?.data.submodule?.quizcompletion[0]?.responses).reduce((prev,curr)=>{
      return curr.points>0?prev+1:(prev||0)
    },0)
  }else score = 0

  return (
    <div>
      {data ? (
        <div className="modcon">
          <div className="course__breadcrumbs">
            <Link to="/quest">
              <div className="course__breadcrumbshome">
                <HomeRoundedIcon
                  sx={{
                    fontSize: '22px',
                    color: true ? 'var(--pblue)' : 'white',
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                />
              </div>
            </Link>
            <BreadcrumbAdmin
              isQuest
              base={base}
              page={content?.name}
            />
          </div>

          <div className="modcon__top">
            <Box
              sx={{
                border: '2px solid var(--grey300)',
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <h5>
                {moduleIndex + 1}.{topicIndex + 1}
              </h5>
            </Box>
            <h3>{submodule.name}</h3>
          </div>
          <br />
          <div className="seprator"/>
          <div className="modcon__middle">
            {quizEnd ? (
              <div className="modcon__quizend">
                <span style={{ fontFamily: "The Nautigal, cursive", fontStyle: "italic", fontSize: "40px", color: "#EE7233" }}>Congratulations</span>
                <img src={starMedal} style={{ width: "130px" }} alt="" />
                <p>You have completed <span style={{ fontWeight: "700", color: "black" }}>{submodule.name}</span></p>
                <p>Your score is {score}/{submodule?.quiz.length}.</p>
                <div className="seprator">&nbsp;</div>
                <Button
                  style={{ marginTop: '10px' }}
                  onClick={handlePreview}
                  variant="outlined">
                  Result Preview
                </Button>
              </div>) : (
              <div className="modcon__topiccontainer">
                <div className="modcon__desc">
                  {submodule.type != "Quiz" && (<h3 className="ftwt__b">Description:</h3>)}
                  {submodule.type != "Quiz" &&<div>
                    <div
                      className="course_content__desc"
                      dangerouslySetInnerHTML={{
                        __html: submodule.content,
                      }}></div>
                  </div>}
                  {submodule.type == 'Quiz' ? (<div style={{width:"90%"}}>
                    {
                      resultPreview?submodule?.quiz?.map((quiz, index) => {
                        return (
                          <Quiz
                            question={quiz.question}
                            questionId={quiz._id}
                            type={quiz.type}
                            options={quiz.options}
                            index={index}
                            value={quizValue}
                            setValue={setQuizValue}
                            isPreview={resultPreview}
                            userResponses={userResponses}
                            correctAnswers={correctAnswers}
                          />
                        )
                      }):submodule.quiz.map((quiz, index) => {
                        return (
                          <Quiz
                            question={quiz.question}
                            questionId={quiz._id}
                            type={quiz.type}
                            options={quiz.options}
                            index={index}
                            value={quizValue}
                            setValue={setQuizValue}
                            isPreview={resultPreview}
                            userResponses={{}}
                            correctAnswers={{}}

                          />
                        )
                      })
                    }
                  </div>) : <div />}

                  <div className="course_content__resources">
                    {submodule.resources &&
                      submodule.resources.length > 0 &&
                      submodule.resources.map((item) => (
                        <CoursePdf item={item} />
                      ))}
                  </div>
                </div>
                <div className="modcon__bottom">
                  <div className="seprator">&nbsp;</div>

                  <div className="modcon__action">
                    {['INCOMPLETE','LOCKED'].includes(data?.data?.submoduleCompletion)||(!resultPreview&&submodule.type == 'Quiz')
                      ? submodule.type == 'Quiz'
                        ? (<h5 onClick={(handleQuizSubmission)}>Submit</h5>)
                        :(
                          <h5 onClick={markDoneHandler}>Mark As Done</h5>
                        ) : (
                        <h5>Completed</h5>
                      )}
                    <div className="course__actions">
                      <span className="course__prev">
                        <KeyboardArrowLeftOutlinedIcon
                          onClick={handlePrevious}
                          sx={{
                            fontSize: '25px',
                            color: 'var(--pblue)',
                            '&:hover': {
                              color: 'white',
                            },
                          }}
                        />
                      </span>
                      <span className="course__next">
                        <KeyboardArrowRightOutlinedIcon
                          onClick={handleNext}
                          sx={{
                            fontSize: '25px',
                            color: 'var(--pblue)',
                            '&:hover': {
                              color: 'white',
                            },
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div></div>)}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            height: '95vh',
            marginTop: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <h1>No Topic Selected</h1>
        </div>
      )}
    </div>
  );
};

{
  /* <div className="modcon__img">
            {content?.coverImage != null && (
              <img src={content.coverImage} alt="" />
            )}
          </div> */
}