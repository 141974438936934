import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { Checkbox, FormControlLabel, Radio } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const Quiz = ({ question, questionId, options, type, index, value, setValue, isPreview, userResponses, correctAnswers }) => {
  return (
    <div style={{width:"100%", border: "0.5px solid var(--grey300)", borderRadius: "8px", marginBottom: "16px", padding: "20px 20px" }}>
      <h6>Q.{index + 1} &nbsp;{question}</h6>
      <FormControl disabled={isPreview} sx={{ display: "flex", gap: "5px" }}>
        <RadioGroup
          column
          value={value[questionId]}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: "16px",
              marginLeft: "24px",
            },
          }}
          aria-labelledby="demo-column-radio-buttons-group-label"
          name="column-radio-buttons-group"
        >
          {
            options.map(option => {
              const optionStyle = {
                fontSize: '18px',
              }
              if (isPreview) optionStyle['color'] = correctAnswers[questionId]?.includes(option) ? "green" : userResponses[questionId]?.includes(option) ? "red" : "#D9D9D9"
              const correctRadioButton = <CheckIcon style={{ color: "green" }} />
              const selectedWrongRadioButton = <CloseIcon style={{ color: "red" }} />
              return type == 'Single Choice' ? (
                <div style={{ backgroundColor: userResponses[questionId]?.includes(option) ? "#3361CF12" : "transparent", margin: "10px 10px 0px 0px", padding: "0px 18px" }}>
                  <FormControlLabel
                    sx={{"& .MuiFormControlLabel-label": { fontSize: "14px" },}}
                    value={option}
                    control={
                      isPreview 
                      ? correctAnswers[questionId]?.includes(option) 
                        ? <Radio checkedIcon={correctRadioButton} icon={correctRadioButton} />
                        : userResponses[questionId]?.includes(option) 
                          ? <Radio checkedIcon={selectedWrongRadioButton} icon={selectedWrongRadioButton} /> 
                          : <Radio icon={<RadioButtonUncheckedIcon/>}/> 
                      : <Radio checkedIcon={<CheckCircleIcon style={{color:'var(--pblue)'}}/>} />
                    }
                    label={option}
                    onChange={() => {
                      value[questionId] = [option]
                      setValue(value)
                    }}
                  />
                </div>
              ) : (
                <FormControlLabel style={{ backgroundColor: userResponses[questionId]?.includes(option) ? "#3361CF12" : "transparent", margin: "10px 10px 0px 0px", padding: "0px 8px" }}
                  control={
                    <Checkbox
                      icon={isPreview ? correctAnswers[questionId]?.includes(option) ? <CheckIcon /> : userResponses[questionId]?.includes(option) ? <CloseIcon /> : <CheckBoxOutlineBlankIcon /> : <CheckBoxOutlineBlankIcon />}
                      defaultChecked={false}
                      checked={isPreview?false:value[questionId]?.includes(option)}
                      name="Question"
                      onChange={() => {
                        var currentValue = value[questionId]
                        if(currentValue?.includes(option))currentValue.splice(currentValue.indexOf(option),1)
                        else if (currentValue) currentValue.push(option)
                        else currentValue = [option]
                        value[questionId] = currentValue
                        setValue(value)
                      }}
                      sx={{ '& .MuiSvgIcon-root': optionStyle }}
                    />
                  }
                  label={
                    <span
                      className="truncate-1"
                      style={{ fontSize: '13px' }}>
                      {option}
                    </span>
                  }
                />
              )
            })
          }
        </RadioGroup>
      </FormControl>
    </div>
  )
}