import React, { useEffect, useState } from "react";
import { BreadcrumbAdmin } from "../UI/BreadcrumbAdmin";
import "./NewOrganization.css";
import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useAlert } from "../../hooks/useAlert";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import * as yup from "yup";
import countries from "../../assets/json/countries.json";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useAddOwnerMutation,
  useGetOwnersByIdQuery,
  useUpdateOwnersByIdMutation,
} from "../../redux-toolkit/features/owner/ownerApiSlice";
import { useNavigate, useParams } from "react-router-dom";

const steps = [
  "Company Details",
  "Contact Details",

  // , 'Billing Information'
];

function HorizontalLinearAlternativeLabelStepper({
  currentActiveStep,
  // setCurrentActiveStep,
}) {
  return (
    <Box sx={{ width: "60%", margin: "10px 0px 20px" }}>
      <Stepper activeStep={currentActiveStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
            // onClick={() => setCurrentActiveStep(index)}
            >
              <p
                style={{
                  color: currentActiveStep === index ? "var(--pblue)" : "",
                  fontWeight: "bold",
                }}
              >
                {label}
              </p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const base = [
  {
    _id: 1,
    link: "/quest/owner",
    label: "Quest",
  },
];

const validationSchema = yup.object({
  companyName: yup.string().required("Company Name is required"),
  location: yup.string().required("Location is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  contact: yup.number().required("Contact number is required"),
  designation: yup.string().required("Designation is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  emailDomain: yup.string().required("Email domain is required"),
});

const NewOrganization = () => {
  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const [thumbnail, setThumbnail] = React.useState();
  const { orgId } = useParams();
  const [isLarge, setIsLarge] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isDisable, setIsDisable] = React.useState(true);
  const { data } = useGetOwnersByIdQuery(orgId, {
    skip: orgId === undefined,
  });
  const [addOwner] = useAddOwnerMutation();
  const [updateOwnersById] = useUpdateOwnersByIdMutation();
  const { displayAlert } = useAlert();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      companyName: "",
      location: "India",
      firstName: "",
      lastName: "",
      contact: "",
      email: "",
      emailDomain: "",
      thumbnail: "",
      designation: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      if (
        formik.values.companyName !== "" &&
        formik.values.location !== "" &&
        formik.values.email !== "" &&
        formik.values.firstName !== "" &&
        formik.values.lastName !== "" &&
        formik.values.emailDomain !== "" &&
        formik.values.contact && formik.values.designation !== ""
        // &&
        // currentActiveStep === 2
      ) {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("companyName", formik.values.companyName);
        formData.append("location", formik.values.location);
        formData.append("logo", formik.values.thumbnail || thumbnail);
        formData.append("contactPersonPhone", formik.values.contact);
        formData.append("contactPersonFname", formik.values.firstName);
        formData.append("contactPersonLname", formik.values.lastName);
        formData.append("contactPersonEmail", formik.values.email);
        formData.append("companyEmailDomain", formik.values.emailDomain);
        formData.append("contactPersonDesignation", formik.values.designation);
        if (currentActiveStep === 1) {
          if (!orgId) {
            addOwner(formData)
              .unwrap()
              .then((res) => {
                displayAlert({
                  open: true,
                  message:
                    res?.message || "Successfully added new organization",
                  type: "success",
                  timeout: "2000",
                });
                formik.handleReset();
                navigate('/quest/owner');
              })
              .catch((err) => {
                displayAlert({
                  open: true,
                  message: err?.data?.message || `Something went wrong`,
                  type: "error",
                  timeout: "2000",
                });
              })
              .finally(() => setSubmitting(false));
            formik.handleReset();
          } else {
            const apiData = { formData: formData, orgId: orgId };
            if (currentActiveStep === 1) {
              updateOwnersById(apiData)
                .unwrap()
                .then((res) => {
                  displayAlert({
                    open: true,
                    message:
                      res?.message ||
                      "Successfully updated organization details",
                    type: "success",
                    timeout: "2000",
                  });
                  navigate('/quest/owner');
                })
                .catch((err) => {
                  displayAlert({
                    open: true,
                    message: err?.data?.message || `Something went wrong`,
                    type: "error",
                    timeout: "2000",
                  });
                })
                .finally(() => setSubmitting(false));
            } else {
              setSubmitting(false);
            }
          }
        } else {
          setSubmitting(false);
        }
      }
    },
  });
  useEffect(() => {
    setCurrentActiveStep(0);
    if (data && orgId) {
      setThumbnail(data.data.logo);
      formik.setValues({
        companyName: data?.data?.companyName || "",
        location: data?.data?.location || "India", // Set a default value if it's undefined
        firstName: data?.data?.contactPersonFname || "",
        lastName: data?.data?.contactPersonLname || "",
        contact: data?.data?.contactPersonPhone || "",
        email: data?.data?.contactPersonEmail || "",
        thumbnail: data?.data?.logo || "",
        emailDomain: data?.data?.companyEmailDomain || "",
        designation: data?.data?.contactPersonDesignation || ""
      });
    }
  }, [data, orgId]);

  useEffect(() => { }, [
    orgId,
    data,
    formik.values.companyName,
    formik.values.location,
  ]);

  useEffect(() => {
    if (currentActiveStep === 0) {
      if (formik.values.companyName !== "" && formik.values.location !== "") {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }

    if (currentActiveStep === 1) {
      if (
        formik.values.email !== "" &&
        formik.values.firstName !== "" &&
        formik.values.lastName !== "" &&
        formik.values.contact
      ) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }
  }, [
    formik.values.companyName,
    formik.values.location,
    formik.values.contact,
    formik.values.email,
    formik.values.firstName,
    formik.values.lastName,
    currentActiveStep,
  ]);

  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: "error",
        timeout: "2000",
      });
    } else {
      setIsLarge(false);
      formik.setFieldValue("thumbnail", file);
      setThumbnail(file);
    }
  };

  const handlePrevious = () => {
    setCurrentActiveStep((prev) => prev - 1);
  };

  const handleNext = () => {
    if (
      currentActiveStep === 0 &&
      !formik.errors.companyName &&
      !formik.errors.location
    ) {
      setCurrentActiveStep((prev) => prev + 1);
    }
    if (
      currentActiveStep === 1 &&
      !formik.errors.firstName &&
      !formik.errors.email &&
      !formik.errors.lastName &&
      !formik.errors.contact && !formik.errors.designation
    ) {
      setCurrentActiveStep((prev) => prev + 1);
    }
  };

  return (
    <div className="container__accounts">
      <div className="org">
        <BreadcrumbAdmin base={base} page={"Owner"} />
        <HorizontalLinearAlternativeLabelStepper
          setCurrentActiveStep={setCurrentActiveStep}
          currentActiveStep={currentActiveStep}
        />
        <div className="org__title">
          {currentActiveStep === 0 && <h1> Company Details</h1>}
          {currentActiveStep === 1 && <h1> Contact Details</h1>}
          {currentActiveStep === 2 && <h1> Billing Information</h1>}{" "}
        </div>
        <form onSubmit={formik.handleSubmit}>
          {currentActiveStep === 0 && (
            <div className="field">
              <div>
                <h5 className="ftwt__r">Company Logo</h5>
                <div
                  style={{ borderColor: isLarge ? "var(--error)" : "" }}
                  className="dropzone-wrapper"
                >
                  {thumbnail ? (
                    <div className="dropzone-image">
                      <img
                        width={200}
                        height={"100%"}
                        src={
                          typeof thumbnail === "string"
                            ? thumbnail
                            : URL.createObjectURL(thumbnail)
                        }
                        alt="Thumbnail Preview"
                      />
                      <div className="owner__cross">
                        <CloseIcon
                          onClick={() => setThumbnail()}
                          sx={{
                            alignSelf: "flex-start",
                            fontSize: "18px",
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "var(--grey400)",
                            "&:hover": {
                              color: "var(--error)",
                            },
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="dropzone-desc">
                      <UploadFileOutlinedIcon
                        sx={{
                          fontSize: "70px",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "14px",
                          // color: isLarge ? 'var(--error)' : '',
                        }}
                      >
                        Choose an image file{" "}
                        <span style={{ fontSize: "11px" }}>
                          or drag it here.
                        </span>
                        <br />
                        <span style={{ fontSize: "11px" }}>
                          Maximum file size 1MB
                        </span>
                      </p>
                    </div>
                  )}
                  {!thumbnail && (
                    <input
                      name="thumbnail"
                      onChange={thumbnailHandler}
                      type="file"
                      accept="image/*"
                      // name="img_logo"
                      className="dropzone"
                    />
                  )}
                </div>
                {isLarge && (
                  <p
                    style={{
                      color: "var(--error)",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Choose Image smaller than 1MB
                  </p>
                )}
              </div>
              <div>
                <div className="field__detail">
                  <label htmlFor="companyName">
                    Company Name <span>*</span>
                  </label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    name="companyName"
                    placeholder="Enter Company Name"
                    fullWidth
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  />
                </div>
                <div className="field__detail">
                  <label htmlFor="location">
                    Location <span>*</span>
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formik.values.location}
                      // disableClearable
                      // defaultValue={formik?.values?.location}
                      name="location"
                      placeholder="Select Location"
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                      onChange={formik.handleChange}
                    >
                      {countries.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          {currentActiveStep === 1 && (
            <div className="field2">
              <div>
                <div className="field__detail">
                  <label htmlFor="firstName">
                    First Name <span>*</span>
                  </label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    name="firstName"
                    defaultValue={formik.values.firstName}
                    fullWidth
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </div>
                <div className="field__detail">
                  <label htmlFor="email">
                    Email ID<span>*</span>
                  </label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    name="email"
                    fullWidth
                    defaultValue={formik.values.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="field__detail">
                  <label htmlFor="email">
                    Company Email Domain<span>*</span>
                  </label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    name="emailDomain"
                    fullWidth
                    defaultValue={formik.values.emailDomain}
                    value={formik.values.emailDomain}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.emailDomain &&
                      Boolean(formik.errors.emailDomain)
                    }
                    helperText={
                      formik.touched.emailDomain && formik.errors.emailDomain
                    }
                  />
                </div>
                <div className="field__detail">
                  <label htmlFor="role">Role</label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    value={"Super Admin"}
                    disabled={true}
                    variant="outlined"
                    name="role"
                    fullWidth
                  />
                </div>
              </div>
              <div>
                <div className="field__detail">
                  <label htmlFor="lastName">
                    Last Name <span>*</span>
                  </label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    name="lastName"
                    defaultValue={formik.values.lastName}
                    fullWidth
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </div>
                <div className="field__detail">
                  <label htmlFor="email">
                    Contact Number<span>*</span>
                  </label>
                  <TextField
                    required
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                    name="contact"
                    defaultValue={formik.values.contact}
                    fullWidth
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contact && Boolean(formik.errors.contact)
                    }
                    helperText={formik.touched.contact && formik.errors.contact}
                  />
                </div>
                <div className="field__detail">
                  <label htmlFor="designation">
                    Designation <span>*</span>
                  </label>
                  <TextField
                    required
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    name="designation"
                    defaultValue={formik.values.designation}
                    fullWidth
                    value={formik.values.designation}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.designation &&
                      Boolean(formik.errors.designation)
                    }
                    helperText={
                      formik.touched.designation && formik.errors.designation
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {currentActiveStep === 1 && <div className="owner__action">
            {currentActiveStep !== 0 ? (
              <Button
                onClick={handlePrevious}
                sx={{ color: "gray" }}
                variant="text"
              >
                <ArrowBackIcon sx={{ fontSize: "15px" }} /> Previous
              </Button>
            ) : (
              <div></div>
            )}
            <div>
              <Button
                sx={{ color: "gray", borderColor: "gray" }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ marginLeft: "20px" }}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </div>}
        </form>
        {currentActiveStep==0&&<div className="owner__action">
          {currentActiveStep !== 0 ? (
            <Button
              onClick={handlePrevious}
              sx={{ color: "gray" }}
              variant="text"
            >
              <ArrowBackIcon sx={{ fontSize: "15px" }} /> Previous
            </Button>
          ) : (
            <div></div>
          )}
          <div>
            <Button
              sx={{ color: "gray", borderColor: "gray" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
                type="button"
                onClick={handleNext}
                disabled={isDisable}
                sx={{ marginLeft: "20px" }}
                variant="contained"
              >
                Next
              </Button>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default NewOrganization;
