import React, { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddTopic.css';
// import ImageResize from "quill-image-resize-module-react";
import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DeleteOutlineIcon } from "../../../../../assets/delete_icon.svg"
import { useAlert } from '../../../../../hooks/useAlert';
import {
  useCreateTopicMutation,
  useDeleteFileMutation,
  useDeleteTopicMutation,
  useUpdateTopicMutation,
  useUploadFileMutation,
  useUploadRTEImageMutation,
} from '../../../../../redux-toolkit/features/courses/coursesApiSlice';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const Align = Quill.import('formats/align');
Quill.register(Align, true);

const AddVideoLinkAlert = ({
  open,
  handleClose,
  handleSubmit,
  url,
  setUrl,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        style: {
          width: '100%',
        },
      }}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enter a video url
        </DialogContentText>
        <TextField
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            marginTop: '5px',
          }}
          type={'url'}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          id="task-points-add-field"
          name="points"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions
        style={{
          padding: '20px 24px',
        }}>
        <Button
          variant="contained"
          onClick={() => handleSubmit()}>
          Done
        </Button>
        <Button
          variant="outlined"
          autoFocus
          onClick={() => handleClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddTopic = ({
  course,
  refetch,
  module,
  setShowTopic,
  topic,
  isView
}) => {
  const quill = useRef();
  const [name, setName] = useState(topic?.name);
  const [timeToComplete, setTimeToComplete] = useState(topic?.timeToComplete);
  const [content, setContent] = useState(topic?.content);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteTopic] = useDeleteTopicMutation();
  const { displayAlert, clearAlert } = useAlert();
  const [createTopic] = useCreateTopicMutation();
  const [updateTopic] = useUpdateTopicMutation();
  const [uploadRTEImage] = useUploadRTEImageMutation();
  const [thumbnail, setThumbnail] = React.useState(topic?.resources || []);
  const [isLarge, setIsLarge] = React.useState(false);
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      // [{size: []}],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  }
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  const handleOpen = () => setDeleteOpen(true);
  // useEffect(() => {
  //   if (!isAdd) {
  //     setThumbnail(topics?.resources);
  //   }
  // }, [topics]);
  useEffect(() => { }, [thumbnail]);

  const saveTopicHandler = async () => {
    const topic = {
      name: name,
      content: content,
      resources: thumbnail,
      timeToComplete: timeToComplete,
    };

    const finalTask = {
      topic,
      courseId: course?._id,
      moduleId: module?._id,
    };

    if (name && content && timeToComplete) {
      if (timeToComplete <= 0) return displayAlert({
        open: true,
        message: 'Time to complete cannot be less than 1 minute',
        type: 'error',
        timeout: '2000',
      });
      await createTopic(finalTask);
      await refetch();
      setName('');
      setTimeToComplete('');
      setContent('');
      setShowTopic(false);
      displayAlert({
        open: true,
        message: 'Added New Topic Successfully',
        type: 'success',
        timeout: '2000',
      });
    } else
      displayAlert({
        open: true,
        message: 'Please fill in all the fields',
        type: 'error',
        timeout: '2000',
      });
  };

  const deleteTopicHandler = async () => {
    const topicId = topic?._id;
    await deleteTopic(topicId);
    await refetch();
    setShowTopic(false);
    displayAlert({
      open: true,
      message: 'Successfully Deleted Topic',
      type: 'error',
      timeout: '2000',
    });
  };

  const updateTopicHandler = async () => {
    const updatedTopic = {
      name: name,
      content: content,
      resources: thumbnail,
      timeToComplete: timeToComplete,
    };
    const topicId = topic?._id;
    const finalData = { topic: updatedTopic, topicId };
    if (!name || !content || !timeToComplete)
      return displayAlert({
        open: true,
        message: 'Please fill in all the fields',
        type: 'error',
        timeout: '2000',
      });
    if (timeToComplete <= 0)
      return displayAlert({
        open: true,
        message: 'Time to complete cannot be less than 1 minute',
        type: 'error',
        timeout: '2000',
      });
    await updateTopic(finalData).then(async (res) => {
      if (res?.error?.status == 400) {
        throw res.error
      }
      setShowTopic(false);
      await refetch();
      displayAlert({
        open: true,
        message: 'Successfully Updated Topic',
        type: 'success',
        timeout: '2000',
      });
    }).catch(err => {
      displayAlert({
        open: true,
        message: err?.data?.message ?? 'Unable to update Topic, please try again',
        type: 'error',
        timeout: '2000',
      });
    })

  };

  function getVideoUrl(url) {
    let match =
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
      ) ||
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
      ) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);

    if (match && match[2].length === 11) {
      return 'https' + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
    }

    if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
      // eslint-disable-line no-cond-assign
      return (
        (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/'
      );
    }

    return null;
  }

  function videoHandler() {
    let url = prompt('Enter youtube url');
    url = getVideoUrl(url);
    let range = quill.current?.editor?.getSelection();
    if (url != null) {
      quill.current?.editor?.insertEmbed(range, 'video', url);
    } else {
      displayAlert({
        open: true,
        message: 'Invalid video link',
        type: 'error',
        timeout: '2000',
      });
    }
  }

  const imageHandler = async () => {
    let inp = document.createElement('input');
    inp.visibility = 'hidden';
    document.body.appendChild(inp);
    inp.type = 'file';
    inp.addEventListener('change', async (e) => {
      let file = e.target.files[0];
      if (file) {
        if (file.size > 1024 * 1000) {
          displayAlert({
            open: true,
            message: 'Image must be less than 4MB  in size',
            type: 'error',
            timeout: '2000',
          });
        } else {
          displayAlert({
            open: true,
            message: 'Uploading image...',
            type: 'success',
            timeout: '20000',
          });
          await uploadRTEImage(file)
            .unwrap()
            .then((res) => {
              let url = res.data?.location; // upload to s3
              let range = quill.current?.editor?.getSelection();
              if (url != null) {
                quill.current?.editor?.insertEmbed(range, 'image', url);
              }
            })
            .finally(() => {
              clearAlert();
            });
        }
      }
    });
    inp.click();
  };

  useEffect(() => {
    quill.current?.editor
      ?.getModule('toolbar')
      ?.addHandler('video', videoHandler);
    quill.current?.editor
      ?.getModule('toolbar')
      ?.addHandler('image', imageHandler);
  }, [quill]);


  const thumbnailHandler = async (event) => {
    const file = event.target.files[0];
    if (file.size >= 4099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: 'error',
        timeout: '2000',
      });
    } else {
      setIsLarge(false);
      uploadFile(file)
        .unwrap()
        .then(async (data) => {
          let fileFormat = await {
            url: data?.data?.location,
            filename: file.name,
            filetype: file.type,
          };
          setThumbnail([...thumbnail, fileFormat]);
        })
        .catch((err) =>
          displayAlert({
            open: true,
            message: `${err}`,
            type: 'error',
            timeout: '2000',
          })
        );
    }
  };

  const deleteResourseHandler = async (id) => {
    const file = { url: thumbnail[id]?.url };
    await deleteFile(file);
    const updatedArray = thumbnail.filter((item, index) => index != id);
    setThumbnail(updatedArray);
  };


  return (
    <>
      <div className="addTopics">
        <div>
          <h5 className="ftwt__r">Topic Name</h5>
          <TextField
            disabled={isView}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              marginTop: '5px',
            }}
            id="outlined-basic"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </div>
        <div>
          <h5 className="ftwt__r">Content</h5>
          <ReactQuill
            // ref={quill}
            readOnly={isView}
            rows={2}
            style={{
              marginBottom: '40px',
              height: '300px',
              marginTop: '5px',
            }}
            value={content}
            onChange={(value) => setContent(value)}
            theme="snow"
            modules={modules} formats={formats}
          />
        </div>
        <div>
          <h5 className="ftwt__r">Add Resources (PDF)</h5>
          <div
            style={{ borderColor: isLarge ? 'var(--error)' : '' }}
            className="dropzone-wrapper">
            <div className="dropzone-desc">
              <UploadFileOutlinedIcon
                sx={{
                  fontSize: '70px',
                }}
              />
              <p
                style={{
                  fontSize: '14px',
                  // color: isLarge ? 'var(--error)' : '',
                }}>
                Choose an PDF file{' '}
                <span style={{ fontSize: '11px' }}>or drag it here.</span>
                <br />
                <span style={{ fontSize: '11px' }}>
                  Maximum file size 4MB
                </span>
              </p>
            </div>

            {/* {thumbnail && ( */}
            <input
              disabled={isView}
              name="thumbnail"
              onChange={(e)=>{
                thumbnailHandler(e)
                e.target.value = null
              }}
              type="file"
              accept="application/pdf" // name="img_logo"
              className="dropzone"
            />
            {/* )} */}
          </div>
          {isLarge && (
            <p
              style={{
                color: 'var(--error)',
                marginTop: '5px',
                fontSize: '12px',
              }}>
              Choose PDF smaller than 4MB
            </p>
          )}
          <br />
          {thumbnail &&
            thumbnail.length > 0 &&
            thumbnail.map((item, index) => (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}>
                <h5 style={{ marginTop: '5px', color: 'var(--pblue)' }}>
                  {item.filename}
                </h5>
                {!isView && <CloseIcon
                  onClick={() => deleteResourseHandler(index)}
                  sx={{
                    marginTop: '5px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    color: 'var(--grey400)',
                    '&:hover': {
                      color: 'var(--error)',
                    },
                  }}
                />}
              </div>
            ))}
        </div>
        <div>
          <h5 className="ftwt__r">
            Time to Complete (In minutes)
          </h5>
          <TextField
            disabled={isView}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              marginTop: '5px',
            }}
            id="outlined-basic"
            type="number"
            name="timeToComplete"
            value={timeToComplete}
            onChange={(e) => { setTimeToComplete(e.target.value); }}
            fullWidth
            variant="outlined"
          />
        </div>
        <div className="topic__actions">
          {Object.keys(topic).length == 0 && <Button
            color="error"
            onClick={() => setShowTopic(false)}
            variant="outlined">
            Close
          </Button>}
          {Object.keys(topic).length > 0 && !isView && <DeleteOutlineIcon onClick={deleteTopicHandler} style={{
            cursor: 'pointer',
            color: 'var(--grey400)',
            '&:hover': {
              color: 'var(--error)',
            }, fontSize: '30px',
          }} />}
          {Object.keys(topic).length == 0 && <Button
            disabled={isView}
            onClick={saveTopicHandler}
            variant="outlined">
            Add Topic
          </Button>}
          {Object.keys(topic).length > 0 && <Button
            disabled={isView}
            onClick={updateTopicHandler}
            variant="outlined">
            Update Topic
          </Button>}
        </div>
      </div>
    </>
  );
};
