import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import { setFeedbackOpen, setTourActive, setTourOpen } from '../../redux-toolkit/features/theme/themeSlice';

const ProfileListItem = ({
  name,
  icon,
  index,
  role,
  guide,
  iconOnHover,
  link,
  theme,
  newTab,
}) => {
  const [iconSrc, setIconSrc] = useState(icon);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMouseEnter = (iconPath) => {
    return () => {
      setIconSrc(iconPath);
    };
  };

  const handleRedirect = () => {
    if (name === 'Feedback') {
      return dispatch(setFeedbackOpen(true));
    } else if (name === 'Quest Walkthrough') {
      localStorage.setItem('alpha_screens', JSON.stringify([]));
      dispatch(setTourActive(true));
      // dispatch(setTourActive(true));
    }
    return null;
  };

  return (
    <>
      {name === 'My Activity' && role?.label === 'Super Admin' ? (
        ''
      ) : newTab ? (
        <a
          key={index}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
          href={link}>
          <li
            onMouseEnter={handleMouseEnter(iconOnHover)}
            onMouseLeave={handleMouseEnter(icon)}
            onClick={handleRedirect}
            id={guide}
            // {`${name === 'Account' && 'step14'} ${
            //   name === 'User Manual' && 'step15'
            // }`}
            style={{ color: theme ? 'var(--grey600)' : 'var(--grey200)' }}>
            <span
              style={{
                marginRight: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '5px',
              }}>
              <img
                src={iconSrc}
                alt={name}
                style={{
                  height: '22px',
                  width: '25px',
                }}
              />
            </span>
            {name}
          </li>
        </a>
      ) : (
        <Link
          key={index}
          style={{ textDecoration: 'none' }}
          to={link}>
          <li
            id={guide}
            onMouseEnter={handleMouseEnter(iconOnHover)}
            onMouseLeave={handleMouseEnter(icon)}
            onClick={handleRedirect}
            style={{ color: theme ? 'var(--grey600)' : 'var(--grey200)' }}>
            <span
              style={{
                marginRight: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '5px',
              }}>
              <img
                src={iconSrc}
                alt={name}
                style={{
                  height: '22px',
                  width: '25px',
                }}
              />
            </span>
            {name}
          </li>
        </Link>
      )}
    </>
  );
};

export default ProfileListItem;
