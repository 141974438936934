import { apiSlice } from "../../api/apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Admin routes
    badgeStats: builder.query({
      query: ({ type, deptId, userType, startDate, endDate }) => ({
        url: `/api/dashboard/get/points-stats`,
        method: 'GET',
        params: {
          type,
          deptId,
          userType,
          startDate: startDate ?? undefined,
          endDate: endDate ?? undefined,
        },
      }),
    }),
    userStats: builder.query({
      query: ({ type, deptId, userType, startDate, endDate }) => ({
        url: '/api/dashboard/get/user-stats',
        method: 'GET',
        params: {
          type,
          deptId,
          userType,
          startDate: startDate ?? undefined,
          endDate: endDate ?? undefined,
        },
      }),
    }),
    bookStats: builder.query({
      query: ({
        bookId,
        type,
        deptId,
        userType,
        startDate,
        endDate,
        data,
      }) => {
        // Construct the URL with query parameters
        const queryParams = {};

        // Add parameters only if they have values
        if (type !== undefined) queryParams.type = type;
        if (deptId !== undefined) queryParams.deptId = deptId;
        if (userType !== undefined) queryParams.userType = userType;
        if (startDate !== undefined) queryParams.startDate = startDate;
        if (endDate !== undefined) queryParams.endDate = endDate;
        if (bookId !== undefined) queryParams.bookId = bookId;

        const url = `/api/dashboard/get/books-stats?${new URLSearchParams(
          queryParams
        ).toString()}`;

        return {
          url,
          method: 'POST',
        };
      },
    }),

    adminTaskBasedBadgeStats: builder.query({
      query: () => ({
        url: '/api/dashboard/admin/badges-stats/tasks',
        method: 'GET',
      }),
    }),
    adminPointBasedBadgeStats: builder.query({
      query: () => ({
        url: '/api/dashboard/admin/badges-stats/points',
        method: 'GET',
      }),
    }),
    bookData: builder.query({
      query: ({ type, deptId, userType }) => ({
        url: `/api/dashboard/get/books`,
        method: 'GET',
        params: { type, deptId, userType },
      }),
    }),
    getLeaderboard: builder.query({
      query: ({ type, page = 1, deptId, limit = 10, startDate, endDate }) => ({
        url: `/api/dashboard/get/leaderboard?page=${page}&limit=${limit}&startDate=${
          startDate || ''
        }&endDate=${endDate || ''}&deptId=${deptId || ''}`,
        method: 'GET',
      }),
    }),
    bookEmployees: builder.query({
      query: ({ bookId, page = 1, limit = 10, userType }) => ({
        url: `/api/dashboard/get/books/${bookId}/employees?page=${page}&limit=${limit}${
          userType === 'superadmin' ? '&userType=superadmin' : ''
        }`,
        method: 'GET',
      }),
    }),
    // Super Admin routes
  }),
});

export const {
  useBadgeStatsQuery,
  useBookStatsQuery,
  useUserStatsQuery,
  useAdminTaskBasedBadgeStatsQuery,
  useAdminPointBasedBadgeStatsQuery,
  useBookDataQuery,
  useBookEmployeesQuery,
  useGetLeaderboardQuery,
} = dashboardApiSlice;
