import React from "react";
import "./CredentialPoints.css";

const PointBadgeItem = ({ url }) => {
  return (
    <div className="cp__item">
      <img alt="badge" src={url} />
    </div>
  );
};

const PointBadgeCheckpoint = ({ id, title, description }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        flexDirection: "column",
        position: "relative",
        textAlign: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          outline: "3px solid #3361cf",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        {id}
      </div>

      <svg
        width="7"
        height="6"
        viewBox="0 0 7 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          bottom: "-8px",
        }}
      >
        <path
          d="M3.89664 5.25C3.70419 5.58333 3.22307 5.58333 3.03062 5.25L-0.000469637 -1.28846e-07L6.92773 4.76837e-07L3.89664 5.25Z"
          fill="#3361CF"
        />
      </svg>
    </div>
  );
};

const CredentialPoints = ({ points }) => {
  return (
    <div className="cp__container">
      <div className="cp__badgescont">
        {points?.map((badge) => {
          return <PointBadgeItem key={badge?._id} url={badge.badgeImage} />;
        })}
      </div>
      <div
        className="cp__badgescont"
        style={{
          position: "relative",
        }}
      >
        {points?.map((badge) => {
          return (
            <p
              style={{
                color: "#3361cf",
                textAlign: "center",
                width: "100px",
                wordBreak: "keep-all",
                whiteSpace: "nowrap",
                marginTop: "20px",
              }}
            >
              {badge.badgeName}
            </p>
          );
        })}
      </div>

      <div className="cp__timeline">
        <div className="cp__badgepointscont">
          {points?.map((badge, index) => {
            return (
              <PointBadgeCheckpoint
                key={index}
                id={index + 1}
                title={badge.title}
                description={badge.description}
              />
            );
          })}
        </div>
      </div>
      <div
        className="cp__badgescont"
        style={{
          position: "relative",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {points?.map((badge) => {
          return (
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                width: "120px",
              }}
            >
              To get a {badge.badgeName} badge you have to earn {badge.valueThreshold} points
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default CredentialPoints;
