import React, { useEffect, useRef } from 'react';
import { GraphCards } from './GraphCards';
import './Graph.css';

export const Graph = ({ badgeStats, bookData, userStats, bookStats }) => {
  React.useEffect(() => {}, [badgeStats, userStats, bookStats]);

  return (
    <div className="dashboard__graphs">
      {userStats && (
        <GraphCards
          item={userStats?.data}
          isUser
          _id={userStats?.data?._id}
          defaultData={[1, 1]}
        />
      )}
      {badgeStats && (
        <GraphCards
          item={badgeStats?.data}
          _id={badgeStats?.data?._id}
          isStar
          defaultData={[1, 1]}
        />
      )}
      {true && (
        <GraphCards
          isBooks
          bookData={bookData}
          item={bookStats?.data}
          _id={bookStats?.data?._id}
          defaultData={[1, 1, 1]}
        />
      )}
    </div>
  );
};
