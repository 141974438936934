import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useBookEmployeesQuery } from "../../../../../redux-toolkit/features/dashboard/dashboardApliSlice";
import "./ActivityModal.css";
import { ActivityTable } from "./ActivityTable";
import { da } from "date-fns/locale";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  // height: "100%",
  maxHeight: "80vh",
  overflowY: "auto",
  overflowX: "auto",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: "15px 30px",
};

export const ActivityModal = ({
  setActivityOpen,
  activityOpen,
  bookId,
  userType,
}) => {
  const handleClose = () => setActivityOpen(false);
  const [page, setPage] = React.useState(1);

  const { data } = useBookEmployeesQuery(
    { bookId, page, limit: 10, userType },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <div>
      <div>
        <Modal
          open={activityOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="activitymodal">
              <CloseIcon
                onClick={handleClose}
                sx={{
                  fontSize: "18px",
                  cursor: "pointer",
                  marginLeft: "auto",
                  color: "var(--grey400)",
                  "&:hover": {
                    color: "var(--error)",
                  },
                }}
              />
              <div className="activitymodal__head">
                <div>
                  <img src={data?.data?.book?.coverImage} alt="" />
                  <h4 className="ftwt__r">{data?.data?.book?.name}</h4>
                </div>
                <p>{data?.data?.users?.totalResults} Employees</p>
              </div>
              <div className="seprator"></div>
              {data?.data?.users?.totalResults ? (
                <>
                  <ActivityTable users={data?.data?.users?.list || []} />
                  <div style={{display:"flex",justifyContent:"center", margin:"10px 0px"}}>
                    <Pagination
                      count={data?.data?.users?.totalPages}
                      shape="rounded"
                      page={page}
                      onChange={(e, newPage) => {
                        setPage(newPage);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div style={{display:"flex",justifyContent:"center", margin:"10px 0px"}}>
                  <p>No employee has attempted the book</p>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
