import { Box, Button, Divider } from "@mui/material";
import React from "react";
import { useGetBooksQuery } from "../../../../redux-toolkit/features/books/bookApiSlice";
import { AdminCard } from "../../../Admin/My Workspace/AdminCard";
import { CreateCard } from "../../../Admin/My Workspace/Create Card/CreateCard";
import { CreateBooks } from "../../../Admin/My Workspace/CreateBooks";
import { Error404 } from "../../../UI/404/Error404";
import ProgressBar from "../../../UI/ProgressBar";

import "./BooksMq.css";

export const BooksMq = () => {
  const {
    data: allBooksData,
    isLoading: isAllBooksLoading,
    isSuccess: isAllBooksSuccess,
    isError,
  } = useGetBooksQuery("org");

  const [bookOpen, setBookOpen] = React.useState(false);
  const handleBookOpen = () => setBookOpen(true);
  const [drawer, setDrawer] = React.useState(0);

  if (isAllBooksLoading) {
    return <ProgressBar />;
  }
  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="container">
      <Box sx={{ width: "100%" }}>
        <div className="generic__cards">
          {isAllBooksSuccess &&
            allBooksData.data.books &&
            allBooksData.data.books.map((elm, index) => (
              <div key={index}>
                <AdminCard
                  setDrawer={setDrawer}
                  drawer={drawer}
                  data={elm}
                  handleBookOpen={handleBookOpen}
                  isBook
                  isGeneric
                />
              </div>
            ))}

          {/* <div onClick={handleBookOpen}>
            <CreateCard label={'Add Book'} />
          </div>
          <CreateBooks
            type={'ORG'}
            bookOpen={bookOpen}
            setBookOpen={setBookOpen}
          /> */}
        </div>
      </Box>
    </div>
  );
};
