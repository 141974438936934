import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import sortDown from "../../../assets/employee/sort_down.svg";
import sortUp from "../../../assets/employee/sort_up.svg";
import { useGetRedeemStatusQuery } from "../../../redux-toolkit/features/rewards/rewardsApiSlice";
import ProgressBar from "../../UI/ProgressBar";
import "../../UI/Tables/TableUi.css";

import approvedIcon from "../../../assets/rewards/approved.svg";
import pendingIcon from "../../../assets/rewards/pending.svg";

export const SortIcon = ({ sortOrder }) => {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2px",
        width: "fit-content",
      }}
    >
      <img src={sortUp} />
      <img src={sortDown} />
    </span>
  );
};

export const RedeemStatus = ({ selectedEmployee }) => {
  const [sortBy, setSortBy] = useState("status");
  const [sortType, setSortType] = useState("asc");
  const role = useSelector((state) => state.auth.user.role);

  const { data: redeem } = useGetRedeemStatusQuery(
    {
      sortBy,
      sortType,
    },
    { refetchOnMountOrArgChange: true }
  );

   const handleSorting = (sortKey) => {
     setSortBy((prev) => sortKey);
     setSortType((prev) => (prev === 'desc' ? 'asc' : 'desc'));
   };

  return (
    <div style={{ width: '100%' }}>
      {redeem ? (
        <TableContainer
          sx={{
            // height: 'calc( 100vh - 230px)',
            padding: '0 1px',
            width: 'calc( 100vw - 150px)',
          }}>
          <Table
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ borderRadius: '15px 15px 0px 0px' }}
                className="tableUi__head">
                <TableCell
                  sx={{ borderRadius: '15px 0px 0px 0px' }}
                  align="left"
                  onClick={() => handleSorting('name')}>
                  <div
                    className="tableUi__head_sortable"
                    style={{
                      justifyContent: 'flex-start',
                      paddingLeft: '10px',
                    }}>
                    Product Name <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  onClick={() => handleSorting('name')}>
                  Product Points
                </TableCell>
                <TableCell align="center">Product Quantity</TableCell>
                <TableCell align="center">Request Date</TableCell>

                <TableCell
                  sx={{
                    borderRadius:
                      role != 'SUPERADMIN' ? '0px 15px 0px 0px' : '',
                  }}
                  align="center"
                  onClick={() => handleSorting('status')}>
                  <div className="tableUi__head_sortable">
                    Status <SortIcon sortOrder={sortType} />
                  </div>
                </TableCell>

                {role === 'SUPERADMIN' && (
                  <TableCell
                    sx={{ borderRadius: '0px 15px 0px 0px' }}
                    align="center">
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {redeem &&
                [...(redeem?.data || [])].map((row, index) => (
                  <React.Fragment key={index}>
                    <div className="row__spacing"></div>
                    <TableRow
                      className="tableUi___body"
                      key={row?.name}
                      sx={{ borderRadius: '10px' }}
                      style={{
                        backgroundColor: selectedEmployee?.includes(row?._id)
                          ? '#3361cf22'
                          : '',
                      }}>
                      <TableCell
                        sx={{
                          borderRadius: '10px 0px 0px 10px',
                          // border: "1px solid grey",
                          paddingLeft: '10px',
                        }}
                        align="left">
                        {row?.items?.product?.name}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            alignItem: 'center',
                            justifyContent: 'center',
                          }}>
                          <StarBorderOutlinedIcon
                            sx={{ fontSize: '20px', color: 'var(--syellow)' }}
                          />
                          <p>
                            {' '}
                            {row?.items?.product?.points}
                            {' '}Points
                          </p>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {row?.items?.quantity}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(row?.createdAt).format('DD/MM/YY')}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderRadius: '0px 10px 10px 0px',
                          // border: "1px solid grey",
                        }}
                        align="center">
                        {row?.items.status != 'COMPLETED' ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: '4px',
                              alignItem: 'center',
                              justifyContent: 'center',
                              color: 'var(--error)',
                              fontWeight: '400',
                            }}>
                            <img
                              src={pendingIcon}
                              alt="pending"
                            />{' '}
                            <p style={{ color: 'var(--error)' }}> Pending</p>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              gap: '4px',
                              alignItem: 'center',
                              justifyContent: 'center',
                              color: 'var(--success)',
                              fontWeight: '400',
                            }}>
                            {' '}
                            <img
                              src={approvedIcon}
                              alt="approved"
                            />{' '}
                            <p style={{ color: 'var(--success)' }}> Approved</p>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="employee__loader">
          <ProgressBar />
        </div>
      )}
    </div>
  );
};
