import "./SubModules.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


export const SubModule = ({submodule, index, setShowAddTopic,setShowQuiz,setSelectedSubModule}) => {

    return (
        <div className="submodules">
            <div className="submodules__type">
                <h3 className="ftwt__r" style={{ color: "var(--pblue)", paddingLeft: "10px" }}>{index+1}.  {submodule?.type ?? "Topic"}</h3>
                <EditOutlinedIcon
                  sx={{
                    fontSize: '17px',
                    color: "var(--pblue)",
                    ":hover":{cursor: "pointer"}
                  }}
                  onClick={()=>{
                    if(submodule?.type=="Quiz") setShowQuiz(true)
                    else setShowAddTopic(true)
                    // submodule.index = index
                    setSelectedSubModule({...submodule, index})
                }}
                />
            </div>
            <div className="seprator"></div>
            <div className="submodules__title">
                <h6 className="ftwt__r">{submodule.name}</h6>
            </div>
        </div>
    )
}