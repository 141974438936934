import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Sidebar } from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "./Main.css";
import { Navbar } from "../../Navbar/Navbar";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sidebarToggler } from "../../../redux-toolkit/features/theme/themeSlice";
import dashboardD from "../../../assets/Side Bar/Super Admin/dark Mode/Dashboard.svg";
import dashboardDH from "../../../assets/Side Bar/Dark Background/Dashboard _ active.svg";
import dashboard from "../../../assets/Side Bar/Light Background/Dashboard _ default.svg";
import dashboardH from "../../../assets/Side Bar/Light Background/Dashboard _ active.svg";
import reports from "../../../assets/Side Bar/Light Background/Reports _ default.svg";
import reportsH from "../../../assets/Side Bar/Light Background/Reports _ active.svg";
import reportsD from "../../../assets/Side Bar/Super Admin/dark Mode/Reports.svg";
import reportsDH from "../../../assets/Side Bar/Dark Background/Reports _ active.svg";
import workspace from "../../../assets/Side Bar/Light Background/My Workspace _ default.svg";
import workspaceH from "../../../assets/Side Bar/Light Background/My Workspace _ active.svg";
import workspaceD from "../../../assets/Side Bar/Light Background/My Workspace _ default.svg";
import workspaceDH from "../../../assets/Side Bar/Dark Background/My Workspace _ active.svg";
import TaskVerification from "../../../assets/Side Bar/Light Background/Task Verification _ default.svg";
import TaskVerificationH from "../../../assets/Side Bar/Light Background/Task Verification _ active.svg";
import TaskVerificationD from "../../../assets/Side Bar/Light Background/Task Verification _ default.svg";
import TaskVerificationDH from '../../../assets/Side Bar/Super Admin/Dark Background/task_verification_filled.svg';
import ManageQuest from "../../../assets/Icons/SuperAdmin Icons/Manage Quest _ Default.svg";
import ManageQuestH from "../../../assets/Side Bar/Super Admin/Light Background/manage quest_active.svg";
import ManageQuestD from "../../../assets/Side Bar/Super Admin/Light Background/manage quest_default.svg";
import ManageQuestDH from '../../../assets/Side Bar/Super Admin/Light Background/manage_quest_filled.svg';
import ManageOrg from '../../../assets/Icons/SuperAdmin Icons/Manage Organization _ Default.svg';
import ManageOrgH from '../../../assets/Side Bar/Super Admin/Light Background/manage_organisation_filled.svg';
import ManageOrgD from '../../../assets/Side Bar/Super Admin/dark Mode/Manage Organisation.svg';
import ManageOrgDH from '../../../assets/Side Bar/Super Admin/Light Background/manage org_active.svg';

import DepartmentD from '../../../assets/Side Bar/Super Admin/Dark Background/department_default.svg';
import DepartmentDH from '../../../assets/Side Bar/Super Admin/Dark Background/department_active.svg';
import Department from '../../../assets/Side Bar/Super Admin/Light Background/department_default.svg';
import DepartmentH from '../../../assets/Side Bar/Super Admin/Light Background/department_active.svg';

import RewardsD from '../../../assets/Side Bar/Super Admin/light Mode/rewards_default 2.svg';
import RewardsH from '../../../assets/Side Bar/Super Admin/light Mode/rewards_filled1 2.svg';
import RewardsDH from '../../../assets/Side Bar/Super Admin/light Mode/rewards_filled1.svg';

import { useGetInitialAppliedThemeQuery } from '../../../redux-toolkit/features/theme/themeApiSlice';
import { roleToggler } from '../../../redux-toolkit/features/access/accessSlice';

const sideBarAdmin = [
  {
    id: 1,
    name: 'Dashboard',
    icon: dashboard,
    iconOnHover: dashboardH,
    iconD: dashboardD,
    iconOnHoverD: dashboardDH,
    altText: 'Dashboard',
    link: '',
    access: ['ADMIN_REPORTS'],
  },
  {
    id: 2,
    name: 'My Workspace',
    icon: workspace,
    iconOnHover: workspaceH,
    iconD: workspaceD,
    iconOnHoverD: workspaceDH,
    altText: 'My Workspace',
    link: 'my-workspace',
    access: ['ADMIN_BOOKS', 'ADMIN_COURSE'],
  },
  {
    id: 3,
    name: 'Manage Department',
    icon: Department,
    iconOnHover: DepartmentH,
    iconD: DepartmentD,
    iconOnHoverD: DepartmentDH,
    altText: 'Manage Department',
    // link: 'task-verification',
    access: ['ADMIN_TASK_VER'],
  },
  // {
  //   id: 3,
  //   name: 'Task Verification',
  //   icon: TaskVerification,
  //   iconOnHover: TaskVerificationH,
  //   iconD: TaskVerificationD,
  //   iconOnHoverD: TaskVerificationDH,
  //   altText: 'Task Verification',
  //   link: 'task-verification',
  //   access: ['ADMIN_TASK_VER'],
  // },

  {
    id: 4,
    name: 'Reports',
    icon: reports,
    iconOnHover: reportsH,
    iconD: reportsD,
    iconOnHoverD: reportsDH,
    altText: 'Reports',
    link: 'reports',
    access: ['ADMIN_REPORTS'],
  },
];
const sideBarSuperAdmin = [
  {
    id: 1,
    name: 'Dashboard',
    icon: dashboard,
    iconOnHover: dashboardH,
    iconD: dashboardD,
    iconOnHoverD: dashboardDH,
    altText: 'Dashboard',
    link: '',
    access: ['SUPERADMIN_REPORTS'],
  },
  {
    id: 3,
    name: 'Manage Quest',
    icon: ManageQuestD,
    iconOnHover: ManageQuestH,
    iconD: ManageQuest,
    iconOnHoverD: ManageQuestDH,
    altText: 'manage-quest',
    // link: "",
    access: ['SUPERADMIN_BOOKS', 'SUPERADMIN_THEMES'],
  },
  {
    id: 2,
    name: 'Manage Organization',
    icon: ManageOrgD,
    iconOnHover: ManageOrgDH,
    iconD: ManageOrg,
    iconOnHoverD: ManageOrgH,
    altText: 'Manage Organization',
    // link: "",
    access: ['SUPERADMIN_EMPLOYEES', 'SUPERADMIN_DEPARTMENT'],
  },
  {
    id: 4,
    name: 'Reports',
    icon: reports,
    iconOnHover: reportsH,
    iconD: reportsD,
    iconOnHoverD: reportsDH,
    altText: 'Reports',
    link: 'reports',
    access: ['SUPERADMIN_REPORTS'],
  },
  {
    id: 5,
    name: 'Manage Rewards',
    icon: RewardsD,
    iconOnHover: RewardsH,
    iconD: RewardsD,
    iconOnHoverD: RewardsDH,
    altText: 'Manage Rewards',
    // link: 'rewards',
    access: ['SUPERADMIN_REWARDS'],
  },
];
export const AdminLayout = ({ isAdmin, isSuperAdmin }) => {
  const [open, setOpen] = useState(true);
  const { bg } = useSelector((state) => state.theme);
  const { data, isLoading, isError } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setOpen(!open);
    dispatch(sidebarToggler(!open));
  };
  React.useEffect(() => {
    if (isAdmin) {
      dispatch(roleToggler('Admin'));
    }
    if (isSuperAdmin) {
      dispatch(roleToggler('Super Admin'));
    }
  }, [isAdmin, isSuperAdmin]);
  

  React.useEffect(() => {}, [data]);

  return (
    <>
      <div
        className="layout"
        style={{
          backgroundImage: `url(${data?.data.bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="layouthead">
          <Navbar isAdmin />
        </div>
        <div className="layout__right">
          <div className="layout__left">
            {isAdmin && (
              <Sidebar
                sideBarAdmin={sideBarAdmin}
                isAdmin
                open={open}
                handleSidebar={handleSidebar}
              />
            )}
            {isSuperAdmin && (
              <Sidebar
                sideBarAdmin={sideBarSuperAdmin}
                isAdmin
                open={open}
                handleSidebar={handleSidebar}
              />
            )}
          </div>
          <div className="main__wrapper">
            {/* <div className="main__righttop"></div>
            <div className="main__left"></div> */}
            <div className="main">
              <Outlet open={open} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
