import React from 'react';
import './BooksLevels.css';
import mainArrow from '../../../assets/Icons/books/arrow with middle design.svg';
import sepratorArrow from '../../../assets/Icons/books/sepratorArrow.svg';
import star from '../../../assets/Icons/modules/Star.svg';
import arrow from '../../../assets/Icons/arrow.svg';
import {
  useGetBooksLevelsQuery,
  useGetLevelsByIdQuery,
} from '../../../redux-toolkit/features/books/bookApiSlice';
import ProgressBar from '../../UI/ProgressBar';
import { LevelTask } from './LevelTask';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

export const BooksLevels = ({
  length,
  bookMode,
  bookTheme,
  bookId,
  setPage,
  page,
  curElm,
  levelId,
  bookName,
  prev,
  next,
  name
}) => {
  const {
    data: levelData,
    isSuccess: levelSuccess,
    isError: levelError,
    isFetching: levelFetching,
    isLoading: levelLoading,
    refetch,
  } = useGetLevelsByIdQuery(levelId);

  // if (isLoading) {
  //   return <ProgressBar />;
  // }
  let levelIndex = page + 1;

  return (
    <>
      <div
        key={levelId}
        className="booksLevels">
        <div className="booksLevels__head">
          {page === 0 ? (
            ''
          ) : (
            // <img
            //   src={arrow}
            //   onClick={() => setPage(page - 1)}
            //   className="booksLevels__larrow"
            //   alt="arrow"
            // />
            <PlayArrowIcon
              onClick={prev}
              sx={{
                fontSize: '20px',
                transform: 'rotate(180deg)',
                cursor: 'pointer',
                color: bookMode === 'dark' ? 'var(--grey600)' : 'white',
              }}
            />
          )}

          <h1
            style={{
              color: bookName === 'Mindful Movement' ? 'var(--spurple)' : '',
            }}>
            {name}
          </h1>
          {page === length - 1 ? (
            ''
          ) : (
            // <img
            //   src={arrow}
            //   onClick={() => setPage(page + 1)}
            //   className="booksLevels__rarrow"
            //   alt="arrow"
            // />
            <PlayArrowIcon
              onClick={next}
              sx={{
                fontSize: '20px',
                cursor: 'pointer',
                color: bookMode === 'dark' ? 'var(--grey600)' : 'white',
              }}
            />
          )}
        </div>

        <img
          src={bookTheme?.divider}
          className="levelresponsive__img  "
          alt="mainArrow"
        />
      </div>
      <div className="booksLeveldetails">
        <img
          src={bookTheme?.subDivider}
          className="booksLevels_sepratorarrow levelresponsive__img"
          alt="sepratorArrow"
        />

        {levelLoading || levelFetching || levelId === '' ? (
          <ProgressBar />
        ) : (
          levelData &&
          levelData?.data.tasks.map((task, index) => {
            return (
              <LevelTask
                bookTheme={bookTheme}
                bookId={bookId}
                refetch={refetch}
                levelId={levelId}
                index={index}
                task={task}
                bookName={bookName}
                levelName={levelData?.data.name}
              />
            )
          })
        )}
        <div
          style={{ justifyContent: 'flex-end', marginTop: '10px' }}
          className="course__actions">
          {page === 0 ? (
            <span className="course__prevDA">
              <KeyboardArrowLeftOutlinedIcon
                sx={{
                  fontSize: '25px',
                  color: 'var(--grey200)',
                  // '&:hover': {
                  //   color: 'white',
                  // },
                }}
              />
            </span>
          ) : (
            <span className="course__prev">
              <KeyboardArrowLeftOutlinedIcon
                onClick={prev}
                sx={{
                  fontSize: '25px',
                  color: 'var(--pblue)',
                  '&:hover': {
                    color: 'white',
                  },
                }}
              />
            </span>
          )}

          {page === length - 1 ? (
            <span className="course__nextDA">
              <KeyboardArrowRightOutlinedIcon
                sx={{
                  fontSize: '25px',
                  color: 'var(--grey100)',
                  // '&:hover': {
                  //   color: 'white',
                  // },
                }}
              />
            </span>
          ) : (
            <span className="course__next">
              <KeyboardArrowRightOutlinedIcon
                onClick={next}
                sx={{
                  fontSize: '25px',
                  color: 'var(--pblue)',
                  '&:hover': {
                    color: 'white',
                  },
                }}
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
};
