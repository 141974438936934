import { apiSlice } from '../../api/apiSlice';

export const ownerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addOwner: builder.mutation({
      query: (data) => ({
        url: '/api/organizations',
        method: 'POST',
        body: data,
      }),
    }),
    getAllOwners: builder.query({
      query: ({ query }) => ({
        url: `/api/organizations/?query=${query}`,
        method: 'GET',
      }),
    }),
    getInternalOrgs: builder.query({
      query: () => ({
        url: `/api/organizations/internalOrgs`,
        method: 'GET',
      }),
    }),
    getOwnersById: builder.query({
      query: (orgId) => ({
        url: `/api/organizations/${orgId}`,
        method: 'GET',
      }),
    }),

    updateOwnersById: builder.mutation({
      query: (data) => ({
        url: `/api/organizations/${data.orgId}`,
        method: 'PATCH',
        body: data.formData,
      }),
    }),
    removeOwner: builder.mutation({
      query: (orgId) => ({
        url: `/api/organizations/${orgId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddOwnerMutation,
  useGetAllOwnersQuery,
  useGetOwnersByIdQuery,
  useGetInternalOrgsQuery,
  useUpdateOwnersByIdMutation,
  useRemoveOwnerMutation,
} = ownerApiSlice;
