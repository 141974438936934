import { CallMissedOutgoingOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMarkSingleNotificationReadMutation } from "../../redux-toolkit/features/notifications/notificationsApiSlice";
import "./Notifications.css";

const NotificationMessageBlock = ({ notification, refetchData, onClick }) => {
  const navigate = useNavigate();

  const [markSeen] = useMarkSingleNotificationReadMutation();

  return (
    <>
      <button
        className={`notifyBlock__btn ${
          !notification?.hasSeen ? "notifyBlock__btn_unseen" : ""
        }`}
        onClick={() => {
          markSeen(notification?._id)
            .unwrap()
            .then(() => {
              refetchData && refetchData();
            });
          onClick && onClick(notification);

          if (notification?.payload?.metadata?.redirectUrl) {
            navigate(notification?.payload?.metadata?.redirectUrl);
          }
        }}
      >
        <Stack direction="column" spacing={1.5}>
          <Stack
            className="notifyMB__message"
            direction="row"
            justifyContent="space-between"
          >
            <caption style={{ textAlign: "left" }}>
              {notification?.payload?.content}
            </caption>
            {notification?.payload?.metadata?.redirectUrl &&
            !notification?.hasSeen ? (
              <div className="notifyMB__badge">
                <CallMissedOutgoingOutlined
                  sx={{ fontSize: "12px" }}
                  color="primary"
                />
              </div>
            ) : null}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <div className="notifyMB__time">
              <p>
                {Intl.DateTimeFormat("en-US", {
                  weekday: "short",
                }).format(new Date(notification?.createdAt))}{" "}
                {Intl.DateTimeFormat("en-US", {
                  timeStyle: "short",
                }).format(new Date(notification?.createdAt))}
              </p>
            </div>
            <div className="notifyMB__day">
              <p>
                {Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "2-digit",
                }).format(new Date(notification?.createdAt))}
                {", "}
                {Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                }).format(new Date(notification?.createdAt))}
              </p>
            </div>
          </Stack>
        </Stack>
      </button>
    </>
  );
};

export default NotificationMessageBlock;
