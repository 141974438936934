import React, { useRef, useState } from "react";
import "./Departments.css";
import { Button } from "@mui/material";
import { AddDepartment } from "./AddDepartment";
import ProgressBar from "../../../UI/ProgressBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  useGetAllDepartmentsQuery,
  useDeleteDepartmentMutation,
  useUpdateDepartmentMutation,
} from "../../../../redux-toolkit/features/department/departmentApiSlice";
import DepDrawer from "./DepDrawer";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../../UI/Delete Modal/DeleteModal";
import { useAlert } from "../../../../hooks/useAlert";
import { Error404 } from "../../../UI/404/Error404";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";

let image =
  "https://images.unsplash.com/photo-1589251204996-3367cc27f084?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1155&q=80";

export const Departments = () => {
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const ref = useRef(null);
  const { data, isLoading, isError } = useGetAllDepartmentsQuery();
  const [deleteDepartment, { isSuccess }] = useDeleteDepartmentMutation();
  const [drawer, setDrawer] = React.useState(0);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { displayAlert } = useAlert();
  const [depId, setDepId] = useState("");
  const [hod, setHod] = React.useState();
  const [name, setName] = React.useState();
  const handleOpen = () => {
    setDepartmentOpen(true);
  };
  useOutsideClick(ref, () => {
    setDrawer(0);
  });
  const viewBooksHandler = (departmentId) => {
    navigate(
      `/super-admin/manage-organization/departments/books/${departmentId}`
    );
  };

  if (isLoading) {
    return <ProgressBar />;
  }
  if (isError) {
    return <Error404 />;
  }

  const deleteDepHandler = () => {
    deleteDepartment(depId)
      .unwrap()
      .then(() => {
        displayAlert({
          open: true,
          message: `Department Deleted Successfully!`,
          type: "success",
          timeout: "2000",
        });
      })
      .catch((err) => {
        displayAlert({
          open: true,
          message: `${err?.data?.message}`,
          type: "error",
          timeout: "2000",
        });
        setDepId("");
      });
  };
  const editDepHandler = () => {
    handleOpen();
  };

  console.log(depId)

  return (
    <div className="container">
      <div className="departments__actions">
        <Button variant="contained" onClick={()=>{
          handleOpen()
          setName("")
          setDepId("")
        }}>
          Add Department
        </Button>
        <AddDepartment
          setDrawer={setDrawer}
          departmentOpen={departmentOpen}
          setDepartmentOpen={setDepartmentOpen}
          departmentId={depId}
          setName={setName}
          hod={hod}
          name={name}
          setHod={setHod}
        />
        <DeleteModal
          label={"Department"}
          deleteHandler={() => deleteDepHandler()}
          setDeleteOpen={setDeleteOpen}
          deleteOpen={deleteOpen}
        />
      </div>
      <div className="departments">
        {data &&
          data?.data.departments.map((elm, index) => (
            <div key={index} className="departments__cards">
              <div className="departments__more">
                {drawer != elm._id && (
                  <MoreVertIcon
                    onClick={() => setDrawer(elm._id)}
                    sx={{
                      color: "white",
                      fontSize: "20px",
                      margin: "5px",
                      padding: "2px",
                      background: "rgba(68, 68, 68, 0.4)",
                      borderRadius: "5px",
                    }}
                  />
                )}
                {drawer === elm._id && (
                  <div ref={ref}>
                    <DepDrawer
                      editDepHandler={editDepHandler}
                      index={elm._id}
                      setDrawer={setDrawer}
                      setDeleteOpen={setDeleteOpen}
                      setDepId={setDepId}
                    />
                  </div>
                )}
              </div>
              <img
                onClick={() => setDrawer(0)}
                src={elm.coverImage || image}
                alt=""
              />
              <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
                {elm.name}
              </h6>
              <div className="departments__cardsActions">
                <button
                  onClick={() => viewBooksHandler(elm._id)}
                  className="contained__deplgbtn"
                >
                  View All Books
                </button>
              </div>
            </div>
          ))}
        <div onClick={()=>{
          handleOpen()
          // setHod("")
          setName("")
          setDepId("")
        }} className="departments__cards">
          <div className="departments__addDept">
            <AddRoundedIcon
              sx={{ fontSize: "70px", color: "var(--grey400)" }}
            />
          </div>
          <h6 style={{ fontSize: "14px", fontWeight: "500" }}>
            Add Department
          </h6>
          <div className="departments__addDepth6"></div>
        </div>
      </div>
    </div>
  );
};
