import { apiSlice } from "../../api/apiSlice";

export const coursesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssigned: builder.query({
      query: (_id) => ({
        url: `api/lms/course/user/${_id}/enroll`,
        method: 'GET',
      }),
    }),
    getBookmarks: builder.query({
      query: (_id) => ({
        url: `/api/lms/course/user/${_id}/bookmark`,
        method: 'GET',
      }),
    }),
    getCoursesByDept: builder.query({
      query: (_id) => ({
        url: `/api/lms/course/department/${_id}`,
        method: 'GET',
        providesTags: ['Course'],
      }),
    }),
    getCourseById: builder.query({
      query: (_id) => ({
        url: `/api/lms/course/${_id}?source=detailed`,
        method: 'GET',
        providesTags: ['CourseDetail'],
      }),
    }),
    getQuestCourseById: builder.query({
      query: (_id) => ({
        url: `/api/lms/course/${_id}`,
        method: 'GET',
      }),
    }),
    createCourse: builder.mutation({
      query: (data) => ({
        url: `/api/lms/course`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Course'],
    }),
    updateCourse: builder.mutation({
      query: ({ formData, _id }) => ({
        url: `/api/lms/course/${_id}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: ['Course'],
    }),
    deleteCourse: builder.mutation({
      query: (courseId) => ({
        url: `/api/lms/course/${courseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }),
    createModule: builder.mutation({
      query: ({ data, courseId }) => ({
        url: `/api/lms/course/${courseId}/module`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    updateModule: builder.mutation({
      query: ({ data, moduleId }) => ({
        url: `/api/lms/module/${moduleId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    deleteModule: builder.mutation({
      query: (moduleId) => ({
        url: `/api/lms/module/${moduleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    createTopic: builder.mutation({
      query: ({ topic, courseId, moduleId }) => ({
        url: `/api/lms/course/${courseId}/module/${moduleId}/submodule`,
        method: 'POST',
        body: topic,
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    updateTopic: builder.mutation({
      query: ({ topic, topicId }) => ({
        url: `/api/lms/module/submodule/${topicId}`,
        method: 'PATCH',
        body: topic,
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    deleteTopic: builder.mutation({
      query: (topicId) => ({
        url: `/api/lms/module/submodule/${topicId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    updateCertificate: builder.mutation({
      query: ({ courseId, cer }) => ({
        url: `/api/lms/course/certify/${courseId}`,
        method: 'PATCH',
        body: cer,
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    updatePublish: builder.mutation({
      query: ({ courseId, pub }) => ({
        url: `/api/lms/course/publish/${courseId}`,
        method: 'PATCH',
        body: pub,
      }),
      invalidatesTags: ['CourseDetail'],
    }),
    markComplete: builder.mutation({
      query: ({ courseId, moduleId, topicId, bookDetails }) => ({
        url: `/api/lms/course/submodule/status/${courseId}/${moduleId}/${topicId}?status=complete`,
        method: 'POST',
        body: { ...bookDetails },
      }),
    }),
    getCompleteCourseById: builder.query({
      query: (_id) => ({
        url: `/api/lms/course/${_id}`,
        method: 'GET',
        providesTags: ['CourseDetail'],
      }),
    }),
    getSubmoduleById: builder.query({
      query: (submoduleId) => ({
        url: `/api/lms/module/submodule/${submoduleId}`,
        method: 'GET',
        providesTags: ['CourseDetail'],
      }),
    }),
    attemptQuiz: builder.mutation({
      query:({userResponses,quizId})=>({
        url: `/api/lms/course/quiz/${quizId}`,
        method: 'POST',
        body:{userResponses}
      })
    }),
    createCourseAssignment: builder.mutation({
      query:(courseId)=>({
        url: `/api/lms/course/${courseId}/assign`,
        method: 'POST',
      })
    }),
    uploadRTEImage: builder.mutation({
      query: (file) => {
        let formData = new FormData();
        formData.append('file', file);
        return {
          url: `/api/upload/file`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    uploadFile: builder.mutation({
      query: (file) => {
        let formData = new FormData();
        formData.append('file', file);
        return {
          url: `/api/upload/file`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    deleteFile: builder.mutation({
      query: (file) => {
        return {
          url: `/api/delete/file`,
          method: 'DELETE',
          body: file,
        };
      },
    }),
  }),
});

export const {
  useDeleteFileMutation,
  useUploadFileMutation,
  useGetAssignedQuery,
  useGetCourseByIdQuery,
  useGetBookmarksQuery,
  useCreateCourseMutation,
  useCreateModuleMutation,
  useCreateTopicMutation,
  useGetCoursesByDeptQuery,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
  useDeleteModuleMutation,
  useUpdateModuleMutation,
  useDeleteTopicMutation,
  useUpdateTopicMutation,
  useUpdatePublishMutation,
  useUpdateCertificateMutation,
  useGetQuestCourseByIdQuery,
  useMarkCompleteMutation,
  useGetCompleteCourseByIdQuery,
  useGetSubmoduleByIdQuery,
  useAttemptQuizMutation,
  useCreateCourseAssignmentMutation,
  useUploadRTEImageMutation,
} = coursesApiSlice;
