import { apiSlice } from "../../api/apiSlice";

export const myActivityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserActivityStats: builder.query({
      query: (userId) => ({
        url: `/api/badges/user/activity-stats/${userId}`,
        method: "GET",
      }),
    }),
    getUserTaskBasedBadges: builder.query({
      query: (userId) => ({
        url: `/api/badges/user/get-task-badges/${userId}`,
        method: "GET",
      }),
    }),
    getUserPointBasedBadges: builder.query({
      query: (userId) => ({
        url: `/api/badges/user/get-point-badges/${userId}`,
        method: "GET",
      }),
    }),
    getCompletedCourses: builder.query({
      query: () => ({
        url: `/api/user/courses/completed`,
        method: "GET",
      }),
    }),
    getBadges: builder.query({
      query:()=>({
        url:'/api/badges',
        method:"GET"
      })
    })
  }),
});

export const {
  useGetUserActivityStatsQuery,
  useGetUserTaskBasedBadgesQuery,
  useGetUserPointBasedBadgesQuery,
  useGetCompletedCoursesQuery,
  useGetBadgesQuery
} = myActivityApiSlice;
