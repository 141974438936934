import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useAlert } from "../../../../hooks/useAlert";
import "./AddDepartment.css";
import {
  useCreateDepartmentMutation,
  useGetAllUsersDeptQuery,
  useGetDepartmentByIdQuery,
  useUpdateDepartmentMutation,
} from "../../../../redux-toolkit/features/department/departmentApiSlice";
import ProgressBar from "../../../UI/ProgressBar";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: " 40px",
};

export const AddDepartment = ({
  setDepartmentOpen,
  departmentOpen,
  departmentId,
  setDrawer,
  setName,
  name,
  setHod,
  hod,
}) => {
  const { displayAlert } = useAlert();
  // const [hod, setHod] = React.useState();
  // const [name, setName] = React.useState();
  const [createDepartment] = useCreateDepartmentMutation();
  const { data: users, isLoading, isError } = useGetAllUsersDeptQuery();
  const {
    data,
    refetch,
    isLoading: loadingDep,
    isFetching,
  } = useGetDepartmentByIdQuery({departmentId}, {
    // skip: departmentId === 0,
    refetchOnMountOrArgChange: true,
  });
  const [updateDepartment] = useUpdateDepartmentMutation();
  const [thumbnail, setThumbnail] = React.useState();
  const [isLarge, setIsLarge] = React.useState(false);
  const [loading, setLoading] = React.useState(true)


  const handleClose = () => {
    setDrawer(0);
    setDepartmentOpen(false);
    // setThumbnail();
  };
  React.useEffect(() => {
    setLoading(true)
    if (departmentId === "") {
      setThumbnail()
      setHod();
      setName();
      setTimeout(()=>{
        setLoading(false)
      },500)
    }
  }, [departmentId]);

  React.useEffect(() => {
    if (departmentId != 0 && users) {
      const finalHod = users.data?.users.list.filter(
        (elm) => elm._id === data?.data.hod
      )[0];
      setHod(finalHod);
      setName(data?.data.name);
      setThumbnail(data?.data.coverImage);
      setLoading(false)
    }
  }, [data, departmentId]);

  const updateHandler = async () => {
    if (!name || !thumbnail) {
      displayAlert({
        open: true,
        message: `All fields required!`,
        type: "error",
        timeout: "2000",
      });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      hod && formData.append("hod", hod?._id);
      formData.append("coverImage", thumbnail);
      const final = { formData, departmentId };

      await updateDepartment(final)
        .unwrap()
        .then(() => {
          displayAlert({
            open: true,
            message: `Updated Department Successfully!`,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          displayAlert({
            open: true,
            message: `${err?.data?.message}`,
            type: "error",
          });
        });

      handleClose();
    }
  };

  const submitHandler = async () => {
    if (!name || !thumbnail) {
      displayAlert({
        open: true,
        message: `All fields required!`,
        type: "error",
        timeout: "2000",
      });
    } else {
      // const final = { name: name, hod: hod?._id };

      const formData = new FormData();
      formData.append("name", name);
      hod && formData.append("hod", hod?._id);
      formData.append("coverImage", thumbnail);

      await createDepartment(formData);
      await displayAlert({
        open: true,
        message: `New Department Added Successfully!`,
        type: "success",
        timeout: "2000",
      });
      setHod();
      setName();
      setThumbnail();
      handleClose();
    }
  };
  const clearhandler = () => {
    setName("");
  };

  // React.useEffect(() => {}, [name, hod]);

  const thumbnailHandler = (event) => {
    const file = event.target.files[0];

    if (file.size >= 1099276) {
      setIsLarge(true);

      displayAlert({
        open: true,
        message: `Image Size cannot be Larger than 1 MB`,
        type: "error",
        timeout: "2000",
      });
    } else {
      setIsLarge(false);
      setThumbnail(file);
    }
  };


  return (
    <div>
      <Modal
        open={departmentOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {departmentId != 0 ? (
          <>
            {users ? (
              <Box sx={style}>
                <div className="create__formhead">
                  <h1>Add Department</h1>
                  <CloseIcon
                    onClick={handleClose}
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "var(--grey400)",
                      "&:hover": {
                        color: "var(--error)",
                      },
                    }}
                  />
                </div>
                <div className="seprator seprator__gap"></div>
                <div className="department__form">
                  <div>
                    <h5 className="ftwt__r">
                      Department Name <span>*</span>
                    </h5>

                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginTop: "5px",
                      }}
                      required
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <h5 className="ftwt__r">
                      Display Image <span>*</span>
                    </h5>
                    <div
                      style={{ borderColor: isLarge ? "var(--error)" : "" }}
                      className="dropzone-wrapper"
                    >
                      {thumbnail ? (
                        <div className="dropzone-image">
                          <img
                            height={"100%"}
                            src={
                              !thumbnail.type
                                ? data?.data?.coverImage
                                : URL.createObjectURL(thumbnail)
                            }
                            alt="Thumbnail Preview"
                          />
                          <CloseIcon
                            onClick={() => setThumbnail()}
                            sx={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: "var(--grey400)",
                              "&:hover": {
                                color: "var(--error)",
                              },
                            }}
                          />
                        </div>
                      ) : (
                        <div className="dropzone-desc">
                          <UploadFileOutlinedIcon sx={{ fontSize: "70px" }} />
                          <p
                            style={{
                              fontSize: "14px",
                              // color: isLarge ? 'var(--error)' : '',
                            }}
                          >
                            Choose an image file{" "}
                            <span style={{ fontSize: "11px" }}>
                              or drag it here.
                            </span>
                            <br />
                            <span style={{ fontSize: "11px" }}>
                              Maximum file size 1MB
                            </span>
                          </p>
                        </div>
                      )}
                      {!thumbnail && (
                        <input
                          name="thumbnail"
                          onChange={thumbnailHandler}
                          type="file"
                          accept="image/*"
                          className="dropzone"
                        />
                      )}
                    </div>
                    {isLarge && (
                      <p
                        style={{
                          color: "var(--error)",
                          marginTop: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Choose Image smaller than 1MB
                      </p>
                    )}
                  </div>
                  <div>
                    <h5 className="ftwt__r">Head Of Department</h5>

                    <Autocomplete
                      id="disable-clearable"
                      disableClearable
                      options={users ? users?.data?.users.list : []}
                      getOptionSelected={(option, value) =>
                        option._id == value._id
                      }
                      getOptionLabel={(option) => option?.email}
                      fullWidth
                      onChange={(event, newValue) => {
                        setHod(newValue);
                      }}
                      name="headOfDepartment"
                      value={hod}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            marginTop: "5px",
                            borderRadius: "2px",
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    // sx={{
                    //   padding: "0",
                    //   minWidth: "230px",
                    //   maxWidth: "350px",
                    //   // backgroundColor: "white",
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     border: "#fff",
                    //     // backgroundColor: "white",
                    //     borderRadius: "10px",
                    //   },
                    // }}
                    />
                  </div>
                  <div className="department__actions">
                    {/* <div onClick={clearhandler} className="figure">
                      <img
                        src={DeleteInActiveSvg}
                        className="Sirv image-main"
                        height="22px"
                        alt=""
                      />
                      <img
                        src={DeleteActiveSvg}
                        className="Sirv image-hover"
                        height="22px"
                        alt=""
                      />
                    </div> */}
                    {departmentId === 0 ? (
                      <Button
                        onClick={submitHandler}
                        sx={{ width: "100px" }}
                        variant="contained"
                      >
                        Create
                      </Button>
                    ) : (
                      <Button
                        onClick={updateHandler}
                        sx={{ width: "100px" }}
                        variant="contained"
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </div>
              </Box>
            ) : (
              <Box sx={style}>
                <ProgressBar />
              </Box>
            )}
          </>
        ) : loading ? (<Box sx={style}>
          <ProgressBar />
        </Box>) : (
          <Box sx={style}>
            <div className="create__formhead">
              <h1>Add Department</h1>
              <CloseIcon
                onClick={handleClose}
                sx={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "var(--grey400)",
                  "&:hover": {
                    color: "var(--error)",
                  },
                }}
              />
            </div>
            <div className="seprator seprator__gap"></div>
            <div className="department__form">
              <div>
                <h5 className="ftwt__r">
                  Department Name <span>*</span>
                </h5>

                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginTop: "5px",
                  }}
                  required
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <h5 className="ftwt__r">
                  Display Image <span>*</span>
                </h5>
                <div
                  style={{ borderColor: isLarge ? "var(--error)" : "" }}
                  className="dropzone-wrapper"
                >
                  {thumbnail ? (
                    <div className="dropzone-image">
                      <img
                        height={"100%"}
                        src={
                          !thumbnail.type
                            ? data?.data?.coverImage
                            : URL.createObjectURL(thumbnail)
                        }
                        alt="Thumbnail Preview"
                      />
                      <CloseIcon
                        onClick={() => setThumbnail()}
                        sx={{
                          fontSize: "18px",
                          cursor: "pointer",
                          color: "var(--grey400)",
                          "&:hover": {
                            color: "var(--error)",
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className="dropzone-desc">
                      <UploadFileOutlinedIcon sx={{ fontSize: "70px" }} />
                      <p
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        Choose an image file{" "}
                        <span
                          style={{ fontSize: "11px", color: "var(--grey400)" }}
                        >
                          or drag it here.
                        </span>
                        <br />
                        <span
                          style={{ fontSize: "11px", color: "var(--grey400)" }}
                        >
                          Maximum file size 1MB
                        </span>
                      </p>
                    </div>
                  )}
                  {!thumbnail && (
                    <input
                      name="thumbnail"
                      onChange={thumbnailHandler}
                      type="file"
                      accept="image/*"
                      className="dropzone"
                    />
                  )}
                </div>
                {isLarge && (
                  <p
                    style={{
                      color: "var(--error)",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Choose Image smaller than 1MB
                  </p>
                )}
              </div>
              <div>
                <h5 className="ftwt__r">Head Of Department</h5>
                <Autocomplete
                  id="navbar-search"
                  options={users ? users?.data?.users.list : []}
                  getOptionSelected={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.email}
                  fullWidth
                  onChange={(event, newValue) => {
                    setHod(newValue);
                  }}
                  name="headOfDepartment"
                  value={hod}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginTop: "5px",
                        borderRadius: "2px",
                      }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                // sx={{
                //   padding: "0",
                //   minWidth: "230px",
                //   maxWidth: "350px",
                //   // backgroundColor: "white",
                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                //     border: "#fff",
                //     // backgroundColor: "white",
                //     borderRadius: "10px",
                //   },
                // }}
                />
              </div>
              <div className="department__actions">
                {/* <div onClick={clearhandler} className="figure">
                  <img
                    src={DeleteInActiveSvg}
                    className="Sirv image-main"
                    height="22px"
                    alt=""
                  />
                  <img
                    src={DeleteActiveSvg}
                    className="Sirv image-hover"
                    height="22px"
                    alt=""
                  />
                </div> */}
                {departmentId === 0 ? (
                  <Button
                    onClick={submitHandler}
                    sx={{ width: "100px" }}
                    variant="contained"
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    onClick={updateHandler}
                    sx={{ width: "100px" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
              </div>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};
