import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import { ReactComponent as NotificationInActiveSvg } from "../../assets/Icons/profile/Notification _ Profile _ normal state.svg";
import logoDark from "../../assets/LOGO.png";
import logo from "../../assets/White logo.svg";
import { Profile } from "../Profile/Profile";
import "./Navbar.css";
import CloseIcon from "@mui/icons-material/Close";
import ScratchCard from "react-scratchcard-v2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DefaultMaleProfilePic from "../../assets/Icons/profile/Profile icon _ male.png";
import {
  useGetUserNotificationsCountQuery,
  useGetUserNotificationsQuery,
} from "../../redux-toolkit/features/notifications/notificationsApiSlice";
import { useGetPointsQuery } from "../../redux-toolkit/features/points/pointsApiSlice";
import { useGetInitialAppliedThemeQuery } from "../../redux-toolkit/features/theme/themeApiSlice";
import {
  selectedTheme,
  setStep,
  setTourActive,
} from "../../redux-toolkit/features/theme/themeSlice";
import setupSocket from "../../utils/socket";
import NotificationBody from "../Notifications/NotificationBody";
import { ReactComponent as RewardsSvg } from "../../assets/Navbar icons/rewards_outlined.svg";
import { ReactComponent as RewardsSvgH } from "../../assets/Navbar icons/rewards_filled.svg";
import { ReactComponent as PointsSvg } from "../../assets/Navbar icons/my activity.svg";
import { ReactComponent as PointsSvgH } from "../../assets/Navbar icons/my activity_filled.svg";
import { ReactComponent as QoinsSvgH } from "../../assets/Navbar icons/Qoins_logo.svg";
import img from "../../assets/scratch_card.png";

import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import Confetti from "react-confetti";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  overflow: "hidden",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: "0",
};

export const Navbar = ({ isBook, isAdmin, bookMode, isPreview }) => {
  const socket = setupSocket();
  const [congratsNotification, setCongratsNotification] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [notificationType, setNotificationType] = useState("all");
  const { pathname } = useLocation();
  const { data: notifications, refetch: refetchNotifications } =
    useGetUserNotificationsQuery(notificationType, {
      refetchOnMountOrArgChange: true,
    });
  const { data: unseenNotificationCount, refetch: refetchUnseenNotifications } =
    useGetUserNotificationsCountQuery(null, {
      refetchOnMountOrArgChange: true,
    });
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [notificationToggle, setNotificationToggle] = useState(false);
  const theme = useSelector(selectedTheme);

  const { data, isLoading, isError } = useGetInitialAppliedThemeQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: pointsData } = useGetPointsQuery({
    refetchOnMountOrArgChange: true,
  });

  const [notificationCount, setNotificationCount] = useState(0);
  const user = useSelector((state) => state.auth.user);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!isPreview) {
      setState(!state);
      setNotificationToggle(false);
    }
    if (!isPreview && !open) {
      dispatch(setTourActive(false));
      dispatch(setStep([]));
    }
  };

  const handleNotificationDataRefetch = () => {
    refetchNotifications();
    refetchUnseenNotifications();
  };

  const handleNotificationDrawer = () => {
    if (!isPreview) {
      setNotificationToggle(!notificationToggle);
      setState(false);
    }
    toggleDrawer("top", false);
    handleNotificationDataRefetch();
  };

  const handleNotificationDrawerClose = () => {
    setNotificationToggle(false);
  };

  useEffect(() => {
    setNotificationCount(unseenNotificationCount?.data || 0);
  }, [unseenNotificationCount]);

  useEffect(() => {
    socket?.on("taskApproval", (payload) => {
      if (payload[0]?.owner === user?._id) {
        setNotificationCount((prev) => prev + (payload?.length || 1));
      }
    });

    socket?.on("taskRejection", (payload) => {
      if (payload?.owner === user?._id) {
        setNotificationCount((prev) => prev + 1);
      }
    });
    socket?.on("permissionChange", (payload) => {
      if (payload?.owner === user?._id) {
        setNotificationCount((prev) => prev + 1);
      }
    });
    socket?.on("bookUnlocked", (payload) => {
      if (payload?.owner === user?._id) {
        setNotificationCount((prev) => prev + 1);
      }
    });
    socket?.on("departmentalBookCreate", (payload) => {
      setNotificationCount((prev) => prev + 1);
    });
    socket?.on("departmentalTaskSubmission", (payload) => {
      setNotificationCount((prev) => prev + 1);
    });

    socket?.on("rewardApproval", (payload) => {
      setNotificationCount((prev) => prev + 1);
    });

    socket?.on("rewardDispatched", (payload) => {
      setNotificationCount((prev) => prev + 1);
    });

    socket?.on("badgeCongratulate", (payload) => {
      if (payload?.owner === user?._id) {
        setNotificationCount((prev) => prev + (payload?.length || 1));
      }
    });

    return () => {
      socket?.off("taskApproval");
      socket?.off("taskRejection");
      socket?.off("bookUnlocked");
      socket?.off("permissionChange");
      socket?.off("departmentalBookCreate");
      socket?.off("departmentalTaskSubmission");
      socket?.off("rewardApproval");
      socket?.off("badgeCongratulate");
      socket?.off("rewardDispatched");
    };
  }, [socket]);
  return (
    <>
      {showConfetti ? (
        <Confetti
          width={550}
          gravity={0.4}
          height={window?.innerHeight}
          numberOfPieces={600}
          recycle={false}
          style={{
            margin: "0 auto",
            zIndex: 10000,
            position: "fixed",
            top: 0,
          }}
        />
      ) : null}
      <Modal
        open={!!congratsNotification}
        onClose={() => {
          setShowConfetti(false);
          setCongratsNotification(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div
            style={{
              position: "absolute",
              right: "16px",
              top: "16px",
              zIndex: 100,
            }}>
            <CloseIcon
              onClick={() => {
                setShowConfetti(false);
                setCongratsNotification(null);
              }}
              sx={{
                fontSize: "20px",
                cursor: "pointer",
                color: "var(--grey400)",
                "&:hover": {
                  color: "var(--error)",
                },
              }}
            />
          </div>
          <ScratchCard
            width={350}
            height={440}
            image={img}
            finishPercent={30}
            onComplete={() => setShowConfetti(true)}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "20px",
              }}>
              <h1
                style={{
                  fontFamily: "Alex Brush, cursive",
                  fontSize: "50px",
                  color: "#ffbe18",
                  textAlign: "center",
                  marginTop: "-30px",
                }}>
                Congratulations!
              </h1>
              <p style={{ textAlign: "center", paddingTop: "20px" }}>
                <span style={{ color: "#3661cf", fontWeight: "bold" }}>
                  {congratsNotification?.payload?.content?.split("is")[0]}
                </span>{" "}
                is
                {congratsNotification?.payload?.content?.split("is")[1]}
              </p>{" "}
            </div>
          </ScratchCard>
        </Box>
      </Modal>

      <div className="nav">
        <Grid
          container
          sx={{ width: "100%", height: "100%", padding: "0px 30px " }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid
            item
            sx={{ width: "15vw", display: "flex", justifyContent: "center" }}>
            <Link to={"/quest"}>
              {isBook ? (
                <img
                  src={bookMode === "dark" ? logoDark : logo}
                  width="160px"
                  alt=""
                />
              ) : (
                <img
                  src={data?.data.mode === "dark" ? logoDark : logo}
                  width="160px"
                  alt=""
                />
              )}
            </Link>
          </Grid>

          <Grid item>
            <Grid
              container
              spacing={{
                md: 1,
                xl: 2,
              }}></Grid>
          </Grid>

          <Grid item>
            <div className="nav__right">
              <div className="nav__icons">
                <div
                  id="step23"
                  // onMouseOut={() => setIsWallet(false)}
                  // onMouseOver={() => setIsWallet(true)}
                  className="nav__iconsbg">
                  {!pathname.includes("super-admin") &&
                    !pathname.includes("/admin") && (
                      <Link to={"/quest/rewards"}>
                        <div className="nav__Icons1">
                          <RewardsSvgH style={{ width: 35, height: 35 }} />

                          <div className="nav__IconsH1">
                            <RewardsSvg style={{ width: 35, height: 35 }} />
                          </div>
                        </div>
                      </Link>
                    )}

                  {true && (
                    <div className="nav__icontext">
                      <div className="comment-box">
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}>
                          <QoinsSvgH style={{ width: "40px" }} />:
                          <span style={{ color: "var(--pblue)" }}>
                            &nbsp;{pointsData && pointsData?.data?.walletPoints}
                          </span>
                        </div>

                        <Link to={"/quest/rewards"}>
                          <Button variant="contained">Rewards</Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  id="step24"
                  // onMouseOver={() => setIsPoint(true)}
                  // onMouseOut={() => setIsPoint(false)}
                  className="nav__iconsbg">
                  {!pathname.includes("super-admin") &&
                    !pathname.includes("admin") && (
                      <Link to={"/quest/my-activity"}>
                        <div className="nav__Icons1">
                          <PointsSvgH style={{ width: 35, height: 35 }} />

                          <div className="nav__IconsH1">
                            <PointsSvg style={{ width: 35, height: 35 }} />
                          </div>
                        </div>
                      </Link>
                    )}
                  {true && (
                    <div className="nav__icontext">
                      <div className="comment-box">
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}>
                          Total Points:
                          <span style={{ color: "var(--pblue)" }}>
                            &nbsp;{pointsData && pointsData?.data?.earnedPoints}{" "}
                          </span>
                        </div>
                        <Link to={"/quest/my-activity"}>
                          <Button variant="contained">My Activity</Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!pathname.includes("super-admin") &&
                !pathname.includes("admin") && (
                  <div
                    style={{
                      height: "30px",
                      width: "1px",
                      backgroundColor:
                        data?.data.mode === "dark" ? "var(--grey600)" : "white",
                    }}></div>
                )}
              <div className="nav__profile">
                <div className="step25">
                  <div
                    className="nav__notifyiconsbg"
                    onClick={handleNotificationDrawer}>
                    {notificationCount > 0 ? (
                      <span className="nav__notif_count">
                        {notificationCount > 9 ? "9+" : notificationCount}
                      </span>
                    ) : null}
                    <NotificationInActiveSvg
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <div
                      className="nav__profileControls"
                      onClick={toggleDrawer("top", true)}>
                      <div className="nav__profilepic">
                        {user?.avatar && (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: "35px", height: "35px" }}
                            src={
                              user?.avatar != null
                                ? user?.avatar
                                : DefaultMaleProfilePic
                            }
                          />
                        )}
                      </div>
                      <div className="avatar__arrow">
                        {isBook ? (
                          <ArrowBackIosIcon
                            sx={{
                              color:
                                bookMode === "dark"
                                  ? "var(--grey600)"
                                  : "white",
                              fontSize: "20px",
                            }}
                          />
                        ) : (
                          <ArrowBackIosIcon
                            sx={{
                              color:
                                data?.data.mode === "dark"
                                  ? "var(--grey600)"
                                  : "white",
                              fontSize: "20px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <Drawer
                      anchor={"top"}
                      sx={{
                        backgroundColor: "transparent",
                      }}
                      BackdropProps={{ invisible: true }}
                      PaperProps={{
                        sx: {
                          margin: "70px 33px 0px 0px",
                          marginLeft: "auto",
                          padding: "0px 10px",
                          width: "280px",
                          // zIndex: '9999 !important',
                          backgroundColor: theme
                            ? "var(--slightgrey)"
                            : "#040404",
                          border: "1px solid var(--grey200)",
                          borderRadius: "0 0 10px 10px",
                          borderTop: "0px",
                          boxShadow: "0 16px 32px rgba(0,0,0,0.07)",
                        },
                      }}
                      open={state}
                      onClose={toggleDrawer("top", false)}>
                      <Profile toggleDrawer={toggleDrawer} theme={theme} />
                    </Drawer>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Drawer
          anchor={"top"}
          sx={{
            backgroundColor: "transparent",
          }}
          BackdropProps={{ invisible: true }}
          PaperProps={{
            sx: {
              margin: "70px 120px 0px 0px",
              marginLeft: "auto",
              padding: "20px 20px 10px 20px",
              width: "450px",
              backgroundColor: "var(--slightgrey)",
              border: "1px solid var(--grey200)",
              borderRadius: "0 0 10px 10px",
              borderTop: "0px",
              boxShadow: "0 16px 32px rgba(0,0,0,0.07)",
            },
          }}
          open={notificationToggle}
          onClose={(_, reason) =>
            reason === "backdropClick" && handleNotificationDrawerClose()
          }>
          {notifications ? (
            <NotificationBody
              setNotificationType={setNotificationType}
              handleClose={handleNotificationDrawerClose}
              notifications={notifications?.data || []}
              refetchData={handleNotificationDataRefetch}
              onClick={(notification) => {
                if (
                  notification?.payload?.metadata?.action === "CONGRATULATIONS"
                ) {
                  setTimeout(() => {
                    setCongratsNotification(notification);
                  }, 1000);
                }
              }}
            />
          ) : null}
        </Drawer>
      </div>
    </>
  );
};
