import React, { useCallback, useState, useEffect } from 'react';
import './Owner.css';
import { Search } from '../UI/Search/Search';
import { debounce } from '../../utils';
import { Button } from '@mui/material';
import { BreadcrumbAdmin } from '../UI/BreadcrumbAdmin';
import {
  useGetAllOwnersQuery,
  useRemoveOwnerMutation,
  useGetInternalOrgsQuery
} from '../../redux-toolkit/features/owner/ownerApiSlice';
import ProgressBar from '../UI/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from '../UI/Delete Modal/DeleteModal';
import { useAlert } from '../../hooks/useAlert';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { OrgCard } from './OrgCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Owner = () => {
  const [query, setQuery] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [removeOwner] = useRemoveOwnerMutation();
  const [value, setValue] = useState(0)
  const [qgroup, setQGroup] = useState("")


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, isLoading, isError, refetch } = useGetAllOwnersQuery(
    { query },
    { refetchOnMountOrArgChange: true }
  );
  const { data: internalOrgs } = useGetInternalOrgsQuery();
  const displayAlert = useAlert();

  const navigate = useNavigate();
  const setDebouncedSearchQuery = useCallback(
    debounce((value) => {
      setQuery(value.trim());
    }, 500),
    []
  );

  const handleDeleteOpen = (_id) => {
    setDeleteOpen(true);
    setOrgId(_id);
  };
  const handleDeleteOwner = async () => {
    if (orgId) {
      await removeOwner(orgId)
        .unwrap()
        .then((res) => {
          displayAlert({
            open: true,
            message: res?.message || 'Successfully removed organization',
            type: 'success',
            timeout: '2000',
          });
        })
        .catch((err) => {
          displayAlert({
            open: true,
            message: err?.data?.message || `Something went wrong`,
            type: 'error',
            timeout: '2000',
          });
        });
    }
    refetch();
    setOrgId(null);
  };

  const base = [
    {
      _id: 1,
      link: '/',
      label: 'Quest',
    },
  ];

  const handleNavigateDetailed = (orgId) => {
    navigate(`/quest/owner/view-organization/${orgId}`);
  };

  const handleNavigateNewOrg = () => {
    navigate(`/quest/owner/new-organization`);
  };

  useEffect(() => {
    const qgroupid = data?.data?.filter((org) => org.companyName == 'QGroup')[0]?._id
    setQGroup(qgroupid)
  }, [data])

  return (
    <div className="container__accounts">
      <div className="owner">
        <BreadcrumbAdmin
          base={base}
          page={'Owner'}
        />
        <DeleteModal
          label={'Organization'}
          deleteHandler={handleDeleteOwner}
          setDeleteOpen={setDeleteOpen}
          deleteOpen={deleteOpen}
        />
        <div className="owner__head">
          <Search
            placehold={'Search by Name or Email'}
            setSearchVal={(val) => setDebouncedSearchQuery(val)}
          />
          <Button
            onClick={handleNavigateNewOrg}
            variant="contained">
            Add Organiation
          </Button>
        </div>
        <div>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab
                  label="All"
                  sx={{
                    textTransform: 'capitalize',
                    // marginRight: '20px',
                    fontWeight: '500',
                  }}
                  {...a11yProps(0)}
                />

                <Tab
                  sx={{ textTransform: 'capitalize', fontWeight: '500' }}
                  label="QGroup"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ textTransform: 'capitalize', fontWeight: '500' }}
                  label="External"
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{ textTransform: 'capitalize', fontWeight: '500' }}
                  label="Deactivated"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={value}
              index={0}>
              <div className="owner__cardLayout">
                {!isLoading ? (
                  data &&
                  data.data?.map((item) => (
                    <OrgCard
                      image={item.logo}
                      id={item._id}
                      name={item.companyName}
                      poc={item.contactPersonFname + " " + item.contactPersonLname}
                      handleDeleteOpen={handleDeleteOpen}
                      handleNavigateDetailed={handleNavigateDetailed}
                    />
                  ))
                ) : (
                  <ProgressBar />
                )}
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}>
              <div className='owner__cardLayout'>
                <div className='vieworg__details' style={{marginRight:"10px"}}>
                  <div className='vieworg__company'>
                    <h4 style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>Company Details</h4>
                    <div className='vieworg__companydetails'>
                      <div className='vieworg__companyleft'>
                        <p className='ftwt__b'>Company Logo</p>
                        <img src={data?.data?.filter((org) => org.companyName === 'QGroup')[0].logo} alt="" />
                      </div>
                      <div className='vieworg__companyright'>
                        <div className='vieworg__datapoint'>
                          <p className='ftwt__b'>Company Name</p>
                          <p className='ftwt__b'>Owner</p>
                          <p className='ftwt__b'>Domain email</p>
                        </div>
                        <div className='vieworg__datapoint'>
                          <p className='ftwt__r'>Qgroup</p>
                          <p className='ftwt__r'>Mikael Kretz</p>
                          <p className='ftwt__r'>www.qgroup.se</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='vieworg__contact'>
                    <h4>QGroup Companies</h4>
                    <div className='owner__internalOrgs' style={{padding:"20px 0px", gap:"20px"}}>
                      {internalOrgs?.data?.map((org) => (
                        <div className='vieworg__companydetails' style={{border:"1px solid #D9D9D9", margin:"0px 20px", borderRadius:"10px"}}>
                          <div className='vieworg__companyleft'>
                            <img src={org.coverImage} alt="" />
                          </div>
                          <div className='vieworg__companyright'>
                            <div className='vieworg__datapoint'>
                              <p className='ftwt__b'>Company Name</p>
                              <p className='ftwt__b'>Total Users</p>
                              <p className='ftwt__b'>Total Super Admins</p>
                              <p className='ftwt__b'>Total Admins</p>
                            </div>
                            <div className='vieworg__datapoint'>
                              <p className='ftwt__r'>{org.name}</p>
                              <p className='ftwt__r'>{org.totalUsers}</p>
                              <p className='ftwt__r'>{org.totalSuperAdmins}</p>
                              <p className='ftwt__r'>{org.totalAdmins}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={2}>
              <div className='owner__cardLayout'>
                {!isLoading ? (
                  data &&
                  data?.data?.filter((org) => org.companyName != "QGroup" && org.isActive).map((item) => (
                    <OrgCard
                      image={item.logo}
                      id={item._id}
                      name={item.companyName}
                      poc={item.contactPersonFname + " " + item.contactPersonLname}
                      handleDeleteOpen={handleDeleteOpen}
                      handleNavigateDetailed={handleNavigateDetailed}
                    />
                  ))
                ) : (
                  <ProgressBar />
                )}
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={3}>
              <div className='owner__cardLayout'>
                {!isLoading ? (
                  data &&
                  data?.data?.filter((org) => !org.isActive).map((item) => (
                    <OrgCard
                      image={item.logo}
                      id={item._id}
                      name={item.companyName}
                      poc={item.contactPersonFname + " " + item.contactPersonLname}
                      handleDeleteOpen={handleDeleteOpen}
                      handleNavigateDetailed={handleNavigateDetailed}
                    />
                  ))
                ) : (
                  <ProgressBar />
                )}
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
};

