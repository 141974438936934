import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./EditImages.css";
import { useGetBookThemesQuery } from '../../../../../redux-toolkit/features/books/bookApiSlice';
import ProgressBar from "../../../../UI/ProgressBar";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "50%",
    maxHeight: "50vh",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: "30px 30px",
};

export const EditImages = ({
    setOpen,
    open,
    label,
    setDataChanged,
    setTheme,
    setThumbnail
}) => {

    const { data, isBookThemeLoading } = useGetBookThemesQuery()
    const [selectItem, setSelectItem] = React.useState({})

    const handleClose = () => {
        if(label==='Template')setTheme({ bgImage: selectItem?.bgImage, divider: selectItem?.dividerImage, mode: selectItem?.mode, subDivider: selectItem?.taskDividerImage, previewImage: selectItem?.previewImage })
        else if(label==='Display Image') setThumbnail(selectItem?.url)
        setDataChanged(true)
        setOpen(false)
    }

    if(isBookThemeLoading)
        return <ProgressBar/>

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="editmodal">
                    <div className="editmodal__header">
                        <h3 className="ftwt__r">Edit {label}</h3>
                        <CloseIcon
                            onClick={handleClose}
                            sx={{
                                fontSize: "18px",
                                cursor: "pointer",
                                marginLeft: "auto",
                                color: "var(--grey400)",
                                "&:hover": {
                                    color: "var(--error)",
                                },
                            }}
                        />
                    </div>
                    <div className="seprator" />
                    {/* <h6 className="ftwt__r">Select {label} <span style={{ color: "red" }}>*</span></h6> */}
                    <div className="editmodal__options">
                        {label=="Template"?data?.data?.themes?.map((theme, index) => (
                            <div>
                                <img src={theme.previewImage} alt="" style={{ objectFit: "contain", height: "100px", borderRadius: "10px", border: index == selectItem?.index ? "2px solid var(--pblue)" : "1px solid transparent" }} onClick={() => { setSelectItem({ ...theme, index }) }} />
                                <h4>{theme.name}</h4>
                            </div>
                        )):data?.data?.logos?.map((logo, index) => (
                            <div>
                            <img src={logo.url} alt="" width="100px" height="100px" style={{ padding: "10px 30px",objectFit: "contain",border: index == selectItem?.index ? "2px solid var(--pblue)" : "1px solid #C3C3C3", borderRadius: "8px" }} onClick={() => setSelectItem({url:logo.url,index})} />
                            <h4>{logo.name}</h4>
                          </div>
                        ))}
                    </div>
                    <div className="editmodal__action">
                        <Button onClick={handleClose} variant="contained">
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};
