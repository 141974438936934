import LeaderboardTable from './LeaderboardTable/LeaderboardTable';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import rightArrow from '../../../assets/Icons/arrow.svg';
import { roleToggler } from '../../../redux-toolkit/features/access/accessSlice';
import DateRangePickerComp from '../../UI/DateRangePicker';
import './SaDashboard.css';
import { Graph } from '../../Admin/Dashboard/Charts/Graph';
import { QuestActivity } from '../../Admin/Dashboard/Quest Activity/QuestActivity';

import {
  useBadgeStatsQuery,
  useBookDataQuery,
  useUserStatsQuery,
  useBookStatsQuery,
} from '../../../redux-toolkit/features/dashboard/dashboardApliSlice';
import ProgressBar from '../../UI/ProgressBar';
import { useGetAllDepartmentsQuery } from '../../../redux-toolkit/features/department/departmentApiSlice';
import { setDepartment } from '../../../redux-toolkit/features/dashboard/dashboardSlice';
import { setActiveItem, setSelectBooks } from '../../../redux-toolkit/features/theme/themeSlice';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const SaDashboard = () => {
  const dispatch = useDispatch();
  const selectBooks = useSelector((state) => state.theme.selectBooks);
  const [openDepartmentSelect, setOpenDepartmentSelect] = React.useState();
  const [dateRange, setDateRange] = React.useState(null);
  React.useEffect(() => {
    dispatch(roleToggler('Admin'));
  }, []);
  const { departmentId } = useSelector((state) => state.auth.user);
  const { selectedDept } = useSelector((state) => state.dashboard);
  const [value, setValue] = React.useState(0);

  // Organizationals
  const { data: bookOrg, isLoading } = useBookDataQuery(
    { type: 'org', userType: 'superadmin' },
    { refetchOnMountOrArgChange: true }
  );

  const { data: badgeStatsOrg, isLoading: badgeStatsOrgLoading } =
    useBadgeStatsQuery(
      {
        userType: 'superadmin',
        type: 'org',
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: userStatsOrg, isLoading: userStatsOrgLoading } =
    useUserStatsQuery(
      {
        userType: 'superadmin',
        type: 'org',
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: bookStatsOrg, isLoading: bookStatsOrgLoading } =
    useBookStatsQuery(
      {
        bookId: Object.keys(selectBooks).length?selectBooks._id:bookOrg?.data[0]._id,
        userType: 'superadmin',
        type: 'org',
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: value != 0,
      }
    );
  //  Departmental
  const { data: bookDept, isLoading: deptLoading } = useBookDataQuery(
    {
      deptId: selectedDept?._id,
      type: 'dept',
      userType: 'superadmin',
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: badgeStatsDept, isLoading: badgeStatsDeptLoading } =
    useBadgeStatsQuery(
      {
        userType: 'superadmin',
        type: 'dept',
        deptId: selectedDept?._id,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: userStatsDept } = useUserStatsQuery(
    {
      userType: 'superadmin',
      type: 'dept',
      deptId: selectedDept?._id,
      startDate: dateRange ? dateRange[0] : undefined,
      endDate: dateRange ? dateRange[1] : undefined,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: bookStatsDept, refetch } = useBookStatsQuery(
    {
      bookId: Object.keys(selectBooks).length?selectBooks._id:bookDept?.data[0]?._id,
      userType: 'superadmin',
      type: 'dept',
      deptId: selectedDept?._id,
      startDate: dateRange ? dateRange[0] : undefined,
      endDate: dateRange ? dateRange[1] : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: value === 0,
    }
  );
  const {
    data: allDepartments,
    isLoading: departmentloading,
    isError,
  } = useGetAllDepartmentsQuery();

  React.useEffect(() => {
    dispatch(roleToggler('Super Admin'));

    dispatch(setActiveItem(1));
  }, []);

  React.useEffect(()=>{
    if(value==0)dispatch(setSelectBooks({_id:bookOrg?.data[0]?._id,name:bookOrg?.data[0]?.name}))
    else if(value==1)dispatch(setSelectBooks({_id:bookDept?.data[0]?._id,name:bookDept?.data[0]?.name}))
  },[value, bookDept,bookOrg])

  React.useEffect(()=>{
    let usersDeptId = allDepartments?.data?.departments.filter(
      (item) => item._id === departmentId
    )[0];
    dispatch(setDepartment(usersDeptId));
  },[allDepartments])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    refetch();
  }, [selectBooks]);

  if (
    badgeStatsDeptLoading ||
    bookStatsOrgLoading ||
    userStatsOrgLoading ||
    badgeStatsOrgLoading ||
    deptLoading ||
    isLoading ||
    departmentloading
  ) {
    return <ProgressBar />;
  }

  const selectHandler = (dept) => {
    dispatch(setSelectBooks({}))
    dispatch(setDepartment(dept));
    setOpenDepartmentSelect(false);
  };

  return (
    <div className="container ">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab
              label="Organization"
              sx={{
                textTransform: 'capitalize',
                marginRight: '20px',
                fontWeight: '500',
              }}
              {...a11yProps(0)}
            />

            <Tab
              sx={{ textTransform: 'capitalize', fontWeight: '500' }}
              label="Department"
              {...a11yProps(1)}
            />
          </Tabs>
          <div className="dashboard__actions">
            <div className="dashboard__actionsdate">
              <DateRangePickerComp
                onChange={(value) => {
                  if (!value) setDateRange(null);
                  else
                    setDateRange(
                      value.map((val) => new Date(val).toISOString())
                    );
                }}
              />
            </div>
            {/* ?only for superadmin */}
            {/* {value === 1 && (
              <FilterListOutlinedIcon
                sx={{ color: "var(--pblue)", fontSize: "30px" }}
              />
            )} */}

            {value === 1 && (
              <div className="employee__manage_employee_dd">
                <div
                  className={
                    (openDepartmentSelect ? 'open' : '') +
                    ' employee__manage_employee_dd_item'
                  }>
                  <div
                    onClick={() => {
                      setOpenDepartmentSelect(!openDepartmentSelect);
                    }}
                    className="employee__manage_employee_dd_label">
                    <p>{selectedDept ? selectedDept?.name : 'Department'}</p>
                    <div>
                      <img
                        src={rightArrow}
                        style={{ marginBottom: '2px' }}
                        alt=""
                      />
                    </div>
                  </div>
                  {openDepartmentSelect ? (
                    <div className="employee__select_menu">
                      <h1 className="select_divider" />
                      {allDepartments &&
                        allDepartments?.data?.departments.map(
                          (curElm, index) => (
                            <p
                              className="employee__select_menuPara"
                              onClick={() => selectHandler(curElm)}
                              key={index}>
                              {curElm.name}
                            </p>
                          )
                        )}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </Box>
        <TabPanel
          value={value}
          index={0}>
          <div className="saadminLayout">
            <div className="sadashboard__content">
              <Graph
                badgeStats={badgeStatsOrg}
                userStats={userStatsOrg}
                bookStats={bookStatsOrg}
                bookData={bookOrg?.data}
              />

              <QuestActivity
                userType={'superadmin'}
                bookData={bookOrg}
              />
              <div className="questLeaderboard">
                <h3>Leaderboard</h3>
                <LeaderboardTable
                  startDate={dateRange ? dateRange[0] : undefined}
                  endDate={dateRange ? dateRange[1] : undefined}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}>
          <div className="adminLayout">
            <div className="dashboard__content ">
              <Graph
                badgeStats={badgeStatsDept}
                userStats={userStatsDept}
                bookStats={bookStatsDept}
                bookData={bookDept?.data}
              />
              <QuestActivity
                userType={'superadmin'}
                deptBookData={bookDept}
              />
              <div className="questLeaderboard">
                <h3>Leaderboard</h3>
                <LeaderboardTable
                  deptId={selectedDept?._id || ''}
                  startDate={dateRange ? dateRange[0] : undefined}
                  endDate={dateRange ? dateRange[1] : undefined}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};
