import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./DeleteModal.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "50%",
  maxHeight: "50vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: "30px 30px",
};

export const DeleteModal = ({
  setDeleteOpen,
  deleteOpen,
  label,
  deleteHandler,
}) => {
  const handleClose = () => setDeleteOpen(false);

  const confirmHandler = async () => {
    deleteHandler();
    handleClose();
  };

  return (
    <Modal
      open={deleteOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="deletemodal">
          <h3 className="ftwt__r">
            Are you sure you want to delete{" "}
            <span style={{ color: "#3361cf", fontWeight: "normal" }}>
              {label}
            </span>
            ?
          </h3>
          <div className="deletemodal__action">
            <Button
              onClick={handleClose}
              // color="error"
              style={{
                color: "var(--grey400)",
                borderColor: "var(--grey400)",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={confirmHandler} color="error" variant="contained">
              Delete
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
