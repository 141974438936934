import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar } from "@mui/material";
import { Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import ProgressBar from "../../../../UI/ProgressBar";
import { Error404 } from "../../../../UI/404/Error404";
import { useSelector } from "react-redux";
import sortUp from "../../../../../assets/employee/sort_up.svg";
import sortDown from "../../../../../assets/employee/sort_down.svg";
import "./AccessBottom.css";

const rows = [
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: false,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
  {
    _id: 10,
    fname: "Joseph",
    mname: "Fredo",
    lname: "Dicosta",
    emailId: "joseph.dicosta@gmail.com",
    empId: "Ab442454",
    status: true,
  },
];

const SortIcon = ({ sortOrder }) => {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2px",
        width: "fit-content",
      }}
    >
      <img src={sortUp} />
      <img src={sortDown} />
    </span>
  );
};

export const AccessTable = ({
  sortType,
  setSortType,
  isLoading,
  isError,
  users,
}) => {
  const handleSorting = () => {
    setSortType(!sortType || sortType === 'desc' ? 'asc' : 'desc');
  };
   if (isLoading) {
     return <ProgressBar />;
   }

  if (isError) {
    return <Error404 />;
  }
  return (
    <div>
      <TableContainer
        sx={{
          height: 'calc( 100vh - 220px)',
          padding: '0 1px',
          minWidth: '800px',
        }}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead>
            <TableRow className="tableUi__head">
              <TableCell
                sx={{ borderRadius: '20px 0px 0px 0px' }}
                align="center">
                <div
                  onClick={handleSorting}
                  className="tableUi__head_sortable">
                  Full Name <SortIcon sortOrder={sortType} />
                </div>
              </TableCell>

              <TableCell align="center">Email Address</TableCell>

              <TableCell align="center">Role</TableCell>

              {/* <TableCell
                sx={{ borderRadius: '0px 20px 0px 0px' }}
                align="center">
                Access
              </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {users &&
              users.map((row, index) => (
                <>
                  <div
                    key={index}
                    className="row__spacing"></div>
                  <TableRow
                    className="tableUi___body"
                    key={row?.name}
                    // sx={{
                    //   // "&:last-child td, &:last-child th": {
                    //   //   border: "1px solid grey",
                    //   // },
                    //   borderRadius: "20px",
                    // }}
                    sx={{ borderRadius: '10px' }}>
                    <TableCell
                      sx={{
                        borderRadius: '10px 0px 0px 10px',
                        // border: "1px solid grey",
                      }}
                      align="center">
                      <div className="tableUi__rankscir">
                        <div className="tableUi__rank">
                          {row?.fname + ' ' + row.lname}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell align="center">{row?.email}</TableCell>

                    {/* <TableCell align="center">{row?.employeeId}</TableCell> */}

                    <TableCell
                      sx={{
                        borderRadius: '0px 10px 10px 0px',
                        // border: "1px solid grey",
                      }}
                      align="center">
                      {row?.role?.length>1||row?.role[0]?.includes("CUSTOM")?"CUSTOM ROLE":row?.role[0]}
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
