import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import rightArrow from "../../../assets/Icons/arrow.svg";
import { roleToggler } from "../../../redux-toolkit/features/access/accessSlice";
import DateRangePickerComp from "../../UI/DateRangePicker";
import "./AdminDashboard.css";
import { BadgesSection } from "./BadgesSection/BadgesSection";
import { Graph } from "./Charts/Graph";
import { QuestActivity } from "./Quest Activity/QuestActivity";
import {
  useBadgeStatsQuery,
  useBookDataQuery,
  useUserStatsQuery,
  useBookStatsQuery,
} from "../../../redux-toolkit/features/dashboard/dashboardApliSlice";
import ProgressBar from "../../UI/ProgressBar";
import { setActiveItem, setSelectBooks } from '../../../redux-toolkit/features/theme/themeSlice';


export const AdminDashboard = () => {
  const dispatch = useDispatch();

  const [openDepartmentSelect, setOpenDepartmentSelect] = React.useState();
  React.useEffect(() => {
    dispatch(roleToggler('Admin'));
    dispatch(setActiveItem(1));
  }, []);

  const { departmentId } = useSelector((state) => state.auth.user);
  const [dateRange, setDateRange] = React.useState(null);
  const selectBooks = useSelector((state) => state.theme.selectBooks);


  // Organizationals
  const { data: bookOrg, isLoading } = useBookDataQuery(
    { type: 'org' },
    { refetchOnMountOrArgChange: false }
  );
  // const { data: badgeStatsOrg, isLoading: badgeStatsOrgLoading } =
  //   useBadgeStatsQuery(
  //     {
  //       type: 'org',
  //       startDate: dateRange ? dateRange[0] : undefined,
  //       endDate: dateRange ? dateRange[1] : undefined,
  //     },
  //     { refetchOnMountOrArgChange: true }
  //   );
  // const { data: userStatsOrg, isLoading: userStatsOrgLoading } =
  //   useUserStatsQuery(
  //     {
  //       type: 'org',
  //       startDate: dateRange ? dateRange[0] : undefined,
  //       endDate: dateRange ? dateRange[1] : undefined,
  //     },
  //     { refetchOnMountOrArgChange: true }
  //   );
  // const { data: bookStatsOrg, isLoading: bookStatsOrgLoading } =
  //   useBookStatsQuery(
  //     {
  //       bookId: "6465ce03dc4cb675366d787b",
  //       type: 'org',
  //       startDate: dateRange ? dateRange[0] : undefined,
  //       endDate: dateRange ? dateRange[1] : undefined,
  //     },
  //     {
  //       refetchOnMountOrArgChange: false,
  //     }
  //   );
  //  Departmental
  
  
  const { data: bookDept, isLoading: deptLoading } = useBookDataQuery(
    {
      userType: "admin",
      type:'dept'
    },
    { refetchOnMountOrArgChange: false }
  );

  const { data: badgeStatsDept, isLoading: badgeStatsDeptLoading } =
    useBadgeStatsQuery(
      {
        type: 'dept',
        deptId: departmentId,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { data: userStatsDept } = useUserStatsQuery(
    {
      type: 'dept',
      deptId: departmentId,
      startDate: dateRange ? dateRange[0] : undefined,
      endDate: dateRange ? dateRange[1] : undefined,
    },
    { refetchOnMountOrArgChange: true }
  );

  React.useEffect(()=>{
    dispatch(setSelectBooks({_id:bookDept?.data[0]?._id,name:bookDept?.data[0]?.name}))
  },[bookDept])

  const { data: bookStatsDept } = useBookStatsQuery(
    {
      bookId: Object.keys(selectBooks).length?selectBooks._id:bookDept?.data[0]._id,
      type: 'dept',
      deptId: departmentId,
      startDate: dateRange ? dateRange[0] : undefined,
      endDate: dateRange ? dateRange[1] : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (
    badgeStatsDeptLoading ||
    deptLoading ||
    isLoading
  ) {
    return <ProgressBar />;
  }

  return (
    <div className="container">
      <div className="dashboard__headers">
        <h4 className="ftwt__b" style={{ color: "var(--pblue)" }}>Department</h4>
        <div className="dashboard__actions">
          <DateRangePickerComp
            onChange={(value) => {
              if (!value) setDateRange(null);
              else
                setDateRange(
                  value.map((val) => new Date(val).toISOString())
                );
            }}
          />
        </div>
      </div>
      <div className="seprator"></div>
      <div className="adminLayout">
        <div className="dashboard__content ">
          <Graph
            badgeStats={badgeStatsDept}
            userStats={userStatsDept}
            bookStats={bookStatsDept}
            bookData={[...bookDept?.data,...bookOrg?.data]}
          />
          <QuestActivity bookData={bookOrg} deptBookData={bookDept} />
          <BadgesSection />
        </div>
      </div>
    </div>
  );
};

// btn
