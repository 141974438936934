import { Search } from '../../../UI/Search/Search';
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { debounce } from '../../../../utils';
import React, { useCallback, useEffect, useState } from 'react';
import './SelectBook.css';
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel, Radio } from '@mui/material';
import { setSelectBooks } from '../../../../redux-toolkit/features/theme/themeSlice';
import { useSelector, useDispatch } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "50%",
    // height: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: "15px 30px",
};

export const SelectBook = ({
    setActivityOpen,
    activityOpen,
    allBooks,
}) => {
    const handleClose = () => setActivityOpen(false);
    const [books, setBooks] = useState([])
    const selectBooks = useSelector((state) => state.theme.selectBooks);
    const dispatch = useDispatch();

    useEffect(()=>{
        setBooks(allBooks)
    },[allBooks])
    
    const filterBooks = (value) => {
        if(!value)setBooks(allBooks)
        else{
            const filteredBooks = allBooks.filter((book)=>{
                return book.name.toUpperCase().includes(value.toUpperCase())
            })
            setBooks(filteredBooks)
        }
    }

    const setDebouncedSearchQuery = useCallback(
        debounce((value) => {
            filterBooks(value);
        }, 500),
        []
    );

    return (
        <div>
            <Modal
                open={activityOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="activitymodal">
                        <CloseIcon
                            onClick={handleClose}
                            sx={{
                                fontSize: "18px",
                                cursor: "pointer",
                                marginLeft: "auto",
                                color: "var(--grey400)",
                                "&:hover": {
                                    color: "var(--error)",
                                },
                            }}
                        />
                        <Search
                            placehold={'Search...'}
                            setSearchVal={(val) => setDebouncedSearchQuery(val)}
                        />
                        <div className='selectbook__header'>
                            <h4>All Books</h4>
                        </div>
                        <FormControl sx={{ display: "flex", gap: "5px" }} className='selectbook'>
                            <RadioGroup
                                value={selectBooks._id}
                                column
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: "16px",
                                        marginLeft: "24px",
                                    },
                                }}
                                aria-labelledby="demo-column-radio-buttons-group-label"
                                name="column-radio-buttons-group"
                            >
                                {books?.length > 0 && books.map((book, index) => (
                                    <div className='selectbook__book'>
                                        <FormControlLabel
                                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, }}
                                            value={book?._id}
                                            control={
                                                <Radio />
                                            }
                                            label={book?.name}
                                            onChange={() => {
                                                dispatch(setSelectBooks({_id:book?._id,name:book?.name}))
                                            }}
                                        />
                                    </div>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
