import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { useAlert } from "../../../../../hooks/useAlert";
import "../EditCourses.css";
import React, { useRef, useState } from "react";
import {
    useDeleteModuleMutation,
    useUpdateModuleMutation,
} from "../../../../../redux-toolkit/features/courses/coursesApiSlice";
import { Button, TextField } from "@mui/material";
import { ReactComponent as DeleteOutlineIcon } from "../../../../../assets/delete_icon.svg"
import { color } from "@mui/system";
// import { ReactComponent as DeleteIcon } from "./../../../assets/delete_icon.svg";



export const Module = ({
    setSelectedModule,
    setShowAddModule,
    index,
    setSelectedSubModule,
    setShowAddTopic,
    setShowQuiz,
    selectedModule,
    refetch,
    mod,
    showEditModule,
    setShowEditModule,
    setShowQuestion,
    isView
}) => {

    const ref = useRef(null);
    const [drawer, setDrawer] = React.useState(0);
    const [name, setModuleName] = useState(mod?.name);
    const [deleteModule] = useDeleteModuleMutation();
    const [updateModule] = useUpdateModuleMutation();
    const { displayAlert } = useAlert();


    useOutsideClick(ref, () => {
        setDrawer(0);
    });
    const deleteModuleHandler = async () => {
        const moduleId = selectedModule?._id;
        await deleteModule(moduleId);
        await refetch();
        displayAlert({
            open: true,
            message: "Successfully Deleted Module",
            type: "error",
            timeout: "1500",
        });
    };
    const updateModuleHandler = async () => {
        const moduleId = selectedModule?._id;
        const data = { name };
        const finalData = { data, moduleId };
        await updateModule(finalData);
        await refetch();
        setShowEditModule("");
        setDrawer(0);
        displayAlert({
            open: true,
            message: "Successfully Updated Module",
            type: "success",
            timeout: "1500",
        });
    };

    return (
        <>
            {showEditModule == mod._id ? (<div className="editcourses__accordiansForm">
                <div>
                    <h4 className="ftwt__r">Module Name</h4>
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            marginTop: '5px',
                        }}
                        id="outlined-basic"
                        value={name}
                        onChange={(e) => setModuleName(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <div className="editcourses__moduleAction">
                        <DeleteOutlineIcon 
                        onClick={deleteModuleHandler}
                        style={{
                            cursor: 'pointer',
                            fontSize: '30px',
                        }}
                        />
                        <Button
                            onClick={updateModuleHandler}
                            variant="contained">
                            Save
                        </Button>
                    </div>
                </div>
            </div>) : (<div className="editcourse__modcontainer" onClick={() => {
                setSelectedModule({ ...mod, index })
                setShowAddTopic(false)
                setShowQuiz(false)
                setSelectedSubModule({})
                setShowQuestion({})
                setShowAddModule(false)
            }}>
                <div className="editcourse__mod" onClick={() => {
                    setShowEditModule("")
                }} style={{ backgroundColor: selectedModule._id === mod._id ? "var(--slightpurple)" : "transparent" }}>
                    <h4 className="ftwt__r" style={{ color: "var(--pblue)", paddingLeft: "10px" }}>{mod.name}</h4>
                    {!isView && <MoreVertIcon
                        onClick={() => setDrawer(mod._id)}
                        sx={{
                            color: "var(--pblue)",
                            fontSize: "20px",
                            padding: "2px",
                            borderRadius: "5px",
                        }}
                    />}
                </div>
                {drawer === selectedModule._id && (
                    <div ref={ref} className="courseDrawer">
                        <p onClick={() => { setShowEditModule(selectedModule._id) }}>Edit</p>
                        <div className="seprator"></div>
                        <p onClick={deleteModuleHandler}> Delete</p>
                    </div>
                )}
            </div>)}
        </>
    )
}