import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Button, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useCallback, useState } from "react";
import { CSVLink } from "react-csv";
import { debounce } from "../../../../../utils";
import "../../../../Admin/Reports/Reports Detailed/ReportsDetailed.css";
import { BreadcrumbAdmin } from "../../../../UI/BreadcrumbAdmin";
import DateRangePickerComp from "../../../../UI/DateRangePicker";
import { Search } from "../../../../UI/Search/Search";
import { DeptBooksStatisticTable } from "./DeptBooksStatisticTable";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

const list = [
  {
    name: "BookName",
    label: "Book Name",
  },
  // { name: "BookID", label: "BookID" },
  { name: "task", label: "No of Tasks" },
  { name: "pointsLevels", label: "No of Levels" },
  { name: "pointsCompletions", label: "Points Assigned" },
  { name: "NoOfCompletions", label: "No of Completions" },
  { name: "Inprogress", label: "No of Inprogress" },
];

export const DepartmentBooksStatistic = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [select, setSelect] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const ref = React.useRef(null);

  const closePopup = () => {
    setSelect(false);
  };

  useOutsideClick(ref, closePopup);

  const [check, setCheck] = useState({
    BookName: true,
    BookID: true,
    task: true,
    pointsLevels: true,
    pointsCompletions: true,
    NoOfCompletions: true,
    Inprogress: true,
  });
  const [dateRange, setDateRange] = React.useState(null);

  const base = [
    {
      _id: 1,
      link: "/admin/reports",
      label: "Reports",
    },
  ];

  const handleChange = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };

  const setDebouncedSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 500),
    []
  );
  return (
    <div className="container">
      <BreadcrumbAdmin base={base} page={"Department Books Activity"} />
      <div className="reportsDetailed__head">
        <Search
          mini
          setSearchVal={(val) => setDebouncedSearchQuery(val)}
          placehold={"Search..."}
        />
        <div className="reportsDetailed__headright">
          <DateRangePickerComp
            onChange={(value) => {
              if (!value) {
                setDateRange(null);
              } else {
                setDateRange(value.map((val) => new Date(val).toISOString()));
              }
            }}
          />
          <div ref={ref} className="reports__selectLayout">
            <Button
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: select ? "rotate(-90deg)" : "rotate(90deg)",
                    color: "grey",
                    fontSize: "16px !important",
                  }}
                />
              }
              sx={{
                color: "var(--grey400)",
                borderColor: "var(--grey300)",
                width: "150px",
                fontWeight: "400 !important",
              }}
              onClick={() => setSelect(!select)}
            >
              Manage Field
            </Button>
            <div
              className={select ? "reports__selectActive" : "reports__select"}
            >
              <div className="reports__selectcontent">
                {list.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        defaultChecked={item.name}
                        name={item.name}
                        onChange={handleChange}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: "20px" } }}
                      />
                    }
                    onChange={handleChange}
                    label={
                      <span style={{ fontSize: "15px" }}>{item.label}</span>
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <CSVLink filename="Departmental book statistics" data={csvData}>
            <Button
              variant="contained"
              endIcon={<FileUploadOutlinedIcon sx={{ color: "white" }} />}
            >
              Export
            </Button>
          </CSVLink>
        </div>
      </div>

      <div className="reports__table">
        <DeptBooksStatisticTable
          check={check}
          startDate={dateRange && dateRange[0]}
          endDate={dateRange && dateRange[1]}
          searchQuery={searchQuery}
          getTableData={(data) => {
            let cleanedCSVData = data?.data?.map((obj) => {
              let d = {
                "Book name": obj.name,
                "No of tasks": obj.tasksCount,
                "No of levels": obj.levelCount,
                Points: obj.pointsCompletion,
                "No of completion": obj.completionCount,
                "No of progress": obj.inProgressCount,
              };
              delete d._id;
              d.department = d?.department?.name;
              return d;
            });
            setCsvData(cleanedCSVData);
          }}
        />
      </div>
    </div>
  );
};
