import React from "react";
import "./AdminRolesCards.css";
import Checkbox from "@mui/material/Checkbox";

export const AdminRolesCards = ({
  _id,
  roles,
  label,
  setSelectedPermissions,
  selectedPermissions,
  allPermissions,
  descp,
  empPermissions,
  employeeData,
}) => {
  const [check, setCheck] = React.useState(false);

  React.useEffect(()=>{
    if(empPermissions?.permissions)setSelectedPermissions([...empPermissions?.permissions])
    empPermissions?.permissions?.map(perm=>{
      if(perm == _id){
        setCheck(true)
      }
    })
  },[])

  React.useEffect(() => {
    if (selectedPermissions.length > 0) {
      // isPresent = allPermissions.some((permission) => permission._id === _id);
      let isPresent =
        selectedPermissions.filter((item) => item === _id).length > 0;

        if (isPresent) {
          setCheck(true);
        }
    }
  }, [selectedPermissions]);
  React.useEffect(() => {}, [check]);

  const handleChange = (e) => {
    // let existingPermissions = roles?.map((obj) => obj);
    if (!check) {
      // const arr3 = [...selectedPermissions, ...existingPermissions];
      setSelectedPermissions([...selectedPermissions, _id]);
    } else {
      const filteredArray1 = selectedPermissions?.filter(
        (value) => value != _id
      );
      setSelectedPermissions([...filteredArray1]);
      setCheck(false);
    }
  };


  return (
    <div className="adminRolesCards">
      <div className="adminRolesCards__head">
        <Checkbox
          checked={check}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          {...label}
          onChange={handleChange}
        />
        <h4>{label}</h4>
      </div>
      <div className="seprator"></div>
      <h5 className="ftwt__r">{descp}</h5>
    </div>
  );
};
