
export const SubModuleDrawer = ({setShowAddTopic,setSubModDrawer, setShowQuiz}) => {
    return (
        <>
            <div className="submodule__drawer">
                <p onClick={()=>{
                    setShowAddTopic(true)
                    setSubModDrawer(false)
                    }}>Add Topic</p>
                <div className="seprator"></div>
                <p onClick={()=>{
                    setShowQuiz(true)
                    setSubModDrawer(false)
                    }}> Add Quiz</p>
            </div>
        </>
    );
}