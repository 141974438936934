import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "./Wheel.css";
import { ReactComponent as SelectedCardVector } from "../../../assets/books/Vector.svg";
import { useNavigate } from "react-router-dom";
import Lock from "../../../assets/lock.png";
// import Wildlife from '../../../assets/books/org/wildlife.png';
import Wildlife from "../../../assets/Icons/books/empty_card_placeholder-01 1.svg";

const getMyCords = (theta, radius) => {
  return {
    x: Math.cos(theta) * radius,
    y: Math.sin(theta) * radius,
  };
};

function WheelCard(props) {
  let newCords = getMyCords(props.theta, props.radius);
  const navigate = useNavigate();
  React.useEffect(() => {}, [props.activeTab]);

  const handleNavigation = () => {
    let bookId = props?.bookData[props?.id]._id;
    if (!props.isPreview) {
      if (props.type === "active") {
        // !@rishabh lock after mikeals demo
        // navigate(`books/${bookId}`);
        props.startHandler(bookId, props.isLocked);
      } else {
        props.selectedBookHandler(props.id, props.itemRotation);
      }
    }
  };
  const deptBookWheelStyle = props.bookType != "ORG"?{
    display:"flex",
    justifyContent:"center"
  }:{}
  return (
    <>
      <div
        onClick={handleNavigation}
        className={`${props.bookType != "ORG" && "cardColor"} ${
          props.bookType != "DEPT" && "cardColor"
        } card ${props.type} `}
        style={{
          background: 'rgba(255, 255, 255, 0.8)',
          cursor: props.type === "active" ? "pointer" : "",
          // scale: props.type === "active" ? "1.08" : "",
          left: `${props.center.x + newCords.x}px`,
          top: `${props.center.y + newCords.y}px`,
          transform: `translate(-50%, -50%) rotate(${
            props.itemRotation
          }deg) scale(${props.type === "active" ? "1.1" : "1"})`,
          opacity: props.type === "active" ? "1" : "0.5",
          ...deptBookWheelStyle
        }}
      >
        {props.isLocked && (
          <div
            style={{
              display: "flex",
              fontSize: "50px",
              // direction: 'row',
              // justifyContent: 'space-between',
              margin: "20px",
              alignItems: "center",
              height: "30%",
              width: "100%",
              position: "absolute",
              zIndex: 2,
            }}
          >
            <img
              style={{
                transform: `rotate(335deg)`,
              }}
              src={Lock}
              width={20}
              alt=""
            />
          </div>
        )}
        <div
          onClick={handleNavigation}
          style={{
            display: "flex",
            fontSize: "50px",
            padding: "10px 10px 20px 10px",
            justifyContent: "center",
          }}
        >
          {props.bookType != "ORG" ? (
            <img
              onClick={handleNavigation}
              style={{
                // position: "relative",
                objectFit: "contain",
                width:"80%"
                // padding:"20px",
                // width: "40%",
                // heighth: "100%",
                // maxHeight: "180px",
              }}
              alt=""
              src={props.coverImage || Wildlife}
            />
          ) : (
            <img
              onClick={handleNavigation}
              style={{
                position: "absolute",
                width: "50%",
                heighth: "100%",
                maxHeight: "180px",
                position: "relative",
              }}
              alt="book-cover"
              src={props.wheelImage || Wildlife}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default WheelCard;
